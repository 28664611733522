/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.content-request-modal {
  flex: 1;
  margin-top: 4rem;
  max-width: 700px;
  color: #9296A2; }
  .content-request-modal .modal-content {
    background: white; }
  .content-request-modal .modal-title {
    color: #171F3D;
    margin-bottom: 1rem; }
  .content-request-modal .control-label {
    color: #9296A2;
    font-size: .75rem;
    font-weight: 600;
    text-transform: uppercase; }
  .content-request-modal .scheduling-fields {
    line-height: normal; }
  .content-request-modal textarea {
    border: 2px solid #D8D8D8;
    border-radius: 4px; }
  .content-request-modal .invalid-feedback {
    display: block; }
  .content-request-modal .pending-files {
    list-style-type: none;
    padding-left: 0; }
  .content-request-modal .pending-file-remove {
    margin-right: .5em; }
    .content-request-modal .pending-file-remove svg {
      color: red;
      font-weight: bold; }
  .content-request-modal .btn-primary {
    color: white; }
  .content-request-modal .btn-outline-dark {
    color: #00283D;
    border: 1px solid #D8D8D8; }
    .content-request-modal .btn-outline-dark:hover {
      background: transparent;
      border-color: #00283D; }
  .content-request-modal .close {
    color: white;
    position: absolute;
    top: -70px;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 64px;
    font-weight: 300;
    text-shadow: none;
    opacity: 1; }
    .content-request-modal .close:hover:not(:disabled):not(.disabled):hover {
      color: white; }
