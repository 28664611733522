@import "variables";

$tab-height: 0px;
$actions-height: 91px;

#editor-side {
  height: 100%;

  .editor-side-tabs {
    display: flex;

    height: $tab-height;
  }

  .tab {
    background-color: $sky-blue;
    color: $white;
    cursor: pointer;
    padding: 9px;
    text-align: center;
    width: 50%;

    &.active {
      background-color: $blue-600;
      cursor: auto;
    }

    &:not(.active) {
      &:hover {
        background-color: $white;
        color: $blue-600;
      }
    }
  }

  .editor-side-active {
    height: calc(100% - #{$tab-height} - #{$actions-height});
  }

  .editor-side-actions {
    background-color: $sky-blue;
    height: $actions-height;
    padding: 0 1em;

    /* Flex Styles */
    display: flex;
    align-items: center;
  }

  .save-button-group {
    width: 100%;

    .save-button {
      width: 80%;
    }
    .dropdown-toggle-split {
      width: 20%;
    }

    .dropdown-item {
      color: $black;
    }
  }
}
