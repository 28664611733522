@import 'variables';

$header-height: 60px;
$footer-height: 60px;

#admin-layout {
  height: 100%;
  overflow-y: hidden;

  /* Flex Styling */
  display: flex;
  flex-direction: column;

  #admin-content {
    display: flex;
    height: calc(100vh - #{$main-nav-bar-height});
  }

  #admin-sidebar {
    flex: 0 0 300px; // don't grow, don't shrink, start at 300px;
  }

  #admin-page {
    margin-top: 1em;
    width: 100%;
    overflow-y: auto;
  }
}
