@import 'variables';


.site-control-editor {
  background-color: $body-bg-color;
  color: $midnight-blue;

  input.form-control {
    border-color: $white;
    border-radius: $default-radius;
    color: $body-color;
    padding: 7px;
  }

  .group-header {
    margin-left: -15px;
    margin-right: -15px;
    padding: .5rem;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .group-header-label {
    font-size: .75rem;
    font-weight: bold;
    text-transform: uppercase;
    padding-left: 15px;
  }

  .group-controls {
    background-color: $lightest-gray;
    padding: 1rem;
  }

  .form-buttons {
    display: flex;
    justify-content: space-between;
  }

  .form-button {
    width: 36px;
    height: 36px;
  }
}
