@import 'variables';

.edit-line-controls {
  .line-group {
    background-color: $lightest-gray;
  }

  .control-group-help {
    font-size: .75rem;
    font-style: italic;
  }
}
