@import 'variables';

.content-name {
  display: inline-flex;

  .cancel-name-button,
  .edit-name-button {
    color: $white;
  }

  .edit-name-input {
    font-size: 1.5rem;
    font-weight: 300;
    padding: 0;
    line-height: 1.2;
    border: 0;
  }

  .btn {
    margin-left: 0.2rem;
  }

}
