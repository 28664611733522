@import "variables";

.edit-time-controls {
  .edit-time-text-wrapper {
    height: 1rem;
    margin-bottom: 1rem;
    position: relative;
  }

  .edit-time-text {
    color: $gray-400;
    font-size: .75rem;
    position: absolute;
    top: 0;
  }

  .edit-time-start {
    left: -10px;
  }

  .edit-time-end {
    right: -10px;
  }
}
