/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.select-file-header {
  margin-bottom: 1.5em;
  /* Flex Styling */
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .select-file-header .header-buttons {
    width: 100%;
    /* Flex Styling */
    display: flex;
    align-items: center;
    justify-content: flex-end; }
  .select-file-header .control-btn {
    margin-left: 1em;
    padding: .5em .75em;
    font-size: .875em; }
  .select-file-header .blank-canvas-btn {
    border-color: #999999;
    color: #999999; }
  .select-file-header .search-form-wrapper {
    flex: 1 1 auto;
    border-radius: 4px;
    border: 1px solid #505050;
    padding: .25em .5em;
    margin: .25em .75em; }
