/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
#main-layout {
  height: 100%;
  overflow-y: hidden;
  /* Flex Styling */
  display: flex;
  flex-direction: column; }

#main-content {
  display: flex;
  height: calc(100vh - 82px); }

#main-sidebar {
  flex: 0 0 300px; }

#main-page {
  width: 100%; }
  #main-page.with-emulation-bar {
    height: calc(100vh - 82px - 95px); }
    @media (min-width: 576px) {
      #main-page.with-emulation-bar {
        height: calc(100vh - 82px - 48px); } }
