@import 'variables';

.order-details {
  padding: 0;
  margin: 1rem 0;
  background-color: $lightest-gray;
  border-radius: 10px;
  align-self: flex-start;
  font-size: 0.8rem;
  color: $blue-800;

  .order-details-top {
    border-bottom: 2px dashed $gray-300;
    padding: 1rem;
  }

  .order-details-middle {
    border-bottom: 2px solid $gray-300;
    padding: 1rem;
  }

  .order-details-bottom {
    padding: 1rem;
  }

  .btn {
    margin-left: auto;
    font-size: 0.8rem;
    padding: 0.3rem 1.2rem;
  }

  .price {
    font-size: 1.3rem;

    small {
      font-size: 0.8rem;
      font-weight: normal;
    }
  }

  .btn-outline-dark {
    border-color: $gray-300;
  }

  .cost-markout {
    text-decoration: line-through;
  }

  .disclaimer {
    position: absolute;
    padding: 1rem;
    bottom: -5rem;
    text-align: center;
    color: $gray-400;
  }

  .form-control {
    color: $black;

    &::placeholder {
      color: $gray-400;
      font-weight: 400;
    }
  }
}
