@import "variables";

.user-file-folders {

  .folders-list-header {
    padding: 0.5rem 0.5rem 0.5rem 0.3rem;
  }

  .ai-tool-text {
    display: block;
    font-size: 11px;
    font-style: italic;
    margin-bottom: 4px;
    line-height: 1;
  }
  
  .folders-list-item {
    border-radius: 6px;
    cursor: pointer;
    padding: 0.2rem 1rem;
    transition: background-color 250ms ease;

    &.active {
      background-color: $light-gray;
    }

    .folders-list-item-count {
      opacity: 0.5;
    }
  }

  .fa-folder {
    color: $secondary;
    font-size: 1.2rem;
    transition: color 250ms ease;
  }

  .folders-list-item.active .fa-folder {
    color: $white;
  }
}
