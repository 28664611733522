@import 'variables';

$max-image-height: 14em;

.edit-image-controls {
  .can-not-edit {
    margin-top: 1rem;
    text-align: center;
  }

  .ai-tool-text {
    display: block;
    font-size: 11px;
    font-style: italic;
    margin-bottom: 4px;
    line-height: 1;
  }

  .image-selector {
    margin-bottom: 1rem;
    min-height: 20px;
    position: relative;
  }

  .image-change-button {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    cursor: pointer;
    padding: 0;
  }

  .image-thumbnail {
    width: auto;
    max-height: $max-image-height;
    border-radius: $default-radius;
  }

  .image-edit-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    svg {
      background-color: $black;
      border-radius: 100%;
      color: $white;
      padding: 10px;
      opacity: 0.75;
    }
  }

  .crop-dropdown {
    width: 20px;
    padding-right: 0;
    padding-left: 0;
  }

  .react-numeric-input {
    b i {
      // Because the component doesn't believe in classes...
      background: white !important;
    }
  }
}
