@import "variables";

.publish-content-modal {
  .collection-sidebar-list {
    min-width: 220px;
  }

  .choose-collection {
    text-align: center;
  }
}
