/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
#admin-layout {
  height: 100%;
  overflow-y: hidden;
  /* Flex Styling */
  display: flex;
  flex-direction: column; }
  #admin-layout #admin-content {
    display: flex;
    height: calc(100vh - 82px); }
  #admin-layout #admin-sidebar {
    flex: 0 0 300px; }
  #admin-layout #admin-page {
    margin-top: 1em;
    width: 100%;
    overflow-y: auto; }
