// @import "_bootstrap";
@import 'variables';

#main-navbar {
  margin-left: 0;
  position: relative;
  max-width: 100vw;
  min-height: $main-nav-bar-height;

  .navbar {
    z-index: 100;
    position: absolute;
    height: $main-nav-bar-height;
    width: 100%;
    background-image: linear-gradient(to right, $white 2%, $primary 55%, $secondary);
    display: flex;
    letter-spacing: 0.03em;

    .btn-link {
      font-size: 14px;
    }

    .navbar-left-logo-container {
      position: absolute;
      left: 0;
      top: 0;
      height: $main-nav-bar-height;

      @media (min-width: map-get($grid-breakpoints, sm)) {
        left: 1rem;
      }
    }

    .navbar-nav {
      align-items: center;

      .nav-link {
        color: $white;
      }
    }

    .navbar-collapse {
      @media (max-width: map-get($grid-breakpoints, lg)) {
        background-color: $midnight-blue;
        border-radius: $modal-content-border-radius;
      }
    }

    .nav-dropdown {
      position: relative;

      .nav-dropdown-toggle {
        cursor: pointer;
      }

      .nav-dropdown-item-list {
        display: none;
        list-style-type: none;
        position: absolute;
        top: 100%;
        left: 0;
        width: 200px;
        background-color: $midnight-blue;
        padding: 15px;

        &:hover {
          display: block;
        }

        .nav-dropdown-item {
          .btn-link {
            color: $white;
            font-size: 12px;
            font-weight: 500;
            padding: 4px 16px;

            &:hover, &:focus {
              color: $primary;
              text-decoration: none;
            }
          }
        }
      }

      .nav-dropdown-toggle:hover + .nav-dropdown-item-list {
        display: block;
      }
    }

    .nav-btn {
      margin-left: 1rem;
    }

    #dropdownMenuButton {
      margin-left: 0.5rem;
    }

    #download-credits-badge {
      margin-right: 5px;
      padding: 0.5rem 1.25rem;
    }

    .badge-item {
      display: flex;
      align-items: center;
      margin-right: 6px;
    }

    .badge-label {
      color: $white;
      margin-left: 0;
      max-width: 80px;
      font-size: .875rem;
      line-height: .85rem;
    }

    .navbar-toggle-button {
      padding: 0.25rem 0.75rem;
    }
  }
}
