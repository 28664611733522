@import 'variables';

@import 'modules/site-control-editor';
@import 'modules/site-react-select';
@import 'modules/site-react-toggle';
@import 'modules/site-refresh-button';
@import 'modules/site-no-results';
@import 'modules/site-error';
@import 'modules/site-text-input';
@import 'modules/site-label';
@import 'modules/site-transparent-button';
@import 'modules/site-navbar-control'; // This must be below site-transparent-button.
@import 'browserSupport';

.main-row {
  padding: 1rem 1rem;
}

.sub-row {
  padding: 1rem 1rem;
}

a {
  cursor: pointer;
}

* {
  &:focus {
    outline: none !important;
  }
}

.btn-icon {
  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
  }
}

.form-control-bottom-border {
  background: none;
  border: none;
  border-radius: 0;
  border-bottom: 2px solid $blue-600;
  color: $body-color;

  &::placeholder {
    color: $secondary;
  }

  &:focus {
    background: none;
    box-shadow: none;
    color: $body-color;
    border-color: $primary;
  }
}

.react-select-bottom-border {
  background: none;
  border: none;
  border-radius: 0;
  border-bottom: 2px solid $blue-600;
  color: $body-color;

  &::placeholder {
    color: $secondary;
  }

  &:focus {
    background: none;
    box-shadow: none;
    color: $body-color;
    border-color: $primary;
  }

  >div[class$="-control"] {
    border: none;
  }
  span[class$="-indicatorSeparator"] {
    width: 0;
  }
}

// react-selct-v2 classNamePrefix = react-select-bottom-border
.react-select-bottom-border__control {
  box-shadow: none !important;
  border: none !important;
}

.react-select-bottom-border__menu {
  .react-select-bottom-border__option--is-selected {
    background-color: $gray-400 !important;
  }
  .react-select-bottom-border__option--is-focused {
    background-color: $gray-300 !important;
  }
}

// react-selct-v2 classNamePrefix = react-select
.react-select__control {
  box-shadow: none !important;
  border-color: hsl(0,0%,80%) !important; // default react-select-v2 border color
}

.react-select__menu {
  .react-select__option--is-selected {
    background-color: $gray-400 !important;
  }
  .react-select__option--is-focused {
    background-color: $gray-300 !important;
  }
}

.icon {
  height: 24px;
  width: 24px;
}

.icon-bold {
  font-weight: bold;
}

/*
 * BADGES
 */

.badge-outline-primary {
  background-color: $badge-outline-bg-color;
  border: 1px solid $primary;
  color: $primary;
}

.badge-outline-dark {
  background-color: $badge-outline-bg-color;
  border: 1px solid $gray-400;
  color: $gray-400;
}

/*
 * Tooltips
 */
.tooltip {
  pointer-events: none;

  &.show {
    transition: opacity .2s ease-out;
  }

  .tooltip-inner {
    hr {
      border-top-color: $white;
    }
  }
}

/*
 * Popovers
 */
.popover-header, .popover-body {
  color: $blue-800;
  text-align: justify;
  text-align-last: left;
}

/*
 * Modals
 */
.modal-backdrop {
  background-color: $body-bg-color;
}
