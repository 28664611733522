/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.multi-slider {
  position: relative;
  width: 100%; }
  .multi-slider .multi-slider-track {
    background-color: #50D2C2;
    border-radius: 30px;
    height: 4px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; }
  .multi-slider .multi-slider-handle {
    background-color: #fff;
    border-radius: 100%;
    cursor: pointer;
    position: absolute;
    top: -4px;
    left: 0;
    width: 12px;
    height: 12px; }
    .multi-slider .multi-slider-handle:hover {
      background-color: #D8D8D8; }
