/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.text-field.form-group {
  margin-bottom: .5rem; }
  .text-field.form-group .form-control {
    padding-left: 0;
    padding-bottom: 1.5rem;
    border: none;
    border-radius: 0;
    border-bottom: 2px solid #D8D8D8; }
    .text-field.form-group .form-control:focus {
      box-shadow: none;
      border-color: #00DBD0; }
  .text-field.form-group.floating-label .form-control {
    margin-top: 20px; }
  .text-field.form-group.floating-label label {
    position: relative;
    display: flex;
    flex-direction: column;
    vertical-align: top; }
  .text-field.form-group.floating-label span {
    color: #9296A2;
    position: absolute;
    top: 0;
    left: 0;
    cursor: text;
    transform: translate(0, 24px) scale(1);
    transform-origin: top left;
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms; }
  .text-field.form-group.floating-label.has-content span,
  .text-field.form-group.floating-label .form-control:focus + span {
    font-weight: 600;
    text-transform: uppercase;
    transform: translate(0, 1.5px) scale(0.75); }
  .text-field.form-group .invalid-feedback {
    display: block; }
