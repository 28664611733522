@import "variables";

.folder-sidebar-list {
  background-color: $body-bg-color;
  margin-top: 8px;

  .sidebar-list-header {
    padding: 0.5rem 0.5rem 0.5rem 0.3rem;
  }

  .sidebar-list-item {
    border-radius: $default-radius;
    transition: background-color 250ms ease;
    cursor: pointer;
    padding: 0.2rem 1rem;

    &:hover, &.active {
      background-color: $lightest-gray;
    }

    .sidebar-list-item-prepend {
      color: $secondary;
    }

    .sidebar-list-item-count {
      opacity: 0.5;
    }
  }

  .fa-folder {
    color: $secondary;
    font-size: 1.2rem;
    transition: color 250ms ease;
  }

  .help-text {
    display: inline-block;
    opacity: .5;
    text-align: center;
    width: 100%;
  }

  .sidebar-list-item.active .fa-folder {
    color: $white;
  }
}
