@import 'variables';

.banner-container {
  width: 100%;
  padding: 10px 0px;
  background-color: $lightest-gray;
  margin: 20px 0;
  position: relative;
}

.banner-text {
  font-size: 1.5em;
  font-weight: 700;
  text-align: center;
  margin: 0;
  position: inherit;
  z-index: 50;

  &-logo {
    height: 2em;
    padding: 0 10px;
  }

  .red {
    color: $nextled-red;
  }
}

.left-color {
  width: 10%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;

  &.nextled {
    background-image: linear-gradient(to right, $nextled-light-blue, $lightest-gray);
  }
}

.right-color {
  width: 10%;
  height: 100%;
  position: absolute;
  right: 0;
  bottom: 0;

  &.nextled {
    background-image: linear-gradient(to right, $lightest-gray, $nextled-red);
  }
}
