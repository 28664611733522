/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.order-details {
  padding: 0;
  margin: 1rem 0;
  background-color: #E9ECEF;
  border-radius: 10px;
  align-self: flex-start;
  font-size: 0.8rem;
  color: #00283D; }
  .order-details .order-details-top {
    border-bottom: 2px dashed #D8D8D8;
    padding: 1rem; }
  .order-details .order-details-middle {
    border-bottom: 2px solid #D8D8D8;
    padding: 1rem; }
  .order-details .order-details-bottom {
    padding: 1rem; }
  .order-details .btn {
    margin-left: auto;
    font-size: 0.8rem;
    padding: 0.3rem 1.2rem; }
  .order-details .price {
    font-size: 1.3rem; }
    .order-details .price small {
      font-size: 0.8rem;
      font-weight: normal; }
  .order-details .btn-outline-dark {
    border-color: #D8D8D8; }
  .order-details .cost-markout {
    text-decoration: line-through; }
  .order-details .disclaimer {
    position: absolute;
    padding: 1rem;
    bottom: -5rem;
    text-align: center;
    color: #999999; }
  .order-details .form-control {
    color: #000; }
    .order-details .form-control::placeholder {
      color: #999999;
      font-weight: 400; }
