/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.content-name {
  display: inline-flex; }
  .content-name .cancel-name-button,
  .content-name .edit-name-button {
    color: #fff; }
  .content-name .edit-name-input {
    font-size: 1.5rem;
    font-weight: 300;
    padding: 0;
    line-height: 1.2;
    border: 0; }
  .content-name .btn {
    margin-left: 0.2rem; }
