@import 'variables';

#onboard-user-signs {
  height: 100%;
  margin-top: 1.5em;
  overflow-y: auto;

  .signs-header-subtext {
    margin-bottom: .5em;
    font-size: 1em;
  }

  .card-deck {
    margin-bottom: 2em;
  }

  .card {
    color: $black;
    margin-bottom: 1.5em;
    max-width: 18em;
    min-width: 18em;
  }

  .add-new-card {
    background-color: transparent;

    .card-body {
      display: flex;
      align-content: center;
      justify-content: center;
    }

    .add-new-button {
      background-color: $white;
      border: 1px solid gray;
      color: $black;
      width: 100%;
    }
  }
}
