/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
/**
 * Loads all the fonts needed for the editor.
 */
/* AlfaSlabOne */
@font-face {
  font-family: 'AlfaSlabOne';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/AlfaSlabOne-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* Anton */
@font-face {
  font-family: 'Anton';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/Anton-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* ArchivoBlack */
@font-face {
  font-family: 'ArchivoBlack';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/ArchivoBlack-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* Arizona */
@font-face {
  font-family: 'Arizona';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/Arizona-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal; }

/* Arvo */
@font-face {
  font-family: 'Arvo';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/Arvo-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Arvo';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/Arvo-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Arvo';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/Arvo-BoldItalic.ttf) format("truetype");
  font-weight: bold;
  font-style: italic; }

/* AvenirNextLTProBold */
@font-face {
  font-family: 'AvenirNextLTProBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/AvenirNextLTProBold-Regular.otf) format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'AvenirNextLTProBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/AvenirNextLTProBold-Bold.otf) format("opentype");
  font-weight: bold;
  font-style: normal; }

/* AvenirNextLTProBoldCondensed */
@font-face {
  font-family: 'AvenirNextLTProBoldCondensed';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/AvenirNextLTProBoldCondensed-Regular.otf) format("opentype");
  font-weight: normal;
  font-style: normal; }

/* AvenirNextLTProDemi */
@font-face {
  font-family: 'AvenirNextLTProDemi';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/AvenirNextLTProDemi-Regular.otf) format("opentype");
  font-weight: normal;
  font-style: normal; }

/* AvenirNextLTProDemiCondensed */
@font-face {
  font-family: 'AvenirNextLTProDemiCondensed';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/AvenirNextLTProDemiCondensed-Regular.otf) format("opentype");
  font-weight: normal;
  font-style: normal; }

/* Baloo */
@font-face {
  font-family: 'Baloo';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/Baloo-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* Bangers */
@font-face {
  font-family: 'Bangers';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/Bangers-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* BioRhymeBold */
@font-face {
  font-family: 'BioRhymeBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/BioRhymeBold-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'BioRhymeBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/BioRhymeBold-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal; }

/* BitterBold */
@font-face {
  font-family: 'BitterBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/BitterBold-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* Blackhead */
@font-face {
  font-family: 'Blackhead';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/Blackhead-Regular.otf) format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Blackhead';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/Blackhead-Bold.otf) format("opentype");
  font-weight: bold;
  font-style: normal; }

/* BlackOpsOne */
@font-face {
  font-family: 'BlackOpsOne';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/BlackOpsOne-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* BowlbyOne */
@font-face {
  font-family: 'BowlbyOne';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/BowlbyOne-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* CartonSlab */
@font-face {
  font-family: 'CartonSlab';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/CartonSlab-Regular.otf) format("opentype");
  font-weight: normal;
  font-style: normal; }

/* ChangaOne */
@font-face {
  font-family: 'ChangaOne';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/ChangaOne-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'ChangaOne';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/ChangaOne-Italic.ttf) format("truetype");
  font-weight: normal;
  font-style: italic; }

/* Creepster */
@font-face {
  font-family: 'Creepster';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/Creepster-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* DeliusUnicaseBold */
@font-face {
  font-family: 'DeliusUnicaseBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/DeliusUnicaseBold-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* DouglasAaronadeScript */
@font-face {
  font-family: 'DouglasAaronadeScript';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/DouglasAaronadeScript-Regular.otf) format("opentype");
  font-weight: normal;
  font-style: normal; }

/* DouglasCalguryBlock */
@font-face {
  font-family: 'DouglasCalguryBlock';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/DouglasCalguryBlock-Regular.otf) format("opentype");
  font-weight: normal;
  font-style: normal; }

/* DouglasWolves*/
@font-face {
  font-family: 'DouglasWolves';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/DouglasWolves-Regular.otf) format("opentype");
  font-weight: normal;
  font-style: normal; }

/* EdmondsansBold */
@font-face {
  font-family: 'EdmondsansBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/EdmondsansBold-Regular.otf) format("opentype");
  font-weight: normal;
  font-style: normal; }

/* FiraSansBlack */
@font-face {
  font-family: 'FiraSansBlack';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/FiraSansBlack-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'FiraSansBlack';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/FiraSansBlack-Italic.ttf) format("truetype");
  font-weight: normal;
  font-style: italic; }

/* FiraSansBold */
@font-face {
  font-family: 'FiraSansBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/FiraSansBold-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'FiraSansBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/FiraSansBold-Italic.ttf) format("truetype");
  font-weight: normal;
  font-style: italic; }

/* FiraSansCondensedBlack */
@font-face {
  font-family: 'FiraSansCondensedBlack';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/FiraSansCondensedBlack-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'FiraSansCondensedBlack';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/FiraSansCondensedBlack-Italic.ttf) format("truetype");
  font-weight: normal;
  font-style: italic; }

/* FiraSansCondensedBold */
@font-face {
  font-family: 'FiraSansCondensedBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/FiraSansCondensedBold-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'FiraSansCondensedBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/FiraSansCondensedBold-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'FiraSansCondensedBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/FiraSansCondensedBold-Italic.ttf) format("truetype");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'FiraSansCondensedBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/FiraSansCondensedBold-BoldItalic.ttf) format("truetype");
  font-weight: bold;
  font-style: italic; }

/* FiraSansExtraBold */
@font-face {
  font-family: 'FiraSansExtraBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/FiraSansExtraBold-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'FiraSansExtraBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/FiraSansExtraBold-Italic.ttf) format("truetype");
  font-weight: normal;
  font-style: italic; }

/* FiraSansExtraCondensedBlack */
@font-face {
  font-family: 'FiraSansExtraCondensedBlack';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/FiraSansExtraCondensedBlack-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'FiraSansExtraCondensedBlack';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/FiraSansExtraCondensedBlack-Italic.ttf) format("truetype");
  font-weight: normal;
  font-style: italic; }

/* FiraSansExtraCondensedBold */
@font-face {
  font-family: 'FiraSansExtraCondensedBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/FiraSansExtraCondensedBold-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'FiraSansExtraCondensedBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/FiraSansExtraCondensedBold-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'FiraSansExtraCondensedBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/FiraSansExtraCondensedBold-Italic.ttf) format("truetype");
  font-weight: normal;
  font-style: italic; }

/* FraicheSemiBold */
@font-face {
  font-family: 'FraicheSemiBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/FraicheSemiBold-Regular.otf) format("opentype");
  font-weight: normal;
  font-style: normal; }

/* FraicheTrapMedium */
@font-face {
  font-family: 'FraicheTrapMedium';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/FraicheTrapMedium-Regular.otf) format("opentype");
  font-weight: normal;
  font-style: normal; }

/* FraicheTrapSemiBold */
@font-face {
  font-family: 'FraicheTrapSemiBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/FraicheTrapSemiBold-Regular.otf) format("opentype");
  font-weight: normal;
  font-style: normal; }

/* FranklinGothicDemi */
@font-face {
  font-family: 'FranklinGothicDemi';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/FranklinGothicDemi-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'FranklinGothicDemi';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/FranklinGothicDemi-Italic.ttf) format("truetype");
  font-weight: normal;
  font-style: italic; }

/* FranklinGothicDemiCondensed */
@font-face {
  font-family: 'FranklinGothicDemiCondensed';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/FranklinGothicDemiCondensed-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* FranklinGothicHeavy */
@font-face {
  font-family: 'FranklinGothicHeavy';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/FranklinGothicHeavy-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'FranklinGothicHeavy';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/FranklinGothicHeavy-Italic.ttf) format("truetype");
  font-weight: normal;
  font-style: italic; }

/* FredokaOne */
@font-face {
  font-family: 'FredokaOne';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/FredokaOne-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* FuturaStdHeavy */
@font-face {
  font-family: 'FuturaStdHeavy';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/FuturaStdHeavy-Regular.otf) format("opentype");
  font-weight: normal;
  font-style: normal; }

/* GermaniaOne */
@font-face {
  font-family: 'GermaniaOne';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/GermaniaOne-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* GilSansMTBold */
@font-face {
  font-family: 'GilSansMTBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/GilSansMTBold-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'GilSansMTBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/GilSansMTBold-Italic.ttf) format("truetype");
  font-weight: normal;
  font-style: italic; }

/* GilSansMTExtCondensedBold */
@font-face {
  font-family: 'GilSansMTExtCondensedBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/GilSansMTExtCondensedBold-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* GilSansUltraBold */
@font-face {
  font-family: 'GilSansUltraBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/GilSansUltraBold-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* GilSansUltraBoldCondensed */
@font-face {
  font-family: 'GilSansUltraBoldCondensed';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/GilSansUltraBoldCondensed-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* Gineva */
@font-face {
  font-family: 'Gineva';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/Gineva-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* GothamBold */
@font-face {
  font-family: 'GothamBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/GothamBold-Regular.otf) format("opentype");
  font-weight: normal;
  font-style: normal; }

/* GravitasOne */
@font-face {
  font-family: 'GravitasOne';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/GravitasOne-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* Haettenschweiler */
@font-face {
  font-family: 'Haettenschweiler';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/Haettenschweiler-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* HanaleiFill */
@font-face {
  font-family: 'HanaleiFill';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/HanaleiFill-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* HandwrittenCrystal */
@font-face {
  font-family: 'HandwrittenCrystal';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/HandwrittenCrystal-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* Haudrey */
@font-face {
  font-family: 'Haudrey';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/Haudrey-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* Hitchcut */
@font-face {
  font-family: 'Hitchcut';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/Hitchcut-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* HostageScript */
@font-face {
  font-family: 'HostageScript';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/HostageScript.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* Impact */
@font-face {
  font-family: 'Impact';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/Impact-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* Intro */
@font-face {
  font-family: 'Intro';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/Intro-Regular.otf) format("opentype");
  font-weight: normal;
  font-style: normal; }

/* JellyBelly */
@font-face {
  font-family: 'JellyBelly';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/JellyBellyFont-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* KGHappySolid */
@font-face {
  font-family: 'KGHappySolid';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/KGHappySolid-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* KalamBold */
@font-face {
  font-family: 'KalamBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/KalamBold-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* KelsonSansBGBold */
@font-face {
  font-family: 'KelsonSansBGBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/KelsonSansBGBold-Regular.otf) format("opentype");
  font-weight: normal;
  font-style: normal; }

/* KlinicSlabBold */
@font-face {
  font-family: 'KlinicSlabBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/KlinicSlabBold-Regular.otf) format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'KlinicSlabBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/KlinicSlabBold-Italic.otf) format("opentype");
  font-weight: normal;
  font-style: italic; }

/* Knewave */
@font-face {
  font-family: 'Knewave';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/Knewave-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* Kohm */
@font-face {
  font-family: 'Kohm';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/Kohm-Regular.otf) format("opentype");
  font-weight: normal;
  font-style: normal; }

/* LatoBlack */
@font-face {
  font-family: 'LatoBlack';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/LatoBlack-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* LatoBold */
@font-face {
  font-family: 'LatoBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/LatoBold-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* Liberator */
@font-face {
  font-family: 'Liberator';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/Liberator-Regular.otf) format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Liberator';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/Liberator-Bold.otf) format("opentype");
  font-weight: bold;
  font-style: normal; }

/* LouisBold */
@font-face {
  font-family: 'LouisBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/LouisBold-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* LuckiestGuy */
@font-face {
  font-family: 'LuckiestGuy';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/LuckiestGuy-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* Madden */
@font-face {
  font-family: 'Madden';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/Madden-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* MerriweatherBlack */
@font-face {
  font-family: 'MerriweatherBlack';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/MerriweatherBlack-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'MerriweatherBlack';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/MerriweatherBlack-Italic.ttf) format("truetype");
  font-weight: normal;
  font-style: italic; }

/* MerriweatherBold */
@font-face {
  font-family: 'MerriweatherBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/MerriweatherBold-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'MerriweatherBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/MerriweatherBold-Italic.ttf) format("truetype");
  font-weight: normal;
  font-style: italic; }

/* ModernNo20 */
@font-face {
  font-family: 'ModernNo20';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/ModernNo20-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* Mogra */
@font-face {
  font-family: 'Mogra';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/Mogra-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* MontserratBlack */
@font-face {
  font-family: 'MontserratBlack';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/MontserratBlack-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'MontserratBlack';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/MontserratBlack-Italic.ttf) format("truetype");
  font-weight: normal;
  font-style: italic; }

/* MontserratBold */
@font-face {
  font-family: 'MontserratBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/MontserratBold-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'MontserratBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/MontserratBold-Italic.ttf) format("truetype");
  font-weight: normal;
  font-style: italic; }

/* MontserratExtraBold */
@font-face {
  font-family: 'MontserratExtraBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/MontserratExtraBold-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'MontserratExtraBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/MontserratExtraBold-Italic.ttf) format("truetype");
  font-weight: normal;
  font-style: italic; }

/* MortEndBold */
@font-face {
  font-family: 'MortEndBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/MortEnd-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal; }

/* MortEndExtraBold */
@font-face {
  font-family: 'MortEndExtraBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/MortEndExtraBold-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* OleoScript */
@font-face {
  font-family: 'OleoScript';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/OleoScript-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'OleoScript';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/OleoScript-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal; }

/* OpenSansCondensedBold */
@font-face {
  font-family: 'OpenSansCondensedBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/OpenSansCondensedBold-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* Orbitron*/
@font-face {
  font-family: 'Orbitron';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/Orbitron-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Orbitron';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/Orbitron-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal; }

/* OrbitronBlack*/
@font-face {
  font-family: 'OrbitronBlack';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/OrbitronBlack-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* Oswald */
@font-face {
  font-family: 'Oswald';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/Oswald-Combined.ttf) format("truetype"); }

/* Ovsyanka */
@font-face {
  font-family: 'Ovsyanka';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/Ovsyanka-Regular.otf) format("opentype");
  font-weight: normal;
  font-style: normal; }

/* OvsyankaPress */
@font-face {
  font-family: 'OvsyankaPress';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/OvsyankaPress-Regular.otf) format("opentype");
  font-weight: normal;
  font-style: normal; }

/* Pacifico */
@font-face {
  font-family: 'Pacifico';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/Pacifico-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* PassionOne */
@font-face {
  font-family: 'PassionOne';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/PassionOne-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'PassionOne';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/PassionOne-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal; }

/* PassionOneBlack */
@font-face {
  font-family: 'PassionOneBlack';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/PassionOneBlack-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* PermanentMarker */
@font-face {
  font-family: 'PermanentMarker';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/PermanentMarker-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* Piedra */
@font-face {
  font-family: 'Piedra';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/Piedra-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* PinkSunset */
@font-face {
  font-family: 'PinkSunset';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/PinkSunset-Regular.otf) format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'PinkSunset';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/PinkSunset-Italic.otf) format("opentype");
  font-weight: normal;
  font-style: italic; }

/* PixelRand */
@font-face {
  font-family: 'PixelRand';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/PixelRand-Regular.otf) format("opentype");
  font-weight: normal;
  font-style: normal; }

/* PoppinsBlack */
@font-face {
  font-family: 'PoppinsBlack';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/PoppinsBlack-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'PoppinsBlack';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/PoppinsBlack-Italic.ttf) format("truetype");
  font-weight: normal;
  font-style: italic; }

/* PoppinsBold */
@font-face {
  font-family: 'PoppinsBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/PoppinsBold-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'PoppinsBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/PoppinsBold-Italic.ttf) format("truetype");
  font-weight: normal;
  font-style: italic; }

/* PoppinsExtraBold */
@font-face {
  font-family: 'PoppinsExtraBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/PoppinsExtraBold-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'PoppinsExtraBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/PoppinsExtraBold-Italic.ttf) format("truetype");
  font-weight: normal;
  font-style: italic; }

/* ProximaNovaBold */
@font-face {
  font-family: 'ProximaNovaBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/ProximaNovaBold-Regular.otf) format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'ProximaNovaBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/ProximaNovaBold-Italic.otf) format("opentype");
  font-weight: normal;
  font-style: italic; }

/* RalewayBlack */
@font-face {
  font-family: 'RalewayBlack';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/RalewayBlack-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'RalewayBlack';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/RalewayBlack-Italic.ttf) format("truetype");
  font-weight: normal;
  font-style: italic; }

/* RalewayBold */
@font-face {
  font-family: 'RalewayBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/RalewayBold-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'RalewayBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/RalewayBold-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'RalewayBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/RalewayBold-Italic.ttf) format("truetype");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'RalewayBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/RalewayBold-BoldItalic.ttf) format("truetype");
  font-weight: bold;
  font-style: italic; }

/* RhinosRocks */
@font-face {
  font-family: 'RhinosRocksLeft';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/RhinosRocksLeft-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* Righteous */
@font-face {
  font-family: 'Righteous';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/Righteous-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* RobotoBlack */
@font-face {
  font-family: 'RobotoBlack';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/RobotoBlack-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'RobotoBlack';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/RobotoBlack-Italic.ttf) format("truetype");
  font-weight: normal;
  font-style: italic; }

/* RobotoBold */
@font-face {
  font-family: 'RobotoBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/RobotoBold-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'RobotoBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/RobotoBold-Italic.ttf) format("truetype");
  font-weight: normal;
  font-style: italic; }

/* RobotoCondensedBold */
@font-face {
  font-family: 'RobotoCondensedBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/RobotoCondensedBold-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'RobotoCondensedBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/RobotoCondensedBold-Italic.ttf) format("truetype");
  font-weight: normal;
  font-style: italic; }

/* Rockwell */
@font-face {
  font-family: 'Rockwell';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/Rockwell-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Rockwell';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/Rockwell-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Rockwell';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/Rockwell-Italic.ttf) format("truetype");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'Rockwell';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/Rockwell-BoldItalic.ttf) format("truetype");
  font-weight: bold;
  font-style: italic; }

/* RockwellExtraBold */
@font-face {
  font-family: 'RockwellExtraBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/RockwellExtraBold-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* RosebaySlab */
@font-face {
  font-family: 'RosebaySlab';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/RosebaySlab-Regular.otf) format("opentype");
  font-weight: normal;
  font-style: normal; }

/* RosebaySlabOblique */
@font-face {
  font-family: 'RosebaySlabOblique';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/RosebaySlab-Italic.otf) format("opentype");
  font-weight: normal;
  font-style: normal; }

/* Runboy */
@font-face {
  font-family: 'Runboy';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/Runboy-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* SegoeUI */
@font-face {
  font-family: 'SegoeUI';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/SegoeUI.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'SegoeUI';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/SegoeUI-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'SegoeUI';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/SegoeUI-Italic.ttf) format("truetype");
  font-weight: normal;
  font-style: italic; }

/* Shojumaru */
@font-face {
  font-family: 'Shojumaru';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/Shojumaru-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* SigmarOne */
@font-face {
  font-family: 'SigmarOne';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/SigmarOne-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* Signesha */
@font-face {
  font-family: 'Signesha';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/Signesha-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* SourceSansProBold */
@font-face {
  font-family: 'SourceSansProBold';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/SourceSansPro-Bold.otf) format("opentype");
  font-weight: Bold;
  font-style: normal; }

/* SourceSansProBlack */
@font-face {
  font-family: 'SourceSansProBlack';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/SourceSansProBlack-Regular.otf) format("opentype");
  font-weight: normal;
  font-style: normal; }

/* SpicyRice */
@font-face {
  font-family: 'SpicyRice';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/SpicyRice-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* Stolzl */
@font-face {
  font-family: 'Stolzl';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/Stolzl-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Stolzl';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/Stolzl-Book.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Stolzl';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/Stolzl-Light.ttf) format("truetype");
  font-weight: light;
  font-style: normal; }

/* TitanOne */
@font-face {
  font-family: 'TitanOne';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/TitanOne-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* ThirtyNineStencil */
@font-face {
  font-family: 'ThirtyNineStencil';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/ThirtyNineStencil-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

/* Zeichen */
@font-face {
  font-family: 'Zeichen';
  src: url(https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts/Zeichen-Regular.otf) format("opentype");
  font-weight: normal;
  font-style: normal; }

#display {
  width: 100%;
  overflow: hidden; }
  #display .scrollbars .scrollbar-horizontal {
    width: calc(100% - 27px);
    margin-left: 7px; }
  #display .scrollbars .scrollbar-vertical {
    height: calc(100% - 27px);
    margin-top: 7px; }
