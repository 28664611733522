@import 'variables';

.counter-modal {
  .modal-content {
    background-color: $body-bg-color;
    padding: 0;
  }

  .modal-header {
    background-color: $secondary;
    color: $white;
    padding: 1em;
    border-bottom: 1px solid $gray-900;
  }

  .modal-body-content-wrapper {
    padding: 2em;
  }

  .modal-footer {
    padding: 0 2em 2em 2em;
  }

  .setup-info-box {
    padding: 15px;
    margin: 15px;
    background-color: $gray-200;
    border: 1px solid $gray-300;
    border-radius: 8px;
  }

  .time-increment-button {
    height: 50px;
    width: 33%;
    margin: 0 8px;
    background-color: $white;

    &.is-selected {
      background-color: $secondary;
    }
  }
}