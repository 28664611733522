.size-entity-buttons {
  .size-dropdown {
    width: 20px;
    padding-right: 0;
    padding-left: 0;
  }

  .dropdown {
    display: inline-block;
  }

  .dropdown-item {
    border-bottom: 0;
  }
}
