@import 'variables';

.editor-new-dimensions {
  margin-left: .25em;

  .btn {
    height: 36px;
  }

  .dropdown-menu {
    color: black;
  }

  .dropdown-item {
    svg {
      margin-right: 1rem;
      text-align: center;
      width: 16px;

      &.pull-right {
        margin-right: -.75rem;
        margin-top: .25rem;
        width: auto;
      }
    }
  }
}
