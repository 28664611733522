@import "variables";

.entity-dimensions {
  .site-select {
    max-width: 240px;
    min-width: 120px;

    div[class$="-control"] {
      flex-wrap: nowrap;
    }
  }
}

.editor-dimension-select {
  .react-select__value-container {
    flex-wrap: nowrap;
    min-width: 0;
  }

  .Select-menu-outer {
    width: auto !important;
    max-width: 320px;
    border-color: $midnight-blue !important;
  }

  .aspect-ratio-sign-list {
    list-style-type: none;
    margin: 0;
    padding-left: 1rem;
    overflow: hidden;
  }

  .aspect-ratio-sign-list-item {
    line-height: 1.1;
    margin-bottom: 0.75rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &-sign-name {
      font-size: 14px;
      font-weight: bold;
    }
    &-sign-dimensions {
      font-size: 12px;
    }
  }
}
