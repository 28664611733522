@import 'variables';

#signup-page {
  background-color: $body-bg-color;
  color: $body-color;
  padding-top: 2rem;

  @media (min-width: 992px) {
    padding-top: 8rem;
  }

  .logo {
    max-width: 225px;
  }

  .signup-button {
    border-color: $secondary;
  }

  hr {
    border-top: 2px dashed $blue-600;
  }
}
