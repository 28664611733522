/**
 * This is a fork of the react-select css, but converted to scss and with the colors split out into themes.
 * There is a dark and a light theme that uses the global variables.
 */

@import 'variables';

$header: (
  arrow-color: $blue-400,
  bg-color: $blue-600,
  border-color: $white,
  color: $white,
  disabled-bg-color: $blue-600,
  focus-color: $primary,
  focused-bg-color: lighten($blue-600, 20%),
  multi-border-color: $primary,
  option-color: $white,
  selected-bg-color: lighten($blue-600, 10%),
);

$dark: (
  arrow-color: $blue-400,
  bg-color: $blue-600,
  border-color: $white,
  color: $white,
  disabled-bg-color: $blue-600,
  focus-color: $primary,
  focused-bg-color: lighten($blue-600, 20%),
  multi-border-color: $primary,
  option-color: $white,
  selected-bg-color: lighten($blue-600, 10%),
);

$light: (
  arrow-color: $gray-400,
  bg-color: $white,
  border-color: $gray-300,
  color: $gray-800,
  disabled-bg-color: $gray-100,
  focus-color: $primary,
  focused-bg-color: darken($white, 20%),
  multi-border-color: $primary,
  option-color: $gray-500,
  selected-bg-color: darken($white, 10%),
);

$inputWhite: (
  arrow-color: $gray-400,
  bg-color: $white,
  border-color: $white,
  color: $gray-800,
  disabled-bg-color: $gray-100,
  focus-color: $primary,
  focused-bg-color: darken($white, 20%),
  multi-border-color: $primary,
  option-color: $gray-500,
  selected-bg-color: darken($white, 10%),
);

$themes: (
  dark: $dark,
  header: $header,
  input-white: $inputWhite,
  light: $light
);

@keyframes Select-animation-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes Select-animation-spin {
  to {
    transform: rotate(1turn);
  }
}

.Select {
  position: relative;

  input::-webkit-contacts-auto-fill-button,
  input::-webkit-credentials-auto-fill-button {
    display: none !important;
  }

  input::-ms-clear {
    display: none !important;
  }

  input::-ms-reveal {
    display: none !important;
  }

  &,
  div,
  input,
  span {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  &.is-disabled .Select-arrow-zone {
    cursor: default;
    pointer-events: none;
    opacity: 0.35;
  }

  &.is-disabled > .Select-control:hover {
    box-shadow: none;
  }

  &.is-open > .Select-control {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  &.is-open > .Select-control .Select-arrow {
    top: -2px;
    border-width: 0 5px 5px;
  }

  &.is-searchable.is-open > .Select-control {
    cursor: text;
  }

  &.is-searchable.is-focused:not(.is-open) > .Select-control {
    cursor: text;
  }

  &.is-focused:not(.is-open) > .Select-control {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1);
  }

  &.has-value.is-clearable.Select--single > .Select-control .Select-value {
    padding-right: 42px;
  }

  &.has-value.Select--single > .Select-control .Select-value a.Select-value-label,
  &.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label {
    cursor: pointer;
    text-decoration: none;
  }

  &.has-value.Select--single > .Select-control .Select-value a.Select-value-label:hover,
  &.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:hover,
  &.has-value.Select--single > .Select-control .Select-value a.Select-value-label:focus,
  &.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:focus {
    outline: none;
    text-decoration: underline;
  }

  &.has-value.is-pseudo-focused .Select-input {
    opacity: 0;
  }

  &.Select--rtl {
    direction: rtl;
    text-align: right;
  }

  .Select-control {
    border-radius: $default-radius;
    cursor: default;
    display: table;
    border-spacing: 0;
    border-collapse: separate;
    height: 36px;
    outline: none;
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  .Select-control .Select-input:focus {
    outline: none;
  }

  .Select-placeholder,
  &.Select--single > .Select-control .Select-value {
    bottom: 0;
    left: 0;
    line-height: 34px;
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    right: 0;
    top: 0;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .Select-input {
    height: 34px;
    padding-left: 10px;
    padding-right: 10px;
    vertical-align: middle;
  }

  .Select-input > input {
    width: 100%;
    background: none transparent;
    border: 0 none;
    box-shadow: none;
    cursor: default;
    display: inline-block;
    font-family: inherit;
    font-size: inherit;
    margin: 0;
    outline: none;
    line-height: 17px;
    /* For IE 8 compatibility */
    padding: 8px 0 12px;
    /* For IE 8 compatibility */
    -webkit-appearance: none;
  }

  .is-focused .Select-input > input {
    cursor: text;
  }

  .has-value.is-pseudo-focused .Select-input {
    opacity: 0;
  }

  .Select-control:not(.is-searchable) > .Select-input {
    outline: none;
  }

  .Select-loading-zone {
    cursor: pointer;
    display: table-cell;
    position: relative;
    text-align: center;
    vertical-align: middle;
    width: 16px;
  }

  .Select-loading {
    -webkit-animation: Select-animation-spin 400ms infinite linear;
    -o-animation: Select-animation-spin 400ms infinite linear;
    animation: Select-animation-spin 400ms infinite linear;
    width: 16px;
    height: 16px;
    box-sizing: border-box;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    vertical-align: middle;
  }

  .Select-clear-zone {
    -webkit-animation: Select-animation-fadeIn 200ms;
    -o-animation: Select-animation-fadeIn 200ms;
    animation: Select-animation-fadeIn 200ms;
    cursor: pointer;
    display: table-cell;
    position: relative;
    text-align: center;
    vertical-align: middle;
    width: 17px;
  }

  .Select-clear {
    display: inline-block;
    font-size: 18px;
    line-height: 1;
  }

  &.Select--multi .Select-clear-zone {
    width: 17px;
  }

  .Select-arrow-zone {
    cursor: pointer;
    display: table-cell;
    position: relative;
    text-align: center;
    vertical-align: middle;
    width: 25px;
    padding-right: 5px;
  }

  &.Select--rtl .Select-arrow-zone {
    padding-right: 0;
    padding-left: 5px;
  }

  .Select-arrow {
    border-style: solid;
    border-width: 5px 5px 2.5px;
    display: inline-block;
    height: 0;
    width: 0;
    position: relative;
  }

  .Select-control > *:last-child {
    padding-right: 5px;
  }

  &.Select--multi .Select-multi-value-wrapper {
    display: inline-block;
  }

  .Select-aria-only {
    position: absolute;
    display: inline-block;
    height: 1px;
    width: 1px;
    margin: -1px;
    clip: rect(0, 0, 0, 0);
    overflow: hidden;
    float: left;
  }

  .Select-menu-outer {
    border-bottom-right-radius: $default-radius;
    border-bottom-left-radius: $default-radius;
    box-sizing: border-box;
    margin-top: -1px;
    max-height: 200px;
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    z-index: 1;
    -webkit-overflow-scrolling: touch;
  }

  .Select-menu {
    max-height: 198px;
    overflow-y: auto;
  }

  .Select-option {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    padding: 8px 10px;
  }

  .Select-option:last-child {
    border-bottom-right-radius: $default-radius;
    border-bottom-left-radius: $default-radius;
  }

  .Select-option.is-disabled {
    cursor: default;
  }

  .Select-noresults {
    box-sizing: border-box;
    cursor: default;
    display: block;
    padding: 8px 10px;
  }

  &.Select--multi {
    .Select-input {
      vertical-align: middle;
      margin-left: 10px;
      padding: 0;
    }

    &.Select--rtl .Select-input {
      margin-left: 0;
      margin-right: 10px;
    }

    &.has-value .Select-input {
      margin-left: 5px;
    }

    .Select-value {
      border-radius: 2px;
      display: inline-block;
      font-size: 0.9em;
      line-height: 1.4;
      margin-left: 5px;
      margin-top: 5px;
      vertical-align: top;
    }

    .Select-value-icon,
    .Select-value-label {
      display: inline-block;
      vertical-align: middle;
    }

    .Select-value-label {
      border-bottom-right-radius: 2px;
      border-top-right-radius: 2px;
      cursor: default;
      padding: 2px 5px;
    }

    a.Select-value-label {
      cursor: pointer;
      text-decoration: none;
    }

    a.Select-value-label:hover {
      text-decoration: underline;
    }

    .Select-value-icon {
      cursor: pointer;
      border-bottom-left-radius: 2px;
      border-top-left-radius: 2px;
      padding: 1px 5px 3px;
    }

    &.Select--rtl .Select-value {
      margin-left: 0;
      margin-right: 5px;
    }

    &.Select--rtl .Select-value-icon {
      border-right: none;
    }

    &.is-disabled .Select-value-icon {
      cursor: not-allowed;
    }
  }
}

@each $theme-name, $theme in $themes {
  .Select.theme-#{$theme-name} {
    &.is-disabled > .Select-control {
      background-color: map-get($theme, 'disabled-bg-color');
    }

    &.is-open > .Select-control {
      background-color: map-get($theme, 'bg-color');
      border-color: map-get($theme, 'border-color');
    }

    &.is-open > .Select-control .Select-arrow {
      border-color: transparent transparent map-get($theme, 'arrow-color');
    }

    &.is-focused > .Select-control {
      background-color: map-get($theme, 'bg-color');
    }

    &.is-focused:not(.is-open) > .Select-control {
      background-color: map-get($theme, 'bg-color');
      border-color: map-get($theme, 'focus-color');
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1);
    }

    &.has-value.Select--single > .Select-control .Select-value .Select-value-label,
    &.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
      color: map-get($theme, 'color');
    }

    &.has-value.Select--single > .Select-control .Select-value a.Select-value-label:hover,
    &.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:hover,
    &.has-value.Select--single > .Select-control .Select-value a.Select-value-label:focus,
    &.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:focus {
      color: map-get($theme, 'focus-color');
    }

    &.has-value.Select--single > .Select-control .Select-value a.Select-value-label:focus,
    &.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:focus {
      background-color: map-get($theme, 'bg-color');
    }

    &.is-open .Select-arrow,
    .Select-arrow-zone:hover > .Select-arrow {
      border-top-color: map-get($theme, 'focused-bg-color');
    }

    .Select-control {
      background-color: map-get($theme, 'bg-color');
      border: 1px solid map-get($theme, 'border-color');
      color: map-get($theme, 'color');
    }

    .Select-control:hover {
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    }

    .Select-control .Select-input:focus {
      background-color: map-get($theme, 'bg-color');
    }

    .Select-placeholder,
    &.Select--single > .Select-control .Select-value {
      color: map-get($theme, 'arrow-color');
    }

    .Select-loading {
      border: 2px solid map-get($theme, 'border-color');
      border-right-color: map-get($theme, 'color');
    }

    .Select-clear-zone {
      color: map-get($theme, 'arrow-color');
    }

    .Select-clear-zone:hover {
      color: #D0021B;
    }

    .Select-arrow {
      border-color: map-get($theme, 'arrow-color') transparent transparent;
    }

    .Select-input > input {
      color: map-get($theme, 'color');
    }

    .Select-menu-outer {
      background-color: map-get($theme, 'bg-color');
      border: 1px solid map-get($theme, 'border-color');
      border-top-color: map-get($theme, 'focused-bg-color');
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    }

    .Select-option {
      background-color: map-get($theme, 'bg-color');
      color: map-get($theme, 'option-color');
    }

    .Select-option.is-selected {
      background-color: map-get($theme, 'selected-bg-color');
      color: map-get($theme, 'color');
    }

    .Select-option.is-focused {
      background-color: map-get($theme, 'focused-bg-color');
      color: map-get($theme, 'color');
    }

    .Select-option.is-disabled {
      color: map-get($theme, 'border-color');
    }

    .Select-noresults {
      color: map-get($theme, 'arrow-color');
    }

    &.Select--multi {
      .Select-value {
        background-color: $blue-100;
        border: 1px solid map-get($theme, 'multi-border-color');
        color: map-get($theme, 'focus-color');
      }

      a.Select-value-label {
        color: map-get($theme, 'arrow-color');
      }

      .Select-value-icon {
        border-right: 1px solid map-get($theme, 'multi-border-color');
        padding: 1px 5px 3px;
      }

      .Select-value-icon:hover,
      .Select-value-icon:focus {
        background-color: #d8eafd;
        color: #0071e6;
      }

      .Select-value-icon:active {
        background-color: map-get($theme, 'multi-border-color');
      }

      &.Select--rtl .Select-value-icon {
        border-left: 1px solid map-get($theme, 'multi-border-color');
      }

      &.is-disabled .Select-value {
        background-color: map-get($theme, 'disabled-bg-color');
        border: 1px solid map-get($theme, 'disabled-bg-color');
        color: map-get($theme, 'color');
      }

      &.is-disabled .Select-value-icon {
        border-right: 1px solid map-get($theme, 'disabled-bg-color');
      }

      &.is-disabled .Select-value-icon:hover,
      &.is-disabled .Select-value-icon:focus,
      &.is-disabled .Select-value-icon:active {
        background-color: map-get($theme, 'disabled-bg-color');
      }
    }
  }
}
