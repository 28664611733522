@import 'variables';

$max-video-height: 14em;

.edit-video-controls {
  .video-selector {
    margin-bottom: 1rem;
    min-height: 20px;
    position: relative;
  }

  .video-change-button {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    cursor: pointer;
    padding: 0;
  }

  .video-thumbnail {
    width: auto;
    max-width: 220px;
    max-height: $max-video-height;
    border-radius: $default-radius;
  }

  .video-edit-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    svg {
      background-color: $black;
      border-radius: 100%;
      color: $white;
      padding: 10px;
      opacity: 0.75;
    }
  }

}
