@import 'variables';

.select-file.file-item {
  width: 224px;
  margin: .5rem;
  overflow: hidden;
  position: relative;

  &.is-pending {
    opacity: .5;
  }

  &.is-loading {
    .loading-icon {
      position: absolute;
      transition: all 200ms ease;
    }
  }

  .file-name {
    font-size: 1em;
    max-width: 100%;
    color: $white;
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 0 6px;
  }

  .pending-label {
    max-width: 100%;
    overflow: hidden;
    color: $white;
    position: absolute;
    top: 33px;
  }

  .error-label {
    max-width: 100%;
    overflow: hidden;
    color: $white;
    position: absolute;
    top: 33px;
  }

  .img {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
  }

  .file-actions {
    display: flex;
    visibility: hidden;
    padding: 4px 0;
    justify-content: flex-end;
    align-content: center;
  }

  &:hover .file-actions {
    visibility: visible;
  }
}
