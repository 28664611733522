/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.user-file-folders .folders-list-header {
  padding: 0.5rem 0.5rem 0.5rem 0.3rem; }

.user-file-folders .ai-tool-text {
  display: block;
  font-size: 11px;
  font-style: italic;
  margin-bottom: 4px;
  line-height: 1; }

.user-file-folders .folders-list-item {
  border-radius: 6px;
  cursor: pointer;
  padding: 0.2rem 1rem;
  transition: background-color 250ms ease; }
  .user-file-folders .folders-list-item.active {
    background-color: #D1D3D4; }
  .user-file-folders .folders-list-item .folders-list-item-count {
    opacity: 0.5; }

.user-file-folders .fa-folder {
  color: #008BC2;
  font-size: 1.2rem;
  transition: color 250ms ease; }

.user-file-folders .folders-list-item.active .fa-folder {
  color: #fff; }
