/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.select-media.file-item {
  width: 224px;
  margin: .5rem;
  overflow: hidden;
  position: relative; }
  .select-media.file-item.is-pending {
    opacity: .5; }
  .select-media.file-item.is-loading .loading-icon {
    position: absolute;
    transition: all 200ms ease; }
  .select-media.file-item .file-name {
    font-size: 1em;
    max-width: 100%;
    color: #fff;
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 0 6px; }
  .select-media.file-item .pending-label {
    max-width: 100%;
    overflow: hidden;
    color: #fff;
    position: absolute;
    top: 33px; }
  .select-media.file-item .error-label {
    max-width: 100%;
    overflow: hidden;
    color: #fff;
    position: absolute;
    top: 33px; }
  .select-media.file-item .img {
    position: relative;
    border-radius: 10px;
    overflow: hidden; }
  .select-media.file-item .file-actions {
    display: flex;
    visibility: hidden;
    padding: 4px 0;
    justify-content: flex-end;
    align-content: center; }
  .select-media.file-item:hover .file-actions {
    visibility: visible; }
