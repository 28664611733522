/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.banner-container {
  width: 100%;
  padding: 10px 0px;
  background-color: #E9ECEF;
  margin: 20px 0;
  position: relative; }

.banner-text {
  font-size: 1.5em;
  font-weight: 700;
  text-align: center;
  margin: 0;
  position: inherit;
  z-index: 50; }
  .banner-text-logo {
    height: 2em;
    padding: 0 10px; }
  .banner-text .red {
    color: #FF3838; }

.left-color {
  width: 10%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; }
  .left-color.nextled {
    background-image: linear-gradient(to right, #4BE5F7, #E9ECEF); }

.right-color {
  width: 10%;
  height: 100%;
  position: absolute;
  right: 0;
  bottom: 0; }
  .right-color.nextled {
    background-image: linear-gradient(to right, #E9ECEF, #FF3838); }
