/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.transition-entity {
  display: flex;
  flex-direction: column;
  margin: 20px;
  padding: 15px;
  height: 255px;
  width: 180px;
  cursor: pointer; }
  .transition-entity:hover, .transition-entity:focus {
    background-color: #008BC2; }
  .transition-entity.active {
    background-color: #008BC2; }
  .transition-entity .transition-entity-img {
    height: 150px;
    width: 150px; }
  .transition-entity .transition-entity-label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 8px 0px 4px;
    font-size: 14px;
    font-weight: 600; }
  .transition-entity .transition-entity-select {
    width: 100%;
    border: 1px #008BC2 solid;
    border-radius: 4px; }
