/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.content-new-item {
  border-radius: 10px;
  flex: 0 0 15rem;
  overflow: hidden;
  margin: 1rem;
  transition: all 200ms ease;
  cursor: pointer;
  background-image: linear-gradient(90deg, #48BCE4 50%, #5DCDEB 75%, #48BCE4 100%);
  background-position: -15rem;
  background-size: 30rem; }
  .content-new-item:hover {
    margin: 0.5rem;
    flex: 0 0 16rem;
    background-position: 0; }
  .content-new-item .container-16-9 {
    position: relative;
    padding-top: 56.25%; }
  .content-new-item .blank-image-container {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 2em 2.5em 0em 2.5em; }
  .content-new-item .blank-image {
    border: 2px dashed #307DBE;
    height: 100%; }
  .content-new-item .blank-icon {
    color: #000; }
  .content-new-item .content-name {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .content-new-item .img {
    background-position: center;
    background-size: cover; }
