@import 'variables';

#admin-content-request-list-page {
  .role-badge {
    text-transform: capitalize;
  }

  .smart-table {
    .table {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      .table-header {
        background: linear-gradient(135deg, #115A86 0%, #00314F 100%);
      }

      th {
        border-top: 0;
      }
    }
  }

  .action-button {
    padding: 0 .35em;
    font-size: 1.75em;
  }
}
