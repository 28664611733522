@import 'variables';

$modal-width: 75vw;
$modal-height: 70vh;
$upper-height: 75px;

.transition-library-modal {
  max-width: $modal-width;

  .modal-header {
    padding: 1em;
    border-bottom: 1px solid $gray-900;

    .close {
      color: $body-color;
    }
  }

  .modal-content {
    background-color: $body-bg-color;
    width: $modal-width;
    scroll-behavior: auto;
  }

  .modal-body-content-wrapper {
    height: $modal-height;
    width: 100%;
    overflow-y: scroll;
  }

  .flow-direction-button {
    text-transform: capitalize;
    padding: 0px 25px;
    font-size: 14px;
  }
}