/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.presto-entity {
  min-height: 50px;
  opacity: .5;
  position: relative;
  padding: 1rem 0 1rem 1.25rem;
  user-select: none;
  /* Flex Styles */
  flex: 0 1 auto;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start; }
  .presto-entity.active {
    opacity: 1; }
    .presto-entity.active .step-arrow .arrow-marker-line {
      stroke: #000; }
    .presto-entity.active .step-arrow .arrow-marker-head {
      fill: #000; }
  .presto-entity.is-previous {
    opacity: 1; }
    .presto-entity.is-previous .badge-pill {
      background-color: #000;
      color: #fff; }
    .presto-entity.is-previous .step-arrow .arrow-marker-line {
      stroke: #000; }
    .presto-entity.is-previous .step-arrow .arrow-marker-head {
      fill: #000; }
    .presto-entity.is-previous .entity-name {
      color: #000; }
  .presto-entity .badge-pill {
    background-color: #fff;
    color: #000; }
  .presto-entity .step-arrow {
    display: inline-block;
    flex: 0 1 auto;
    margin-top: 4px;
    margin-right: 1em;
    opacity: .5; }
  .presto-entity .entity-name-wrapper {
    display: inline-block;
    flex: 0 1 auto;
    max-width: 160px;
    overflow: hidden; }
    .presto-entity .entity-name-wrapper .entity-order {
      float: left; }
    .presto-entity .entity-name-wrapper .entity-name {
      display: inline-block;
      font-size: .9em;
      float: left;
      margin-top: 3px;
      max-width: 120px;
      word-wrap: break-word; }
  .presto-entity .entity-order {
    margin-right: .75em; }
  .presto-entity .entity-name {
    color: #fff; }
  .presto-entity .entity-actions-wrapper {
    display: flex;
    align-items: center; }
  .presto-entity .entity-actions .btn-link {
    position: relative;
    top: 1px;
    color: #292929; }
  .presto-entity .entity-reorder-action {
    cursor: grab;
    color: #292929;
    margin-left: .5rem; }
