/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.search-bar.theme-white {
  border: solid #6D6D6D 1px;
  border-radius: 25px; }
  .search-bar.theme-white .form-control {
    font-size: 1em;
    background: #fff;
    border: 0;
    color: #000; }

.search-bar.theme-clear {
  border: solid #6D6D6D 1px;
  border-radius: 25px; }
  .search-bar.theme-clear .form-control {
    font-size: 1em;
    background: transparent;
    border: 0;
    color: #000; }
  .search-bar.theme-clear .search-prepend {
    background: transparent;
    border: 0;
    margin-left: 20px;
    color: #6D6D6D;
    font-size: 1em;
    padding: 0; }

.search-bar .input-group {
  flex-wrap: nowrap; }

.search-bar .menu-preview-image {
  display: inline-block; }
  .search-bar .menu-preview-image img {
    max-width: 100px;
    max-height: 100px;
    margin-right: 1em; }
