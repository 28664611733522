@import 'variables';

$checkmark-size: 40px;
$crossmark-size: 16px;

.plan {
  position: relative;
  height: 100%;
  padding: 50px 30px 120px;

  .plan-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 5px 15px;
    color: $white;
    text-transform: uppercase;
    font-size: 0.80em;
    text-align: center;
    font-weight: bold;

    &.background-primary {
      background-color: $primary;
    }

    &.background-secondary {
      background-color: $secondary;
    }
  }

  .plan-indicators {
    display: flex;
  
    .plan-step-indicator {
      width: 25%;
      height: 8px;
      background-color: $light-gray;
      transform: skew(340deg);
      margin: 0 3px;

      &.has-color {
        background-color: $secondary;
      }
    }
  }
  
  .plan-title {
    text-transform: uppercase;
    color: $midnight-blue;
    font-weight: 700;
    margin: 25px 0 5px 0;

    &.large {
      font-size: 32px;
    }
  }
  
  .plan-price {
    color: $dark-gray;
    font-weight: 300;
    padding-bottom: 20px;
  
    .plan-currency {
      font-size: 15px;
    }
  
    .plan-price-number {
      font-size: 40px;
      margin: 0 5px;

      &.small-inline {
        font-size: 24px;
        line-height: 24px;
        font-weight: 400;
        margin: 0;
      }
    }
  }
  
  .plan-description {
    padding-bottom: 30px;

    &-title {
      color: $secondary;
      font-style: italic;
      font-size: 0.90em;
      font-weight: bold;
    }
  
    &-content {
      color: $dark-gray;
      font-size: 0.90em;
      min-height: 3em;
    }
  }

  .plan-features {
    .plan-feature-content {
      padding-left: 10px;
      color: $dark-gray;
      font-size: 0.90em;
    }
  }

  .plan-footer {
    position: absolute;
    bottom: 20px;
    left: 30px;
    right: 30px;

    .plan-select-button {
      width: 100%;
      display: block;
      text-transform: uppercase;
      color: $secondary;
      font-weight: 300;
      font-size: 0.85em;
      border-radius: 0;
      border: 1px solid $secondary;
      margin-top: 10px;

      &.trial {
        background-image: linear-gradient(150deg, $primary 15%, $secondary);
        color: $white;
        border: none;
      }
    }
  }

  &.popular {
    background-color: $lightest-gray;

    #innerCircle {
      fill: $lightest-gray;
    }
  }

  &.recommended {
    background-color: $secondary;
    color: $white;

    #innerCircle {
      fill: $secondary;
    }

    #checkmark {
      fill: $primary;
    }

    .plan-title {
      color: $white;
    }

    .plan-indicators {
      .plan-step-indicator {
        &.has-color {
          background-color: $white;
        }
      }
    }

    .plan-price {
      color: $white;
    }

    .plan-description {
      .plan-description-title {
        color: $primary;
      }

      .plan-description-content {
        color: $white;
      }
    }

    .plan-features {
      .plan-feature-content {
        color: $white;
      }

      .checkmark {
        border-bottom: 3px solid $primary;
        border-right: 3px solid $primary;
      }
    }

    .plan-footer {
      .plan-select-button {
        color: $white;
        border: 1px solid $white;

        &.trial {
          color: $secondary;
          background-color: $white;
          background-image: none;
        }
      }
    }
  }

  .plan-emphasized-message {
    background-image: linear-gradient(120deg, $secondary 15%, $primary);
    color: $white;
    padding: 10px;
    margin-top: 25px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 18px;
  }
}
