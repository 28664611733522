@import "_variables";

.entity-timeline-item {
  display: flex;

  .entity-info {
    width: 300px;
    margin: 0px 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .entity-name {
      width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .entity-time-control {
    flex-grow: 1;
    margin-right: 50px;
  }

  &.active {
    background-color: $lightest-gray;
  }

  .entity-order {
    margin-right: 1em;
  }

  .entity-name-wrapper {
    max-width: 199px;
    overflow: hidden;

    .entity-order {
      float: left;
    }
    .entity-name {
      float: left;
      max-width: 160px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .dropdown-item {
    svg {
      margin-right: 1rem;
      text-align: center;
      width: 16px;
    }
  }

  .entity-actions-wrapper {
    display: flex;
    align-items: center;
  }

  .entity-actions {
    .btn-link {
      position: relative;
      top: 1px;
      color: $body-color;
    }
  }
}