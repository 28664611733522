/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.edit-line-controls .line-group {
  background-color: #E9ECEF; }

.edit-line-controls .control-group-help {
  font-size: .75rem;
  font-style: italic; }
