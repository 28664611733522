@import 'variables';

.content-items {
  position: relative;
  max-width: 1800px;

  .fade-enter {
    opacity: 0;
    transform: scale(0.5);
  }
  .fade-enter-active {
    opacity: 1;
    transform: scale(1);
  }
  .fade-exit {
    opacity: 1;
    transform: scale(1);
  }
  .fade-exit-active {
    opacity: 0;
    transform: scale(0.5);
  }
}
