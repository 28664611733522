@import "variables";
@import 'mixins/scrollbars.scss';

$dark-bg-color: $gray-800;
$width-of-left-bar: 290px;
$total-bar-width: $width-of-left-bar;

$bottom-bar-height: 91px;

#presto-page {
  background: center/11px url("../../../assets/images/display-bg.png") repeat;
  height: 100%;
  overflow: hidden;

  .presto-top {
    height: calc(100vh - #{$main-nav-bar-height} - #{$bottom-bar-height});
    overflow: hidden;

    /* Flex Styles */
    display: flex;
    align-items: stretch;
    flex-direction: row;
    justify-content: space-between;

    &.has-message-bar {
      height: calc(100vh - #{$main-nav-bar-height} - #{$bottom-bar-height} - 95px);
  
      @media (min-width: map-get($grid-breakpoints, sm)) {
        height: calc(100vh - #{$main-nav-bar-height} - #{$bottom-bar-height} - 48px);
      }
    }
  }

  #display-sidebar {
    &.is-minimized {
      flex: 0 0 38px; // don't grow, don't shrink, start at 38px;
    }

    &.is-expanded {
      flex: 0 0 $width-of-left-bar; // don't grow, don't shrink, start at 250px;
    }
  }

  .main-display {
    flex: 2 0 auto;
    height: 100%;
    width: calc(100vw - #{$total-bar-width});
    position: relative;

    &:active,
    &:focus,
    &:hover {
      .display-controls {
        right: 15px;
      }

      .scrollbars {
        .scrollbar-horizontal,
        .scrollbar-vertical {
          @include scrollbars;
        }
      }
    }
  }

  .display-wrapper {
    height: 100%;
  }

  .display-controls {
    border-bottom: none;
    width: 50px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    transition: right .1s ease-out;

    /* Flex Styles */
    display: flex;
    flex-direction: column;
    justify-content: center;

    .controls-wrapper {
      border: 1px solid $gray-900;
      padding: 0;
      width: 100%;

      &:first-of-type {
        border-bottom: 0;
      }
      &:last-of-type {
        border-top: 0;
      }
    }

    .display-control {
      min-height: 160px;
    }

    .zoom-control {
      min-height: 200px;
    }
  }
}
