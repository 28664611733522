/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
/**
 * Loads all the fonts needed for the editor.
 */
.display-text {
  color: #fff;
  line-height: 1.00;
  overflow: hidden; }
  .display-text .display-text-pre {
    white-space: pre-wrap; }
  .display-text .md-align-left {
    display: block;
    text-align: left; }
  .display-text .md-align-center {
    display: block;
    text-align: center; }
  .display-text .md-align-right {
    display: block;
    text-align: right; }
  .display-text .md-align-justify {
    display: block;
    text-align: center; }
  .display-text .md-underline {
    text-decoration: underline; }
  .display-text .md-font-size-5 {
    font-size: 5px; }
  .display-text .md-font-size-6 {
    font-size: 6px; }
  .display-text .md-font-size-7 {
    font-size: 7px; }
  .display-text .md-font-size-8 {
    font-size: 8px; }
  .display-text .md-font-size-9 {
    font-size: 9px; }
  .display-text .md-font-size-10 {
    font-size: 10px; }
  .display-text .md-font-size-11 {
    font-size: 11px; }
  .display-text .md-font-size-12 {
    font-size: 12px; }
  .display-text .md-font-size-13 {
    font-size: 13px; }
  .display-text .md-font-size-14 {
    font-size: 14px; }
  .display-text .md-font-size-15 {
    font-size: 15px; }
  .display-text .md-font-size-16 {
    font-size: 16px; }
  .display-text .md-font-size-17 {
    font-size: 17px; }
  .display-text .md-font-size-18 {
    font-size: 18px; }
  .display-text .md-font-size-19 {
    font-size: 19px; }
  .display-text .md-font-size-20 {
    font-size: 20px; }
  .display-text .md-font-size-21 {
    font-size: 21px; }
  .display-text .md-font-size-22 {
    font-size: 22px; }
  .display-text .md-font-size-23 {
    font-size: 23px; }
  .display-text .md-font-size-24 {
    font-size: 24px; }
  .display-text .md-font-size-25 {
    font-size: 25px; }
  .display-text .md-font-size-26 {
    font-size: 26px; }
  .display-text .md-font-size-27 {
    font-size: 27px; }
  .display-text .md-font-size-28 {
    font-size: 28px; }
  .display-text .md-font-size-29 {
    font-size: 29px; }
  .display-text .md-font-size-30 {
    font-size: 30px; }
  .display-text .md-font-size-31 {
    font-size: 31px; }
  .display-text .md-font-size-32 {
    font-size: 32px; }
  .display-text .md-font-size-33 {
    font-size: 33px; }
  .display-text .md-font-size-34 {
    font-size: 34px; }
  .display-text .md-font-size-35 {
    font-size: 35px; }
  .display-text .md-font-size-36 {
    font-size: 36px; }
  .display-text .md-font-size-37 {
    font-size: 37px; }
  .display-text .md-font-size-38 {
    font-size: 38px; }
  .display-text .md-font-size-39 {
    font-size: 39px; }
  .display-text .md-font-size-40 {
    font-size: 40px; }
  .display-text .md-font-size-41 {
    font-size: 41px; }
  .display-text .md-font-size-42 {
    font-size: 42px; }
  .display-text .md-font-size-43 {
    font-size: 43px; }
  .display-text .md-font-size-44 {
    font-size: 44px; }
  .display-text .md-font-size-45 {
    font-size: 45px; }
  .display-text .md-font-size-46 {
    font-size: 46px; }
  .display-text .md-font-size-47 {
    font-size: 47px; }
  .display-text .md-font-size-48 {
    font-size: 48px; }
  .display-text .md-font-size-49 {
    font-size: 49px; }
  .display-text .md-font-size-50 {
    font-size: 50px; }
  .display-text .md-font-arial {
    font-family: Arial, Helvetica, sans-serif; }
  .display-text .md-font-century {
    font-family: Century Gothic, sans-serif; }
  .display-text .md-font-courier {
    font-family: "Courier New", Courier, monospace; }
  .display-text .md-font-georgia {
    font-family: Georgia, serif; }
  .display-text .md-font-impact {
    font-family: Impact, Charcoal, sans-serif; }
  .display-text .md-font-lucida {
    font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif; }
  .display-text .md-font-palatino {
    font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif; }
  .display-text .md-font-tahoma {
    font-family: Tahoma, Geneva, sans-serif; }
  .display-text .md-font-times {
    font-family: "Times New Roman", Times, serif; }
  .display-text .md-font-verdana {
    font-family: Verdana, Geneva, sans-serif; }
  .display-text .md-font-alfaslabone {
    font-family: AlfaSlabOne, sans-serif; }
  .display-text .md-font-anton {
    font-family: Anton, sans-serif; }
  .display-text .md-font-archivoblack {
    font-family: ArchivoBlack, sans-serif; }
  .display-text .md-font-arizona {
    font-family: Arizona, sans-serif; }
  .display-text .md-font-arvo {
    font-family: Arvo, sans-serif; }
  .display-text .md-font-avenirnextltprobold {
    font-family: AvenirNextLTProBold, sans-serif; }
  .display-text .md-font-avenirnextltproboldcondensed {
    font-family: AvenirNextLTProBoldCondensed, sans-serif; }
  .display-text .md-font-avenirnextltprodemi {
    font-family: AvenirNextLTProDemi, sans-serif; }
  .display-text .md-font-avenirnextltprodemicondensed {
    font-family: AvenirNextLTProDemiCondensed, sans-serif; }
  .display-text .md-font-baloo {
    font-family: Baloo, sans-serif; }
  .display-text .md-font-bangers {
    font-family: Bangers, sans-serif; }
  .display-text .md-font-biorhymebold {
    font-family: BioRhymeBold, sans-serif; }
  .display-text .md-font-bitterbold {
    font-family: BitterBold, sans-serif; }
  .display-text .md-font-blackhead {
    font-family: Blackhead, sans-serif; }
  .display-text .md-font-blackopsone {
    font-family: BlackOpsOne, sans-serif; }
  .display-text .md-font-bowlbyone {
    font-family: BowlbyOne, sans-serif; }
  .display-text .md-font-cartonslab {
    font-family: CartonSlab, sans-serif; }
  .display-text .md-font-changaone {
    font-family: ChangaOne, sans-serif; }
  .display-text .md-font-creepster {
    font-family: Creepster, sans-serif; }
  .display-text .md-font-deliusunicasebold {
    font-family: DeliusUnicaseBold, sans-serif; }
  .display-text .md-font-douglasaaronadescript {
    font-family: DouglasAaronadeScript, sans-serif; }
  .display-text .md-font-douglaswolves {
    font-family: DouglasWolves, sans-serif; }
  .display-text .md-font-douglascalguryblock {
    font-family: DouglasCalguryBlock, sans-serif; }
  .display-text .md-font-edmondsansbold {
    font-family: EdmondsansBold, sans-serif; }
  .display-text .md-font-firasansblack {
    font-family: FiraSansBlack, sans-serif; }
  .display-text .md-font-firasansbold {
    font-family: FiraSansBold, sans-serif; }
  .display-text .md-font-firasanscondensedblack {
    font-family: FiraSansCondensedBlack, sans-serif; }
  .display-text .md-font-firasanscondensedbold {
    font-family: FiraSansCondensedBold, sans-serif; }
  .display-text .md-font-firasansextrabold {
    font-family: FiraSansExtraBold, sans-serif; }
  .display-text .md-font-firasansextracondensedblack {
    font-family: FiraSansExtraCondensedBlack, sans-serif; }
  .display-text .md-font-firasansextracondensedbold {
    font-family: FiraSansExtraCondensedBold, sans-serif; }
  .display-text .md-font-fraichesemibold {
    font-family: FraicheSemiBold, sans-serif; }
  .display-text .md-font-fraichetrapmedium {
    font-family: FraicheTrapmedium, sans-serif; }
  .display-text .md-font-fraichetrapsemibold {
    font-family: FraicheTrapSemiBold, sans-serif; }
  .display-text .md-font-franklingothicdemi {
    font-family: FranklinGothicDemi, sans-serif; }
  .display-text .md-font-franklingothicdemicondensed {
    font-family: FranklinGothicDemiCondensed, sans-serif; }
  .display-text .md-font-franklingothicheavy {
    font-family: FranklinGothicHeavy, sans-serif; }
  .display-text .md-font-fredokaone {
    font-family: FredokaOne, sans-serif; }
  .display-text .md-font-futurastdheavy {
    font-family: FuturaStdHeavy, sans-serif; }
  .display-text .md-font-germaniaone {
    font-family: GermaniaOne, sans-serif; }
  .display-text .md-font-gilsansmtbold {
    font-family: GilSansMTBold, sans-serif; }
  .display-text .md-font-gilsansmtextcondensedbold {
    font-family: GilSansMTExtCondensedBold, sans-serif; }
  .display-text .md-font-gilsansultrabold {
    font-family: GilSansUltraBold, sans-serif; }
  .display-text .md-font-gilsansultraboldcondensed {
    font-family: GilSansUltraBoldCondensed, sans-serif; }
  .display-text .md-font-gineva {
    font-family: Gineva, sans-serif; }
  .display-text .md-font-gothambold {
    font-family: GothamBold, sans-serif; }
  .display-text .md-font-gravitasone {
    font-family: GravitasOne, sans-serif; }
  .display-text .md-font-haettenschweiler {
    font-family: Haettenschweiler, sans-serif; }
  .display-text .md-font-hanaleifill {
    font-family: HanaleiFill, sans-serif; }
  .display-text .md-font-handwrittencrystal {
    font-family: HandwrittenCrystal, sans-serif; }
  .display-text .md-font-haudrey {
    font-family: Haudrey, sans-serif; }
  .display-text .md-font-hitchcut {
    font-family: Hitchcut, sans-serif; }
  .display-text .md-font-hostagescript {
    font-family: HostageScript, sans-serif; }
  .display-text .md-font-impact {
    font-family: Impact, sans-serif; }
  .display-text .md-font-intro {
    font-family: Intro, sans-serif; }
  .display-text .md-font-jellybelly {
    font-family: JellyBelly, sans-serif; }
  .display-text .md-font-kghappysolid {
    font-family: KGHappySolid, sans-serif; }
  .display-text .md-font-kalambold {
    font-family: KalamBold, sans-serif; }
  .display-text .md-font-kelsonsansbgbold {
    font-family: KelsonSansBGBold, sans-serif; }
  .display-text .md-font-klinicslabbold {
    font-family: KlinicSlabBold, sans-serif; }
  .display-text .md-font-knewave {
    font-family: Knewave, sans-serif; }
  .display-text .md-font-kohm {
    font-family: Kohm, sans-serif; }
  .display-text .md-font-latoblack {
    font-family: LatoBlack, sans-serif; }
  .display-text .md-font-latobold {
    font-family: LatoBold, sans-serif; }
  .display-text .md-font-liberator {
    font-family: Liberator, sans-serif; }
  .display-text .md-font-louisbold {
    font-family: LouisBold, sans-serif; }
  .display-text .md-font-luckiestguy {
    font-family: LuckiestGuy, sans-serif; }
  .display-text .md-font-madden {
    font-family: Madden, sans-serif; }
  .display-text .md-font-merriweatherblack {
    font-family: MerriweatherBlack, sans-serif; }
  .display-text .md-font-merriweatherbold {
    font-family: MerriweatherBold, sans-serif; }
  .display-text .md-font-mogra {
    font-family: Mogra, sans-serif; }
  .display-text .md-font-montserratblack {
    font-family: MontserratBlack, sans-serif; }
  .display-text .md-font-montserratbold {
    font-family: MontserratBold, sans-serif; }
  .display-text .md-font-montserratextrabold {
    font-family: MontserratExtraBold, sans-serif; }
  .display-text .md-font-mortendbold {
    font-family: MortEndBold, sans-serif; }
  .display-text .md-font-mortendextrabold {
    font-family: MortEndExtraBold, sans-serif; }
  .display-text .md-font-oleoscript {
    font-family: OleoScript, sans-serif; }
  .display-text .md-font-opensanscondensedbold {
    font-family: OpenSansCondensedBold, sans-serif; }
  .display-text .md-font-orbitron {
    font-family: Orbitron, sans-serif; }
  .display-text .md-font-orbitronblack {
    font-family: OrbitronBlack, sans-serif; }
  .display-text .md-font-oswald {
    font-family: Oswald, sans-serif; }
  .display-text .md-font-ovsyanka {
    font-family: Ovsyanka, sans-serif; }
  .display-text .md-font-ovsyankapress {
    font-family: Ovsyanka, sans-serif; }
  .display-text .md-font-pacifico {
    font-family: Pacifico, sans-serif; }
  .display-text .md-font-passionone {
    font-family: PassionOne, sans-serif; }
  .display-text .md-font-passiononeblack {
    font-family: PassionOneBlack, sans-serif; }
  .display-text .md-font-permanentmarker {
    font-family: PermanentMarker, sans-serif; }
  .display-text .md-font-piedra {
    font-family: Piedra, sans-serif; }
  .display-text .md-font-pinksunset {
    font-family: PinkSunset, sans-serif; }
  .display-text .md-font-pixelrand {
    font-family: PixelRand, sans-serif; }
  .display-text .md-font-poppinsblack {
    font-family: PoppinsBlack, sans-serif; }
  .display-text .md-font-poppinsbold {
    font-family: PoppinsBold, sans-serif; }
  .display-text .md-font-poppinsextrabold {
    font-family: PoppinsExtraBold, sans-serif; }
  .display-text .md-font-proximanovabold {
    font-family: ProximaNovaBold, sans-serif; }
  .display-text .md-font-ralewayblack {
    font-family: RalewayBlack, sans-serif; }
  .display-text .md-font-ralewaybold {
    font-family: RalewayBold, sans-serif; }
  .display-text .md-font-rhinosrocksleft {
    font-family: RhinosRocksLeft, sans-serif; }
  .display-text .md-font-righteous {
    font-family: Righteous, sans-serif; }
  .display-text .md-font-robotoblack {
    font-family: RobotoBlack, sans-serif; }
  .display-text .md-font-robotobold {
    font-family: RobotoBold, sans-serif; }
  .display-text .md-font-robotocondensedbold {
    font-family: RobotoCondensedBold, sans-serif; }
  .display-text .md-font-rockwell {
    font-family: Rockwell, sans-serif; }
  .display-text .md-font-rockwellextrabold {
    font-family: RockwellExtraBold, sans-serif; }
  .display-text .md-font-rosebayslab {
    font-family: RosebaySlab, sans-serif; }
  .display-text .md-font-rosebayslaboblique {
    font-family: RosebaySlabOblique, sans-serif; }
  .display-text .md-font-runboy {
    font-family: Runboy, sans-serif; }
  .display-text .md-font-segoe {
    font-family: SegoeUI, sans-serif; }
  .display-text .md-font-shojumaru {
    font-family: Shojumaru, sans-serif; }
  .display-text .md-font-sigmarone {
    font-family: SigmarOne, sans-serif; }
  .display-text .md-font-signesha {
    font-family: Signesha, sans-serif; }
  .display-text .md-font-sourcesansprobold {
    font-family: SourceSansProBold, sans-serif; }
  .display-text .md-font-sourcesansproblack {
    font-family: SourceSansProBlack, sans-serif; }
  .display-text .md-font-spicyrice {
    font-family: SpicyRice, sans-serif; }
  .display-text .md-font-stolzl {
    font-family: Stolzl, sans-serif; }
  .display-text .md-font-titanone {
    font-family: TitanOne, sans-serif; }
  .display-text .md-font-thirtyninestencil {
    font-family: ThirtyNineStencil, sans-serif; }
  .display-text .md-font-zeichen {
    font-family: Zeichen, sans-serif; }
