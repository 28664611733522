/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
#admin-content-request-page {
  color: #000;
  overflow-y: scroll; }
  #admin-content-request-page .main-header {
    margin-bottom: 1em; }
  #admin-content-request-page .table {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-bottom: 2em;
    margin-top: 2em; }
    #admin-content-request-page .table .table-header {
      background: linear-gradient(135deg, #115A86 0%, #00314F 100%); }
    #admin-content-request-page .table th {
      border-top: 0; }
    #admin-content-request-page .table ul {
      margin-bottom: 0; }
  #admin-content-request-page .data-label {
    width: 200px; }
