/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.edit-video-controls .video-selector {
  margin-bottom: 1rem;
  min-height: 20px;
  position: relative; }

.edit-video-controls .video-change-button {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  padding: 0; }

.edit-video-controls .video-thumbnail {
  width: auto;
  max-width: 220px;
  max-height: 14em;
  border-radius: 4px; }

.edit-video-controls .video-edit-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  .edit-video-controls .video-edit-icon svg {
    background-color: #000;
    border-radius: 100%;
    color: #fff;
    padding: 10px;
    opacity: 0.75; }
