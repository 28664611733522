.loading-icon {
  margin: 1em 0;
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.loading-icon-xs { height: 30px; }
  &.loading-icon-sm { height: 50px; }
  &.loading-icon-md { height: 100px; }
  &.loading-icon-lg { height: 150px; }
  &.loading-icon-em {
    height: 1em;
    margin: auto;

    .loading-img {
      width: 1em;
    }
  }

  .loading-img {
    max-height: 100%;
    max-width: 100%;
    width: 150px;
    display: block;
  }
}
