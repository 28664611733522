/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.file-upload .upload-zone {
  transition: border .125s ease;
  height: 124px;
  border: 2px dashed #999999;
  padding: 2em;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .file-upload .upload-zone.is-active {
    border: 2px dashed #00DBD0; }
    .file-upload .upload-zone.is-active .upload-text {
      color: white; }

.file-upload .upload-text {
  transition: color .125s ease;
  color: #999999;
  margin: 0; }

.file-upload .upload-click-text {
  color: #00DBD0; }
  .file-upload .upload-click-text:hover {
    cursor: pointer; }

.select-image-modal .file-upload .upload-zone {
  height: 160px; }
