/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
#signup-form .btn-submit {
  padding: 0.75rem 3rem; }

#signup-form .remember-me-label {
  margin: 0 0 0 1em;
  cursor: pointer; }

#signup-form .invalid-feedback {
  display: block;
  padding-left: 1em; }
