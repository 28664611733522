.player-controls {
  display: flex;
  align-items: center;

  .control-button {
    padding: 5px 10px;
  }

  .button-play,
  .button-pause {
    font-size: 1.5em;
  }

  .button-back {
    font-size: 1.15em;
    margin-bottom: 1px;
  }
}
