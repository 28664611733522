@import "variables";

/* Styles */
body,
html,
#app,
.app-wrapper {
  height: 100%;

  // Prevent highlighting of things in IE11.
  -ms-user-select: none;
}

html {
  overflow: hidden;
}

body {
  background-color: $body-bg-color;
  color: $body-color;
  font-family: 'Poppins', sans-serif;
  line-height: 1.8;
  position: relative;

  // Disable OS X elastic scrolling
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

img {
  max-width: 100%;
}

a {
  color: $teal;
  &:hover, &:focus {
    text-decoration: underline;
  }
}

.full-height {
  height: 100%;
}

// custom bootstrap columns
.col-xxl-one-fifth {
  @media (min-width: map-get($grid-breakpoints, xxl)) {
    flex: 0 0 20%;
    max-width: 20%;
  }
}

.cursor-pointer {
  cursor: pointer;
}
.cursor-zoom-in {
  cursor: zoom-in;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-decoration-underline {
  text-decoration: underline;
}
