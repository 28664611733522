@import 'variables';

#admin-content-request-page {
  color: $black;
  overflow-y: scroll;

  .main-header {
    margin-bottom: 1em;
  }

  .table {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-bottom: 2em;
    margin-top: 2em;

    .table-header {
      background: linear-gradient(135deg, #115A86 0%, #00314F 100%);
    }

    th {
      border-top: 0;
    }

    ul {
      margin-bottom: 0;
    }
  }

  .data-label {
    width: 200px;
  }
}
