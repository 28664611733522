@import "_variables";

.presto-entity {
  //display: inline-block;
  min-height: 50px;
  opacity: .5;
  position: relative;
  padding: 1rem 0 1rem 1.25rem;
  user-select: none;

  /* Flex Styles */
  flex: 0 1 auto;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;

  &.active {
    opacity: 1;

    .step-arrow {
      .arrow-marker-line {
        stroke: $black;
      }

      .arrow-marker-head {
        fill: $black;
      }
    }
  }

  &.is-previous {
    opacity: 1;

    .badge-pill {
      background-color: $black;
      color: $white;
    }

    .step-arrow {
      .arrow-marker-line {
        stroke: $black;
      }

      .arrow-marker-head {
        fill: $black;
      }
    }

    .entity-name {
      color: $black;
    }
  }

  .badge-pill {
    background-color: $white;
    color: $black;
  }

  .step-arrow {
    display: inline-block;
    flex: 0 1 auto;
    margin-top: 4px;
    margin-right: 1em;
    opacity: .5;
  }

  .entity-name-wrapper {
    display: inline-block;
    flex: 0 1 auto;
    max-width: 160px;
    overflow: hidden;

    .entity-order {
      float: left;
    }
    .entity-name {
      display: inline-block;
      font-size: .9em;
      float: left;
      margin-top: 3px;
      max-width: 120px;
      word-wrap: break-word;
    }
  }

  .entity-order {
    margin-right: .75em;
  }

  .entity-name {
    color: $white;
  }

  .entity-actions-wrapper {
    display: flex;
    align-items: center;
  }

  .entity-actions {
    .btn-link {
      position: relative;
      top: 1px;
      color: $gray-900;
    }
  }

  .entity-reorder-action {
    cursor: grab;
    color: $gray-900;
    margin-left: .5rem;
  }
}
