/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.sign-add-edit-modal {
  flex: 1;
  margin-top: 4rem;
  max-width: 700px;
  color: #9296A2; }
  .sign-add-edit-modal .modal-content {
    background: white; }
  .sign-add-edit-modal .modal-title {
    color: #171F3D;
    margin-bottom: 1rem; }
  .sign-add-edit-modal .control-label {
    color: #9296A2;
    font-size: .75rem;
    font-weight: 600;
    text-transform: uppercase; }
  .sign-add-edit-modal .scheduling-fields {
    line-height: normal; }
  .sign-add-edit-modal textarea {
    border: 2px solid #D8D8D8;
    border-radius: 4px; }
  .sign-add-edit-modal .btn-primary {
    color: white; }
  .sign-add-edit-modal .btn-outline-dark {
    color: #00283D;
    border: 1px solid #D8D8D8; }
    .sign-add-edit-modal .btn-outline-dark:hover {
      background: transparent;
      border-color: #00283D; }
  .sign-add-edit-modal .close {
    color: white;
    position: absolute;
    top: -70px;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 64px;
    font-weight: 300;
    text-shadow: none;
    opacity: 1; }
    .sign-add-edit-modal .close:hover:not(:disabled):not(.disabled):hover {
      color: white; }
