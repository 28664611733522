/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
#admin-content-request-list-page .role-badge {
  text-transform: capitalize; }

#admin-content-request-list-page .smart-table .table {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px; }
  #admin-content-request-list-page .smart-table .table .table-header {
    background: linear-gradient(135deg, #115A86 0%, #00314F 100%); }
  #admin-content-request-list-page .smart-table .table th {
    border-top: 0; }

#admin-content-request-list-page .action-button {
  padding: 0 .35em;
  font-size: 1.75em; }
