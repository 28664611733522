/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
#user-payment-page {
  overflow-y: auto; }
  #user-payment-page .nav {
    position: relative;
    border-bottom: 2px solid #D8D8D8; }
  #user-payment-page .logo {
    max-height: 3rem;
    min-height: 3rem; }
  #user-payment-page .btn-back {
    font-size: 2rem;
    line-height: 1.3rem;
    padding: 0.5rem 0.9rem;
    border-width: 1px; }
  #user-payment-page .signup-alert {
    margin-bottom: 0;
    margin-top: .75rem;
    text-align: center; }
  #user-payment-page .active-page-dot {
    bottom: -7.5px;
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    background: #008BC2;
    transition: all 250ms ease; }
  #user-payment-page .active-page-line {
    position: absolute;
    bottom: -2px;
    left: 0;
    height: 2px;
    background: #008BC2;
    transition: all 250ms ease; }
  #user-payment-page .section1 .active-page-dot {
    left: calc(50vw - 150px - 0.5rem - 15px); }
  #user-payment-page .section1 .active-page-line {
    width: calc(50vw - 150px - 0.5rem - 15px); }
  #user-payment-page .section2 .active-page-dot {
    left: calc(50vw - 7.5px); }
  #user-payment-page .section2 .active-page-line {
    width: calc(50vw - 7.5px); }
  #user-payment-page .section-button {
    background: none;
    white-space: nowrap;
    border-radius: 20px;
    padding: 0.2rem 0;
    font-size: 0.8rem;
    margin: 0 0.5rem;
    text-align: center;
    width: 150px;
    color: #008BC2;
    border: 1px solid #008BC2;
    opacity: 0.3;
    transition: all 250ms ease;
    cursor: pointer; }
  #user-payment-page .section-button.active {
    background: #008BC2;
    color: #fff;
    opacity: 1; }
  #user-payment-page .section-button:hover {
    opacity: 1; }
  #user-payment-page .onboarding-content {
    width: 100%;
    padding: 0 0 1rem; }
  #user-payment-page .onboarding-box {
    padding: 2rem;
    margin: 1rem 0;
    background-color: #fff;
    color: #000;
    border-radius: 10px; }
    #user-payment-page .onboarding-box .form-control {
      margin: 1rem 0;
      color: #000;
      font-weight: 400; }
      #user-payment-page .onboarding-box .form-control::placeholder {
        color: #999999;
        font-weight: 400; }
  #user-payment-page .onboarding-back-button {
    background: none;
    border: none;
    color: #000;
    font-size: 1.2rem;
    padding: 1rem;
    cursor: pointer; }
  #user-payment-page .restore-payment-methods {
    margin-bottom: 1rem; }
  #user-payment-page .restore-payments-button {
    margin-left: .5rem;
    padding: 0.3rem 1.2rem; }
  #user-payment-page .invalid-feedback {
    display: block; }
