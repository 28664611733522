@import "variables";

.rendering-preview {
  background: $focus;
  background: linear-gradient(90deg, rgba(10, 129, 160, 1) 0%, rgba(0, 96, 126, 1) 46%, rgba(0, 45, 66, 1) 100%);
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  .presto-icon {
    background-repeat: no-repeat;
    background-size: contain;

    max-height: 100px;
    @media (min-width: map-get($grid-breakpoints, sm)) {
      max-height: inherit;
    }
  }

  .rendering-text {
    color: #ffffff;
    line-height: normal;
    text-transform: uppercase;

    font-size: 2em;
    @media (min-width: map-get($grid-breakpoints, sm)) {
      font-size: 3em;
    }
  }

  .render-preview-video {
    width: 100%;
  }

  &.rendering-error {
    padding-top: 3rem;
    padding-bottom: 3rem;
    @media (min-width: map-get($grid-breakpoints, sm)) {
      padding-top: 5rem;
      padding-bottom: 5rem;
    }

    .presto-icon {
      filter: grayscale(1);
    }
  }
}
