.btn-with-spiral {
  position: relative;
  padding: 4px;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  > * {
    z-index: 1;
  }

  &::before {
    content: '';
    position: absolute;
    width: 110%;
    padding-top: 110%;
    background: conic-gradient(#00314A 0turn, #00DBD0 0.50turn, #008BC2 0.75turn, #00314A 1turn);
    animation: spin 2s linear infinite;
    z-index: 0;
  }
}

@keyframes spin {
  to{
    transform: rotate(360deg);
  }
}