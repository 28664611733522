@import 'variables';

@mixin override-ltr {
  .public-DraftStyleDefault-ltr {
    text-align: inherit;
  }
}

.edit-text-controls {
  .text-group {
    background-color: $lightest-gray;
    padding-bottom: 15px;
  }

  .ai-tool-text {
    display: block;
    font-size: 11px;
    font-style: italic;
    margin-bottom: 4px;
    line-height: 1;
  }

  .text-control-editor {
    background-color: $white;
    border-radius: $default-radius;
    border: 1px solid $midnight-blue;
    color: $gray-800;
    height: 168px;
    margin: 1rem .5rem;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 15px;

    *::selection {
      background-color: $teal;
      color: $white;
    }

    span {
      font-size: 18px !important;
    }
  }

  .DraftEditor-root {
    height: 100%;
  }

  .type-color-controls {
    display: flex;
  }

  .md-align-left {
    @include override-ltr;
    text-align: left;
  }

  .md-align-center {
    @include override-ltr;
    text-align: center;
  }

  .md-align-right {
    @include override-ltr;
    text-align: right;
  }

  .md-align-justify {
    @include override-ltr;
    text-align: center;
  }

  .react-numeric-input {
    b i {
      // Because the component doesn't believe in classes...
      background: white !important;
    }
  }
}
