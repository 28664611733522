/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.collection-sidebar-list {
  background-color: #fff;
  margin-top: 8px; }
  .collection-sidebar-list .sidebar-list-header {
    padding: 0.5rem 0.5rem 0.5rem 0.3rem; }
  .collection-sidebar-list .sidebar-list-item {
    border-radius: 4px;
    transition: background-color 250ms ease;
    cursor: pointer;
    padding: 0.2rem 1rem; }
    .collection-sidebar-list .sidebar-list-item:hover, .collection-sidebar-list .sidebar-list-item.active {
      background-color: #E9ECEF; }
    .collection-sidebar-list .sidebar-list-item .sidebar-list-item-count {
      opacity: 0.5; }
  .collection-sidebar-list .free-label {
    background: #00DBD0;
    color: #fff;
    font-size: 0.8rem;
    border-radius: 20px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
    text-transform: uppercase;
    transition: opacity 250ms ease;
    margin-left: 0.5rem; }
