@import 'variables';

$credit-button-height: 60px;

.alacarte-table {
  .item-list-tabs {
    flex-wrap: nowrap;
    overflow-x: scroll;
  
    > .nav-item {
      margin-bottom: 0 ;
    }
  }

  .item-list-footer {
    padding: 20px;
    background-color: $gray-300;
    justify-content: center;

    .item-select-button {
      width: 100%;
      display: block;
      text-transform: uppercase;
      color: $secondary;
      font-weight: 300;
      font-size: 0.85em;
      border-radius: 0;
      border: 1px solid $secondary;
      margin-top: 10px;
    }
  }
}

.alacarte-item {
  position: relative;
  height: 100%;
  padding: 75px 30px 50px 30px;

  &.js-gray-100 {
    background-color: $gray-100;
  }

  &.js-gray-200 {
    background-color: $gray-200;
  }

  .item-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 5px 15px;
    color: $white;
    text-transform: uppercase;
    font-size: 0.80em;
    text-align: center;
    font-weight: bold;

    &.js-primary {
      background-color: $primary;
    }

    &.js-secondary {
      background-color: $secondary;
    }
  }

  .item-title {
    text-transform: uppercase;
    color: $midnight-blue;
    font-weight: 700;
    font-size: 1.5em;
  }
  
  .item-price {
    color: $dark-gray;
    font-weight: 500;
    margin: 30px 0;
    line-height: 50px;

    .item-price-text {
      text-transform: uppercase;
    }
  
    .item-currency {
      font-size: 20px;
      font-weight: 300;
    }
  
    .item-price-number {
      font-size: 50px;
      margin: 0 5px;
    }
  }

  .item-description {
    padding-bottom: 30px;

    &-title {
      color: $secondary;
      font-style: italic;
      font-size: 0.90em;
    }
  
    &-content {
      color: $dark-gray;
      font-size: 0.90em;
      min-height: 3em;
    }
  }

  .item-features {
    .item-feature-content {
      padding-left: 10px;
      color: $dark-gray;
      font-size: 0.90em;
    }
  }

  .credit-buttons {
    display: flex;
    justify-content: center;

    .credit-button {
      height: $credit-button-height;
    }
    
    .credit-field {
      margin: 0 10px;
      height: $credit-button-height;
      text-align: center;
      padding: 0;
      width: 90px;
      font-size: 2em;
    }
  }
}