@import 'variables';
@import 'mixins/site-editor-fonts';

$min-font-size: 5;
$max-font-size: 50;

$fonts: (
  arial: (Arial, Helvetica, sans-serif),
  century: (Century Gothic, sans-serif),
  courier: ('Courier New', Courier, monospace),
  georgia: (Georgia, serif),
  impact: (Impact, Charcoal, sans-serif),
  lucida: ('Lucida Sans Unicode', 'Lucida Grande', sans-serif),
  palatino: ('Palatino Linotype', 'Book Antiqua', Palatino, serif),
  tahoma: (Tahoma, Geneva, sans-serif),
  times: ('Times New Roman', Times, serif),
  verdana: (Verdana, Geneva, sans-serif),
);

.display-text {
  color: $white;
  line-height: 1.00;
  overflow: hidden;

  .display-text-pre {
    white-space: pre-wrap;
  }

  .md-align-left {
    display: block;
    text-align: left;
  }

  .md-align-center {
    display: block;
    text-align: center;
  }

  .md-align-right {
    display: block;
    text-align: right;
  }

  .md-align-justify {
    display: block;
    text-align: center;
  }

  .md-underline {
    text-decoration: underline;
  }

  @for $i from $min-font-size through $max-font-size {
    .md-font-size-#{$i} {
      font-size: #{$i}px;
    }
  }

  @each $name, $value in $fonts {
    .md-font-#{$name} {
      font-family: $value;
    }
  }

  @each $name, $value in $site-editor-fonts {
    .md-font-#{$name} {
      font-family: $value;
    }
  }
}
