@import 'variables';

#environment-warning {
  .warning-banner {
    background: linear-gradient(270deg, #ffab00, #ff5700);
    letter-spacing: 1px;
    padding: 0 12px;
    width: 100%;
  }
}
