@import 'variables';

#admin-banner-edit-page {
  color: $black;

  .main-header {
    margin-bottom: 1em;
  }

  .preview-item {
    border-radius: 10px;
    cursor: pointer;
    flex: 0 0 100%;
    height: 192px;
    overflow: hidden;
    position: relative;

    .img {
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
    }
  }
}
