@import 'variables';

$icon-width: 17px;
$input-margin-left: .5em;

.search-form {
  font-size: .875em;

  .search-form-submit {
    display: none;
  }

  .search-form-query {
    background-color: transparent;
    margin-left: $input-margin-left;
    border: none;
    width: calc(100% - #{$icon-width} - #{$input-margin-left});

    &:focus {
      outline: none;
    }
  }

  .search-form-icon {
    font-size: 1.25em;
    width: $icon-width;
  }

  .disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

#content-header {
  .sub-header-search-wrapper {
    $header-icon-width: 25px;

    .search-form-query {
      width: calc(100% - #{$header-icon-width} - #{$input-margin-left});
    }

    .search-form-icon {
      width: $header-icon-width;
    }
  }
}
