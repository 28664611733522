/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.select-feed-modal {
  max-width: 75vw; }
  .select-feed-modal .modal-content {
    background-color: #fff;
    width: 75vw;
    padding: 0; }
  .select-feed-modal .modal-header {
    background-color: #008BC2;
    color: #fff;
    padding: 1em;
    border-bottom: 1px solid #292929; }
  .select-feed-modal .select-feed-main {
    display: flex;
    padding: .75rem 0; }
    .select-feed-modal .select-feed-main > .left-side {
      padding-left: 15px;
      flex: 0 1 275px;
      overflow: auto; }
    .select-feed-modal .select-feed-main > .right-side {
      min-height: 600px;
      flex-grow: 1;
      overflow: auto; }
  .select-feed-modal .feed-type-list-item {
    border-radius: 6px;
    cursor: pointer;
    padding: 0.2rem 1rem;
    transition: background-color 250ms ease; }
    .select-feed-modal .feed-type-list-item.active {
      background-color: #D1D3D4; }
    .select-feed-modal .feed-type-list-item .feed-type-list-item-count {
      opacity: 0.5; }
  .select-feed-modal .feed-item {
    cursor: pointer;
    position: relative;
    border-radius: 5px; }
    .select-feed-modal .feed-item:hover {
      background-color: #008BC2; }
    .select-feed-modal .feed-item.premium-feed-item {
      opacity: .25;
      cursor: not-allowed; }
      .select-feed-modal .feed-item.premium-feed-item:hover {
        background-color: #fff; }
    .select-feed-modal .feed-item .feed-item-thumbnail {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2em;
      background-color: #D1D3D4;
      width: 100%;
      height: 150px;
      border-radius: 5px; }
    .select-feed-modal .feed-item .feed-item-info {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center; }
    .select-feed-modal .feed-item .feed-item-name {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
  .select-feed-modal .forecast-card {
    border: 2px solid black;
    text-align: center;
    padding: 0.375rem 0.125rem;
    line-height: 14px; }
    .select-feed-modal .forecast-card p {
      font-size: 12px;
      margin-bottom: 0; }
    .select-feed-modal .forecast-card p:first-child {
      margin-bottom: 4px; }
    .select-feed-modal .forecast-card img {
      height: 40px;
      margin-bottom: 8px; }
  .select-feed-modal .counter {
    width: 116px;
    height: 68px;
    padding: 6px;
    border: 3px solid black;
    border-radius: 16px; }
    .select-feed-modal .counter .counter-inner-container {
      display: flex;
      height: 100%;
      overflow: hidden;
      border: 3px solid black;
      border-radius: 8px; }
      .select-feed-modal .counter .counter-inner-container div {
        flex-grow: 1;
        padding: 4px 0;
        text-align: center;
        line-height: 1.2;
        border-right: 3px solid black; }
      .select-feed-modal .counter .counter-inner-container div:nth-child(3) {
        line-height: 30px;
        position: relative;
        top: -15px;
        border-right: 0; }
