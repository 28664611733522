@import 'variables';

$header-height: 60px;
$footer-height: 60px;

#main-layout {
  height: 100%;
  overflow-y: hidden;

  /* Flex Styling */
  display: flex;
  flex-direction: column;
}

#main-content {
  display: flex;
  height: calc(100vh - #{$main-nav-bar-height});
}

#main-sidebar {
  flex: 0 0 300px; // don't grow, don't shrink, start at 300px;
}

#main-page {
  width: 100%;

  &.with-emulation-bar {
    height: calc(100vh - #{$main-nav-bar-height} - 95px);

    @media (min-width: map-get($grid-breakpoints, sm)) {
      height: calc(100vh - #{$main-nav-bar-height} - 48px);
    }
  }
}
