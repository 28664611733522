.generate-text-modal {

  .prompt-results-list {
    padding-left: 0;
    list-style: none;
  }
  
  .prompt-results-button {
    padding: 0.1rem 0.5rem;
    text-align: left;
  }
}