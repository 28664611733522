/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
#dashboard-page .sidebar {
  background-color: #f8f9fa;
  border-right: 2px solid #D8D8D8;
  flex: 0 0 320px;
  font-size: 0.9rem;
  overflow-y: scroll;
  width: 322px;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1000; }
  #dashboard-page .sidebar .loading-icon {
    width: auto;
    margin: 0.5rem; }
  #dashboard-page .sidebar .sidebar-list {
    padding: 1.5rem; }

#dashboard-page .sidebar-fixed-container {
  position: fixed;
  z-index: 500;
  top: 82px;
  right: 0;
  bottom: 0;
  left: 0; }
  #dashboard-page .sidebar-fixed-container .sidebar {
    height: 100%;
    background-color: #D8D8D8; }
  #dashboard-page .sidebar-fixed-container .dashboard-sidebar-open {
    background-color: rgba(78, 78, 78, 0.5); }
  #dashboard-page .sidebar-fixed-container.has-message-bar {
    top: calc(82px + 95px); }
    @media (min-width: 576px) {
      #dashboard-page .sidebar-fixed-container.has-message-bar {
        top: calc(82px + 48px); } }

#dashboard-page .dashboard-header {
  /* Flex Styles */
  display: flex;
  align-items: center;
  justify-content: space-between; }

#dashboard-page .dashboard-content {
  max-height: calc(100vh - 82px);
  overflow: scroll;
  padding: 10px; }

#dashboard-page .sidebar-margin {
  margin-left: 322px; }

#dashboard-page .sidebar-focus {
  background-color: rgba(78, 78, 78, 0.5); }

#dashboard-page .choose-folder-header {
  text-align: center; }

#dashboard-page .cancel-search-anchor {
  margin-left: .25em; }
