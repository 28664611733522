/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.entity-timeline-item {
  display: flex; }
  .entity-timeline-item .entity-info {
    width: 300px;
    margin: 0px 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .entity-timeline-item .entity-info .entity-name {
      width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
  .entity-timeline-item .entity-time-control {
    flex-grow: 1;
    margin-right: 50px; }
  .entity-timeline-item.active {
    background-color: #E9ECEF; }
  .entity-timeline-item .entity-order {
    margin-right: 1em; }
  .entity-timeline-item .entity-name-wrapper {
    max-width: 199px;
    overflow: hidden; }
    .entity-timeline-item .entity-name-wrapper .entity-order {
      float: left; }
    .entity-timeline-item .entity-name-wrapper .entity-name {
      float: left;
      max-width: 160px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
  .entity-timeline-item .dropdown-item svg {
    margin-right: 1rem;
    text-align: center;
    width: 16px; }
  .entity-timeline-item .entity-actions-wrapper {
    display: flex;
    align-items: center; }
  .entity-timeline-item .entity-actions .btn-link {
    position: relative;
    top: 1px;
    color: #000; }
