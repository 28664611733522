/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.edit-text-controls .text-group {
  background-color: #E9ECEF;
  padding-bottom: 15px; }

.edit-text-controls .ai-tool-text {
  display: block;
  font-size: 11px;
  font-style: italic;
  margin-bottom: 4px;
  line-height: 1; }

.edit-text-controls .text-control-editor {
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #00314A;
  color: #333333;
  height: 168px;
  margin: 1rem .5rem;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 15px; }
  .edit-text-controls .text-control-editor *::selection {
    background-color: #50D2C2;
    color: #fff; }
  .edit-text-controls .text-control-editor span {
    font-size: 18px !important; }

.edit-text-controls .DraftEditor-root {
  height: 100%; }

.edit-text-controls .type-color-controls {
  display: flex; }

.edit-text-controls .md-align-left {
  text-align: left; }
  .edit-text-controls .md-align-left .public-DraftStyleDefault-ltr {
    text-align: inherit; }

.edit-text-controls .md-align-center {
  text-align: center; }
  .edit-text-controls .md-align-center .public-DraftStyleDefault-ltr {
    text-align: inherit; }

.edit-text-controls .md-align-right {
  text-align: right; }
  .edit-text-controls .md-align-right .public-DraftStyleDefault-ltr {
    text-align: inherit; }

.edit-text-controls .md-align-justify {
  text-align: center; }
  .edit-text-controls .md-align-justify .public-DraftStyleDefault-ltr {
    text-align: inherit; }

.edit-text-controls .react-numeric-input b i {
  background: white !important; }
