@import 'variables';

#signup-form {
  .btn-submit {
    padding: 0.75rem 3rem;
  }

  .remember-me-label {
    margin: 0 0 0 1em;
    cursor: pointer;
  }

  .invalid-feedback {
    display: block;
    padding-left: 1em;
  }

}
