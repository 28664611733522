@import 'variables';

.header-sidebar-list {
    background-color: $body-bg-color;
}

.sidebar-list-item {
    border-radius: $default-radius;
    transition: background-color 250ms ease;
    cursor: pointer;
    padding: 0.2rem 1rem;
    color: $black;

    &-append {
      color: $primary;
    }

    &:hover, &.active {
      background-color: $lightest-gray;
      text-decoration: none;
      color: $black;
    }

    .sidebar-list-item-count {
      opacity: 0.5;
    }

    &.disabled-link {
      cursor: not-allowed;
      color: $light-gray;

      &:hover {
        background-color: $body-bg-color;
      }
    }

    .badge-pill {
      padding: 3px 10px;
      margin-top: 0px;
      background-color: $primary;
      color: $white;
    }
}

.maintenance-message {
  background-color: orange;
  padding: 5px;
  font-weight: bold;
  text-align: center;
}