/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.transition-library-modal {
  max-width: 75vw; }
  .transition-library-modal .modal-header {
    padding: 1em;
    border-bottom: 1px solid #292929; }
    .transition-library-modal .modal-header .close {
      color: #000; }
  .transition-library-modal .modal-content {
    background-color: #fff;
    width: 75vw;
    scroll-behavior: auto; }
  .transition-library-modal .modal-body-content-wrapper {
    height: 70vh;
    width: 100%;
    overflow-y: scroll; }
  .transition-library-modal .flow-direction-button {
    text-transform: capitalize;
    padding: 0px 25px;
    font-size: 14px; }
