/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.edit-screenshot-time-controls .current-time {
  font-size: 1.05em;
  margin-bottom: 1em; }

.edit-screenshot-time-controls .current-time-value {
  font-weight: bold; }

.edit-screenshot-time-controls .set-buttons-header {
  text-align: center; }

.edit-screenshot-time-controls .set-button {
  color: #fff; }
