/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.scrollbars {
  height: 100%;
  position: relative; }
  .scrollbars .scrollbar-horizontal,
  .scrollbars .scrollbar-vertical {
    position: absolute; }
    .scrollbars .scrollbar-horizontal .single-slider-track,
    .scrollbars .scrollbar-vertical .single-slider-track {
      border-radius: 6px;
      opacity: 0;
      transition: background-color .2s ease-out, opacity .2s ease-out; }
    .scrollbars .scrollbar-horizontal .single-slider-handle,
    .scrollbars .scrollbar-vertical .single-slider-handle {
      background-color: #D8D8D8;
      border-radius: 6px;
      opacity: 0;
      transition: background-color .2s ease-out, opacity .2s ease-out, width .2s ease-in-out; }
  .scrollbars:active .single-slider-track, .scrollbars:focus .single-slider-track, .scrollbars:hover .single-slider-track {
    opacity: .4; }
  .scrollbars:active .single-slider-handle, .scrollbars:focus .single-slider-handle, .scrollbars:hover .single-slider-handle {
    opacity: .8; }
  .scrollbars .scrollbar-horizontal {
    bottom: 0;
    left: 0;
    z-index: 1000; }
    .scrollbars .scrollbar-horizontal .single-slider-track {
      height: 14px; }
    .scrollbars .scrollbar-horizontal .single-slider-handle {
      top: 9px;
      height: 14px; }
  .scrollbars .scrollbar-vertical {
    top: 0;
    right: 0;
    z-index: 1001; }
    .scrollbars .scrollbar-vertical .single-slider-track {
      width: 14px; }
    .scrollbars .scrollbar-vertical .single-slider-handle {
      left: 9px;
      width: 14px; }
