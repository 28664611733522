.content-preview {
  position: relative;
  border-radius: 8px 8px 0 0;
  overflow: hidden;

  .modal-image {
    background-size: cover;
    background-position: center;
  }
  
  img.modal-preview {
    object-fit: contain;
  }

  .content-status {
    font-size: 25pt;
    text-align: center;
    text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
    width: 100%;
    position: absolute;
    bottom: .25em;
    left: 0;
  }

  .render-progress {
    width: 100%;
    opacity: .75;
    position: absolute;
    bottom: 0;
    left: 0;

    .render-progress-bar {
      border-radius: 0;
      height: .85rem;
    }

    .progress-bar {
      font-size: .9rem;
    }
  }
}
