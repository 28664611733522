/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.rendering-preview {
  background: #0099E0;
  background: linear-gradient(90deg, #0a81a0 0%, #00607e 46%, #002d42 100%);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }
  .rendering-preview .presto-icon {
    background-repeat: no-repeat;
    background-size: contain;
    max-height: 100px; }
    @media (min-width: 576px) {
      .rendering-preview .presto-icon {
        max-height: inherit; } }
  .rendering-preview .rendering-text {
    color: #ffffff;
    line-height: normal;
    text-transform: uppercase;
    font-size: 2em; }
    @media (min-width: 576px) {
      .rendering-preview .rendering-text {
        font-size: 3em; } }
  .rendering-preview .render-preview-video {
    width: 100%; }
  .rendering-preview.rendering-error {
    padding-top: 3rem;
    padding-bottom: 3rem; }
    @media (min-width: 576px) {
      .rendering-preview.rendering-error {
        padding-top: 5rem;
        padding-bottom: 5rem; } }
    .rendering-preview.rendering-error .presto-icon {
      filter: grayscale(1); }
