/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.select-media-modal {
  max-width: 75vw; }
  .select-media-modal .modal-content {
    background-color: #fff;
    width: 75vw;
    padding: 0; }
  .select-media-modal .modal-header {
    background-color: #008BC2;
    color: #fff;
    padding: 1em;
    border-bottom: 1px solid #292929; }
  .select-media-modal .modal-body-content-wrapper {
    height: 82vh;
    width: 100%; }
  .select-media-modal .select-file-main {
    display: flex;
    justify-content: space-between;
    padding: .75rem 0;
    height: 100%;
    width: 100%; }
    .select-media-modal .select-file-main > .left-side {
      flex: 0 1 275px;
      margin-left: 1em;
      overflow: auto; }
    .select-media-modal .select-file-main > .right-side {
      margin-left: .5em;
      margin-right: 1em;
      overflow: hidden;
      width: 100%;
      overflow: auto; }
  .select-media-modal .files-list {
    overflow-y: auto;
    height: calc(100% - 38px - 30px); }
  .select-media-modal .files-list-flex {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start; }
  .select-media-modal .file-upload {
    width: 224px;
    margin: .5rem;
    display: flex;
    align-items: center; }
