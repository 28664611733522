@import 'variables';

$bottom-padding: 200px;
$padding: 15px;

#display-sidebar {
  background-color: $blue-600;
  height: 100%;
  overflow-y: auto;
  position: relative;

  .expand-arrow {
    position: absolute;
    right: $padding;
  }
  .minimize-arrow {
    position: absolute;
    right: $padding;
  }

  .minimized-content {
    background-color: $body-bg-color;
    padding: 10px 19px 0;
  }

  .display-sidebar-content {
    background-color: $body-bg-color;
    min-width: 290px;
    max-width: 290px;
    width: 290px;
  }
}

// In IE and Edge, change the padding to account for scrollbar width.
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  $ie-right-size: 20px;

  #display-sidebar {
    .display-sidebar-content {
      padding: 10px $ie-right-size 0 $padding;
    }

    .group-header {
      margin-right: -$ie-right-size;
    }
    .minimize-arrow {
      right: $ie-right-size;
    }
  }
}
