@import 'variables';

.site-text-input {
  border-radius: $default-radius;
  border: 1px solid #505050;
  margin-bottom: 1em;
  width: 100%;

  input[type=text] {
    background-color: transparent;
    padding: .25em .5em;
    width: 100%;
    color: $gray-600;
    border: none;

    &:focus {
      outline: none;
      box-shadow: none;
    }

    &:-ms-input-placeholder {
      color: $gray-400;
    }
  }
}
