/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
#admin-banner-edit-page {
  color: #000; }
  #admin-banner-edit-page .main-header {
    margin-bottom: 1em; }
  #admin-banner-edit-page .preview-item {
    border-radius: 10px;
    cursor: pointer;
    flex: 0 0 100%;
    height: 192px;
    overflow: hidden;
    position: relative; }
    #admin-banner-edit-page .preview-item .img {
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%; }
