@import 'variables';

.pricing-tabs {
  flex-wrap: nowrap;
  overflow-x: hidden;
  padding-top: 20px;
  justify-content: center;
  border: none;
  line-height: 1;

  > .nav-item {
    margin-bottom: 0;
    border-radius: 0;
  }

  .nav-link {
    text-align: center;
    text-transform: uppercase;
    width: 200px;
    background-color: $light-gray;
    color: $white;
    overflow-x: hidden;
  
    .subtitle {
      font-size: 0.7em;
    }

    &.active {
      background-color: $white;
      border-radius: 0;
    }
  }
  
}