/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.nav {
  position: relative;
  border-bottom: 2px solid #D8D8D8; }

.logo {
  max-height: 3rem;
  min-height: 3rem; }

.active-page-dot {
  bottom: -7.5px;
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background: #008BC2;
  transition: all 250ms ease; }

.active-page-line {
  position: absolute;
  bottom: -2px;
  left: 0;
  height: 2px;
  background: #008BC2;
  transition: all 250ms ease; }

.section-button {
  background: none;
  white-space: nowrap;
  border-radius: 20px;
  padding: 0.2rem 0;
  font-size: 0.8rem;
  margin: 0 0.5rem;
  text-align: center;
  width: 150px;
  color: #008BC2;
  border: 1px solid #008BC2;
  opacity: 0.3;
  transition: all 250ms ease;
  cursor: pointer; }

.section-button.active {
  background: #008BC2;
  color: #000;
  opacity: 1; }

.section-button:hover {
  opacity: 1; }

.section1 .active-page-dot {
  left: calc(50vw - 150px - 0.5rem - 15px); }

.section1 .active-page-line {
  width: calc(50vw - 150px - 0.5rem - 15px); }

.section2 .active-page-dot {
  left: calc(50vw - 7.5px); }

.section2 .active-page-line {
  width: calc(50vw - 7.5px); }

.section3 .active-page-dot {
  left: calc(50vw + 150px + 0.5rem); }

.section3 .active-page-line {
  width: calc(50vw + 150px + 0.5rem); }

.section4 .active-page-dot {
  left: calc(50vw + calc(2 * 150px + 0.5rem)); }

.section4 .active-page-line {
  width: calc(50vw + calc(2 * 150px + 0.5rem)); }

.onboarding-back-button {
  background: none;
  border: none;
  color: #000;
  font-size: 1.2rem;
  padding: 1rem;
  cursor: pointer; }
