@import 'variables';

$modal-width: 75vw;
$upper-height: 75px;

$header-height: 38px;

.select-feed-modal {
  max-width: $modal-width;

  .modal-content {
    background-color: $body-bg-color;
    width: $modal-width;
    padding: 0;
  }

  .modal-header {
    background-color: $secondary;
    color: $white;
    padding: 1em;
    border-bottom: 1px solid $gray-900;
  }

  .select-feed-main {
    display: flex;
    padding: .75rem 0;

    & > .left-side {
      padding-left: 15px;
      flex: 0 1 275px;
      overflow: auto;
    }
    & > .right-side {
      min-height: 600px;
      flex-grow: 1;
      overflow: auto;
    }
  }

  .feed-type-list-item {
    border-radius: 6px;
    cursor: pointer;
    padding: 0.2rem 1rem;
    transition: background-color 250ms ease;

    &.active {
      background-color: $light-gray;
    }

    .feed-type-list-item-count {
      opacity: 0.5;
    }
  }

  .feed-item {
    cursor: pointer;
    position: relative;
    border-radius: 5px;

    &:hover {
      background-color: $secondary;
    }

    &.premium-feed-item {
      opacity: .25;
      cursor: not-allowed;

      &:hover {
        background-color: $white;
      }
    }

    .feed-item-thumbnail {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2em;
      background-color: $light-gray;
      width: 100%;
      height: 150px;
      border-radius: 5px;
    }

    .feed-item-info {
      position: relative;
  
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .feed-item-name {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .forecast-card {
    border: 2px solid black;
    text-align: center;
    padding: 0.375rem 0.125rem;
    line-height: 14px;

    p {
      font-size: 12px;
      margin-bottom: 0;
    }
    p:first-child {
      margin-bottom: 4px;
    }

    img {
      height: 40px;
      margin-bottom: 8px;
    }
  }

  .counter {
    width: 116px;
    height: 68px;
    padding: 6px;
    border: 3px solid black;
    border-radius: 16px;

    .counter-inner-container {
      display: flex;
      height: 100%;
      overflow: hidden;
      border: 3px solid black;
      border-radius: 8px;

      div {
        flex-grow: 1;
        padding: 4px 0;
        text-align: center;
        line-height: 1.2;
        border-right: 3px solid black;
      }

      div:nth-child(3) {
        line-height: 30px;
        position: relative;
        top: -15px;
        border-right: 0;
      }
    }
  }
}