/**
 * Stroke font-character.
 * @param {Integer} $stroke - Stroke width
 * @param {Color} $color - Stroke color
 * @return {List} - text-shadow list
 */
/**
 * Stroke font-character.
 * @param {Integer} $stroke - Stroke width
 * @param {Color} $color - Stroke color
 */
#display-multi-active {
  background-color: transparent; }
  #display-multi-active .rotation-label {
    text-shadow: -2px -2px 0 white, -2px -1px 0 white, -2px 0px 0 white, -2px 1px 0 white, -2px 2px 0 white, -1px -2px 0 white, -1px -1px 0 white, -1px 0px 0 white, -1px 1px 0 white, -1px 2px 0 white, 0px -2px 0 white, 0px -1px 0 white, 0px 0px 0 white, 0px 1px 0 white, 0px 2px 0 white, 1px -2px 0 white, 1px -1px 0 white, 1px 0px 0 white, 1px 1px 0 white, 1px 2px 0 white, 2px -2px 0 white, 2px -1px 0 white, 2px 0px 0 white, 2px 1px 0 white, 2px 2px 0 white;
    display: none;
    fill: black;
    font-size: 20px;
    font-weight: bold; }
