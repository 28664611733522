/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.navbar-left-logo {
  width: 175px;
  padding: 0 0.5rem;
  overflow: hidden; }
  .navbar-left-logo img {
    max-width: 100%; }
  @media (min-width: 576px) {
    .navbar-left-logo {
      width: 296px; } }
  @media (min-width: 992px) {
    .navbar-left-logo {
      width: 52px; }
      .navbar-left-logo img {
        max-width: calc(296px - 1rem); } }
  @media (min-width: 1200px) {
    .navbar-left-logo {
      width: 296px; } }
