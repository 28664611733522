@import 'variables';

.content-request-modal {
  flex: 1;
  margin-top: 4rem;
  max-width: 700px;
  color: #9296A2;

  .modal-content {
    background: white;
  }
  .modal-title {
    color: #171F3D;
    margin-bottom: 1rem;
  }

  .control-label {
    color: #9296A2;
    font-size: .75rem;
    font-weight: 600;
    text-transform: uppercase;
  }

  .scheduling-fields {
    line-height: normal;
  }

  textarea {
    border: 2px solid $gray-300;
    border-radius: 4px;
  }

  .invalid-feedback {
    display: block;
  }

  .pending-files {
    list-style-type: none;
    padding-left: 0;
  }

  .pending-file-remove {
    margin-right: .5em;

    svg {
      color: red;
      font-weight: bold;
    }
  }

  .btn-primary {
    color: white;
  }

  .btn-outline-dark {
    color: #00283D;
    border: 1px solid $gray-300;
    &:hover {
      background: transparent;
      border-color: #00283D;
    }
  }

  .close {
    color: white;
    position: absolute;
    top: -70px;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 64px;
    font-weight: 300;
    text-shadow: none;
    opacity: 1;
    &:hover:not(:disabled):not(.disabled):hover {
      color: white;
    }
  }
}
