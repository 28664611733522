/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
#presto-bottom-bar {
  background-color: #008BC2;
  box-shadow: 7px 0 1px 0 rgba(0, 0, 0, 0.3);
  height: 91px;
  /* Flex Styles */
  display: flex;
  align-items: stretch;
  flex-direction: row;
  justify-content: space-between; }
  #presto-bottom-bar .entity-list {
    height: 100%;
    overflow-y: hidden;
    padding-left: 1em;
    /* Flex Styles */
    display: flex;
    justify-content: flex-start; }
  #presto-bottom-bar .entity-nav {
    margin-right: 1rem; }
  #presto-bottom-bar .bottom-bar-entities {
    width: 100%;
    overflow-x: auto; }
  #presto-bottom-bar .bottom-bar-actions {
    height: 100%;
    padding-right: 1em;
    /* Flex Styles */
    display: flex;
    align-items: center; }
    #presto-bottom-bar .bottom-bar-actions .action-button {
      flex: 0 1 auto; }
  #presto-bottom-bar .dropdown-button {
    background-color: #31bead;
    border-color: #31bead;
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
    padding-left: .75rem;
    padding-right: .75rem; }
  #presto-bottom-bar .dropdown-button-text {
    margin-left: 1em; }
  #presto-bottom-bar .back-button {
    margin-right: 1em; }
  #presto-bottom-bar .action-button.btn-secondary {
    background-color: #fff;
    color: #000; }
  #presto-bottom-bar .action-button.btn-outline-secondary {
    border-color: #fff;
    color: #fff; }
