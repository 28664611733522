.render-progress {
  position: relative;

  .progress-bar {
    background-color: #00ADEF !important;
    color: black;
  }

  &.small {
    .progress-bar {
      font-size: 1.25rem;
      font-weight: bold;
    }
  }

  &.large {
    .progress-bar {
      font-size: 1.6rem;
      font-weight: bold;
    }

    .render-progress-bar {
      height: 2rem;
    }

    .render-progress-text {
      display: inline-block;
    }
  }
}
