/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.transition-list-item {
  position: relative;
  user-select: none;
  padding: .25rem .10rem .25rem .5rem;
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff; }
  .transition-list-item:hover .transition-delete {
    display: block; }
  .transition-list-item:hover:not(.active) .btn-link {
    color: #fff; }
    .transition-list-item:hover:not(.active) .btn-link:hover {
      color: #D8D8D8; }
  .transition-list-item.active .badge {
    border-color: #333333; }
  .transition-list-item .transition-order {
    margin-right: 1em; }
  .transition-list-item .transition-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%; }
  .transition-list-item .transition-delete {
    display: none; }
  .transition-list-item .dropdown-item svg {
    margin-right: 1rem;
    text-align: center;
    width: 16px; }
  .transition-list-item .transition-actions-wrapper {
    display: flex;
    align-items: center; }
  .transition-list-item .transition-actions .btn-link {
    position: relative;
    top: 1px; }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .transition-list-item .transition-actions-wrapper {
    margin-right: 10px; } }
