/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.counter-modal .modal-content {
  background-color: #fff;
  padding: 0; }

.counter-modal .modal-header {
  background-color: #008BC2;
  color: #fff;
  padding: 1em;
  border-bottom: 1px solid #292929; }

.counter-modal .modal-body-content-wrapper {
  padding: 2em; }

.counter-modal .modal-footer {
  padding: 0 2em 2em 2em; }

.counter-modal .setup-info-box {
  padding: 15px;
  margin: 15px;
  background-color: #e9ecef;
  border: 1px solid #D8D8D8;
  border-radius: 8px; }

.counter-modal .time-increment-button {
  height: 50px;
  width: 33%;
  margin: 0 8px;
  background-color: #fff; }
  .counter-modal .time-increment-button.is-selected {
    background-color: #008BC2; }
