.smart-table-search {
  margin-bottom: 7px;

  .form-group {
    text-align: right;
    width: 100%;
  }

  .input-group {
    width: 74%;

    input {
      padding-right: 40px;
    }

    .input-group-btn {
      width: 1%;
    }
  }

  .cancel-search {
    position: absolute;
    right: 112px;
    top: 12px;
    z-index: 100;

    svg {
      background-color: transparent;
      color: black;
      padding-left: 5px;
    }

    &:hover {
      cursor: pointer;
      text-decoration: none;
    }
  }
}
