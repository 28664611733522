@import 'variables';

#simple-layout {
  height: 100%;
  overflow-y: hidden;

  /* Flex Styling */
  display: flex;
  flex-direction: column;
}

.simple-page {
  overflow: auto;

  &.with-emulation-bar {
    height: calc(100vh - 95px);

    @media (min-width: map-get($grid-breakpoints, sm)) {
      height: calc(100vh - 48px);
    }
  }
}