@import 'variables';

$active-page-dot-radius: 7.5px;
$section-button-width: 150px;
$section-button-margin: 0.5rem;

.nav {
  position: relative;
  border-bottom: $navbar-border-height solid $navbar-border-color;
}

.logo {
  max-height: 3rem;
  min-height: 3rem;
}

.active-page-dot {
  bottom: -$active-page-dot-radius;
  position: absolute;
  width: $active-page-dot-radius * 2;
  height: $active-page-dot-radius * 2;
  border-radius: 100%;
  background: $secondary;
  transition: all 250ms ease;
}

.active-page-line {
  position: absolute;
  bottom: -$navbar-border-height;
  left: 0;
  height: $navbar-border-height;
  background: $secondary;
  transition: all 250ms ease;
}

.section-button {
  background: none;
  white-space: nowrap;
  border-radius: 20px;
  padding: 0.2rem 0;
  font-size: 0.8rem;
  margin: 0 $section-button-margin;
  text-align: center;
  width: $section-button-width;
  color: $secondary;
  border: 1px solid $secondary;
  opacity: 0.3;
  transition: all 250ms ease;
  cursor: pointer;
}

.section-button.active {
  background: $secondary;
  color: $body-color;
  opacity: 1;
}

.section-button:hover {
  opacity: 1;
}

.section1 {
  $center-of-section1-button: calc(50vw - #{$section-button-width} - #{$section-button-margin} - #{$active-page-dot-radius * 2});

  .active-page-dot {
    left: $center-of-section1-button;
  }

  .active-page-line {
    width: $center-of-section1-button;
  }
}

.section2 {
  $center-of-section2-button: calc(50vw - #{$active-page-dot-radius});

  .active-page-dot {
    left: $center-of-section2-button;
  }

  .active-page-line {
    width: $center-of-section2-button;
  }
}

.section3 {
  $center-of-section3-button: calc(50vw + #{$section-button-width} + #{$section-button-margin});

  .active-page-dot {
    left: $center-of-section3-button;
  }

  .active-page-line {
    width: $center-of-section3-button;
  }
}

.section4 {
  $center-of-section4-button: calc(50vw + calc(2 * #{$section-button-width} + #{$section-button-margin}));

  .active-page-dot {
    left: $center-of-section4-button;
  }

  .active-page-line {
    width: $center-of-section4-button;
  }
}

.onboarding-back-button {
  background: none;
  border: none;
  color: $body-color;
  font-size: 1.2rem;
  padding: 1rem;
  cursor: pointer;
}