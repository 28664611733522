/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.display-control {
  background-color: #008BC2;
  height: 100%;
  padding: 1rem 0;
  text-align: center;
  /* Flex Styles */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center; }
  .display-control i {
    font-size: 1.5rem; }
  .display-control .control-btn {
    padding: 0;
    color: #fff; }
    .display-control .control-btn.disabled {
      opacity: .2; }
