/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.dropdown-actions {
  height: 100%; }
  .dropdown-actions .dropdown-actions-open {
    color: #fff; }
  .dropdown-actions svg {
    font-size: 1.25em; }
  .dropdown-actions .loading-icon {
    font-size: 1.25em; }
  .dropdown-actions .btn {
    padding: 0;
    /* Flex Styles */
    display: flex;
    align-items: center; }
