#render-page {
  background-color: transparent;
  height: 100%;

  .render-display {
    background-color: transparent;
    height: 100%;

    #display {
      border: 0;
    }
  }
}
