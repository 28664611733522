@import 'variables';

$main-navbar-height: 60px;
$side-navbar-width: 60px;

.maintenance-mode-bar {
  color: white;
  background-color: $secondary;
  position: relative;
  z-index: 1;
}