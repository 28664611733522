/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.content-item {
  border-radius: 10px;
  flex: 0 0 15rem;
  margin: 1rem;
  transition: all 200ms ease;
  cursor: pointer;
  position: relative;
  background-position: 100%;
  background-size: 200%; }
  .content-item:hover {
    margin: 0.5rem;
    flex: 0 0 16rem;
    background-position: 0; }
  .content-item.active {
    border: 4px solid #50D2C2; }
  .content-item .loading-icon {
    position: absolute;
    transition: all 200ms ease; }
  .content-item .multiselect-icon {
    bottom: 50px;
    font-size: 1.5em;
    font-weight: 700;
    left: -8px;
    position: absolute;
    z-index: 10; }
    .content-item .multiselect-icon.hidden {
      visibility: hidden; }
  .content-item:hover .multiselect-icon.hidden {
    visibility: visible; }
  .content-item .content-item-info {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .content-item .content-item-name {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .content-item .free-label {
    background: #00DBD0;
    color: #fff;
    font-size: 0.8rem;
    border-radius: 20px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
    text-transform: uppercase;
    transition: opacity 250ms ease;
    margin-left: 0.5rem; }
  .content-item .img {
    background-color: black;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat; }
  .content-item .container-16-9 {
    position: relative;
    padding-top: 56.25%; }
  .content-item .content-item-thumbnail {
    position: absolute;
    transform: translateY(-50%);
    border-radius: 10px;
    top: 50%;
    left: 0;
    width: 100%;
    height: 100%; }
  .content-item .content-item-draft,
  .content-item .content-item-processing {
    padding: 7px;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center; }
    .content-item .content-item-draft .presto-icon,
    .content-item .content-item-processing .presto-icon {
      background-repeat: no-repeat;
      background-size: contain;
      height: 100px;
      width: 100px; }
      @media (min-width: 992px) {
        .content-item .content-item-draft .presto-icon,
        .content-item .content-item-processing .presto-icon {
          height: 60px;
          width: 60px; } }
    .content-item .content-item-draft .draft-text,
    .content-item .content-item-processing .draft-text {
      color: #ffffff;
      line-height: normal;
      padding-left: 5px;
      text-transform: uppercase;
      max-width: calc(100% - 100px);
      font-size: 2em; }
      @media (min-width: 576px) {
        .content-item .content-item-draft .draft-text,
        .content-item .content-item-processing .draft-text {
          font-size: 1.75em; } }
      @media (min-width: 768px) {
        .content-item .content-item-draft .draft-text,
        .content-item .content-item-processing .draft-text {
          font-size: 1.5em; } }
      @media (min-width: 992px) {
        .content-item .content-item-draft .draft-text,
        .content-item .content-item-processing .draft-text {
          max-width: calc(100% - 60px);
          font-size: 1.5em; } }
  .content-item .content-item-draft {
    background-color: #b7b7b7; }
    .content-item .content-item-draft .presto-icon {
      filter: grayscale(1); }
  .content-item.processing .content-item-name {
    max-width: 85%;
    z-index: 31; }
  .content-item .content-item-processing {
    background: linear-gradient(90deg, #0a81a0 0%, #00607e 46%, #002d42 100%); }
    .content-item .content-item-processing.has-error .presto-icon {
      filter: grayscale(1); }
  .content-item .render-progress {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 60px;
    z-index: 30; }
    .content-item .render-progress .progress {
      background: transparent;
      border-radius: 0;
      height: 100%; }
    .content-item .render-progress .progress-bar {
      background: linear-gradient(90deg, rgba(0, 173, 239, 0) 0%, #00adef 100%) !important;
      color: white;
      padding-right: 10px;
      text-align: right; }
    .content-item .render-progress .percent-label {
      position: absolute;
      right: 10px; }

.selected {
  background-color: rgba(0, 139, 194, 0.5); }
