/**
 * Loads all the fonts needed for the editor.
 */

$site-editor-fonts: (
  alfaslabone: (AlfaSlabOne, sans-serif),
  anton: (Anton, sans-serif),
  archivoblack: (ArchivoBlack, sans-serif),
  arizona: (Arizona, sans-serif),
  arvo: (Arvo, sans-serif),
  avenirnextltprobold: (AvenirNextLTProBold, sans-serif),
  avenirnextltproboldcondensed: (AvenirNextLTProBoldCondensed, sans-serif),
  avenirnextltprodemi: (AvenirNextLTProDemi, sans-serif),
  avenirnextltprodemicondensed: (AvenirNextLTProDemiCondensed, sans-serif),
  baloo: (Baloo, sans-serif),
  bangers: (Bangers, sans-serif),
  biorhymebold: (BioRhymeBold, sans-serif),
  bitterbold: (BitterBold, sans-serif),
  blackhead: (Blackhead, sans-serif),
  blackopsone: (BlackOpsOne, sans-serif),
//  blanchcaps: (BlanchCaps, sans-serif),
  bowlbyone: (BowlbyOne, sans-serif),
  cartonslab: (CartonSlab, sans-serif),
  changaone: (ChangaOne, sans-serif),
  creepster: (Creepster, sans-serif),
  deliusunicasebold: (DeliusUnicaseBold, sans-serif),
  douglasaaronadescript: (DouglasAaronadeScript, sans-serif),
  douglaswolves: (DouglasWolves, sans-serif),
  douglascalguryblock: (DouglasCalguryBlock, sans-serif),
  edmondsansbold: (EdmondsansBold, sans-serif),
  firasansblack: (FiraSansBlack, sans-serif),
  firasansbold: (FiraSansBold, sans-serif),
  firasanscondensedblack: (FiraSansCondensedBlack, sans-serif),
  firasanscondensedbold: (FiraSansCondensedBold, sans-serif),
  firasansextrabold: (FiraSansExtraBold, sans-serif),
  firasansextracondensedblack: (FiraSansExtraCondensedBlack, sans-serif),
  firasansextracondensedbold: (FiraSansExtraCondensedBold, sans-serif),
  fraichesemibold: (FraicheSemiBold, sans-serif),
  fraichetrapmedium: (FraicheTrapmedium, sans-serif),
  fraichetrapsemibold: (FraicheTrapSemiBold, sans-serif),
  franklingothicdemi: (FranklinGothicDemi, sans-serif),
  franklingothicdemicondensed: (FranklinGothicDemiCondensed, sans-serif),
  franklingothicheavy: (FranklinGothicHeavy, sans-serif),
  fredokaone: (FredokaOne, sans-serif),
  futurastdheavy: (FuturaStdHeavy, sans-serif),
  germaniaone: (GermaniaOne, sans-serif),
  gilsansmtbold: (GilSansMTBold, sans-serif),
  gilsansmtextcondensedbold: (GilSansMTExtCondensedBold, sans-serif),
  gilsansultrabold: (GilSansUltraBold, sans-serif),
  gilsansultraboldcondensed: (GilSansUltraBoldCondensed, sans-serif),
  gineva: (Gineva, sans-serif),
  gothambold: (GothamBold, sans-serif),
  gravitasone: (GravitasOne, sans-serif),
  haettenschweiler: (Haettenschweiler, sans-serif),
  hanaleifill: (HanaleiFill, sans-serif),
  handwrittencrystal: (HandwrittenCrystal, sans-serif),
  haudrey: (Haudrey, sans-serif),
  hitchcut: (Hitchcut, sans-serif),
  hostagescript: (HostageScript, sans-serif),
  impact: (Impact, sans-serif),
  intro: (Intro, sans-serif),
  jellybelly: (JellyBelly, sans-serif),
  kghappysolid: (KGHappySolid, sans-serif),
  kalambold: (KalamBold, sans-serif),
  kelsonsansbgbold: (KelsonSansBGBold, sans-serif),
  klinicslabbold: (KlinicSlabBold, sans-serif),
  knewave: (Knewave, sans-serif),
  kohm: (Kohm, sans-serif),
  latoblack: (LatoBlack, sans-serif),
  latobold: (LatoBold, sans-serif),
  liberator: (Liberator, sans-serif),
  louisbold: (LouisBold, sans-serif),
  luckiestguy: (LuckiestGuy, sans-serif),
  madden: (Madden, sans-serif),
  merriweatherblack: (MerriweatherBlack, sans-serif),
  merriweatherbold: (MerriweatherBold, sans-serif),
//  modernno20: (ModernNo20, sans-serif),
  mogra: (Mogra, sans-serif),
  montserratblack: (MontserratBlack, sans-serif),
  montserratbold: (MontserratBold, sans-serif),
  montserratextrabold: (MontserratExtraBold, sans-serif),
  mortendbold: (MortEndBold, sans-serif),
  mortendextrabold: (MortEndExtraBold, sans-serif),
  oleoscript: (OleoScript, sans-serif),
  opensanscondensedbold: (OpenSansCondensedBold, sans-serif),
  orbitron: (Orbitron, sans-serif),
  orbitronblack: (OrbitronBlack, sans-serif),
  oswald: (Oswald, sans-serif),
  ovsyanka: (Ovsyanka, sans-serif),
  ovsyankapress: (Ovsyanka, sans-serif),
  pacifico: (Pacifico, sans-serif),
  passionone: (PassionOne, sans-serif),
  passiononeblack: (PassionOneBlack, sans-serif),
  permanentmarker: (PermanentMarker, sans-serif),
  piedra: (Piedra, sans-serif),
  pinksunset:(PinkSunset, sans-serif),  
  pixelrand: (PixelRand, sans-serif),
  poppinsblack: (PoppinsBlack, sans-serif),
  poppinsbold: (PoppinsBold, sans-serif),
  poppinsextrabold: (PoppinsExtraBold, sans-serif),
  proximanovabold: (ProximaNovaBold, sans-serif),
  ralewayblack: (RalewayBlack, sans-serif),
  ralewaybold: (RalewayBold, sans-serif),
  rhinosrocksleft: (RhinosRocksLeft, sans-serif),
  righteous: (Righteous, sans-serif),
  robotoblack: (RobotoBlack, sans-serif),
  robotobold: (RobotoBold, sans-serif),
  robotocondensedbold: (RobotoCondensedBold, sans-serif),
  rockwell: (Rockwell, sans-serif),
  rockwellextrabold: (RockwellExtraBold, sans-serif),
  rosebayslab: (RosebaySlab, sans-serif),
  rosebayslaboblique: (RosebaySlabOblique, sans-serif),
  runboy: (Runboy, sans-serif),
  segoe: (SegoeUI, sans-serif),
  shojumaru: (Shojumaru, sans-serif),
  sigmarone: (SigmarOne, sans-serif),
  signesha: (Signesha, sans-serif),
  sourcesansprobold: (SourceSansProBold, sans-serif),
  sourcesansproblack: (SourceSansProBlack, sans-serif),
  spicyrice: (SpicyRice, sans-serif),
  stolzl: (Stolzl, sans-serif),
  titanone: (TitanOne, sans-serif),
  thirtyninestencil: (ThirtyNineStencil, sans-serif),
  zeichen: (Zeichen, sans-serif),
);

@mixin site-editor-font-faces {

  /* AlfaSlabOne */
  @font-face {
    font-family: 'AlfaSlabOne';
    src: url(#{$font-url-base}/AlfaSlabOne-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* Anton */
  @font-face {
    font-family: 'Anton';
    src: url(#{$font-url-base}/Anton-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* ArchivoBlack */
  @font-face {
    font-family: 'ArchivoBlack';
    src: url(#{$font-url-base}/ArchivoBlack-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* Arizona */
  @font-face {
    font-family: 'Arizona';
    src: url(#{$font-url-base}/Arizona-Bold.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  /* Arvo */
  @font-face {
    font-family: 'Arvo';
    src: url(#{$font-url-base}/Arvo-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Arvo';
    src: url(#{$font-url-base}/Arvo-Bold.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: 'Arvo';
    src: url(#{$font-url-base}/Arvo-BoldItalic.ttf) format('truetype');
    font-weight: bold;
    font-style: italic;
  }

  /* AvenirNextLTProBold */
  @font-face {
    font-family: 'AvenirNextLTProBold';
    src: url(#{$font-url-base}/AvenirNextLTProBold-Regular.otf) format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'AvenirNextLTProBold';
    src: url(#{$font-url-base}/AvenirNextLTProBold-Bold.otf) format('opentype');
    font-weight: bold;
    font-style: normal;
  }

  /* AvenirNextLTProBoldCondensed */
  @font-face {
    font-family: 'AvenirNextLTProBoldCondensed';
    src: url(#{$font-url-base}/AvenirNextLTProBoldCondensed-Regular.otf) format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  /* AvenirNextLTProDemi */
  @font-face {
    font-family: 'AvenirNextLTProDemi';
    src: url(#{$font-url-base}/AvenirNextLTProDemi-Regular.otf) format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  /* AvenirNextLTProDemiCondensed */
  @font-face {
    font-family: 'AvenirNextLTProDemiCondensed';
    src: url(#{$font-url-base}/AvenirNextLTProDemiCondensed-Regular.otf) format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  /* Baloo */
  @font-face {
    font-family: 'Baloo';
    src: url(#{$font-url-base}/Baloo-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* Bangers */
  @font-face {
    font-family: 'Bangers';
    src: url(#{$font-url-base}/Bangers-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* BioRhymeBold */
  @font-face {
    font-family: 'BioRhymeBold';
    src: url(#{$font-url-base}/BioRhymeBold-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'BioRhymeBold';
    src: url(#{$font-url-base}/BioRhymeBold-Bold.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
  }

  /* BitterBold */
  @font-face {
    font-family: 'BitterBold';
    src: url(#{$font-url-base}/BitterBold-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* Blackhead */
  @font-face {
    font-family: 'Blackhead';
    src: url(#{$font-url-base}/Blackhead-Regular.otf) format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Blackhead';
    src: url(#{$font-url-base}/Blackhead-Bold.otf) format('opentype');
    font-weight: bold;
    font-style: normal;
  }

  /* BlackOpsOne */
  @font-face {
    font-family: 'BlackOpsOne';
    src: url(#{$font-url-base}/BlackOpsOne-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* BowlbyOne */
  @font-face {
    font-family: 'BowlbyOne';
    src: url(#{$font-url-base}/BowlbyOne-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* CartonSlab */
  @font-face {
    font-family: 'CartonSlab';
    src: url(#{$font-url-base}/CartonSlab-Regular.otf) format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  /* ChangaOne */
  @font-face {
    font-family: 'ChangaOne';
    src: url(#{$font-url-base}/ChangaOne-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'ChangaOne';
    src: url(#{$font-url-base}/ChangaOne-Italic.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
  }

  /* Creepster */
  @font-face {
    font-family: 'Creepster';
    src: url(#{$font-url-base}/Creepster-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* DeliusUnicaseBold */
  @font-face {
    font-family: 'DeliusUnicaseBold';
    src: url(#{$font-url-base}/DeliusUnicaseBold-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* DouglasAaronadeScript */
  @font-face {
    font-family: 'DouglasAaronadeScript';
    src: url(#{$font-url-base}/DouglasAaronadeScript-Regular.otf) format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  /* DouglasCalguryBlock */
  @font-face {
    font-family: 'DouglasCalguryBlock';
    src: url(#{$font-url-base}/DouglasCalguryBlock-Regular.otf) format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  /* DouglasWolves*/
  @font-face {
    font-family: 'DouglasWolves';
    src: url(#{$font-url-base}/DouglasWolves-Regular.otf) format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  /* EdmondsansBold */
  @font-face {
    font-family: 'EdmondsansBold';
    src: url(#{$font-url-base}/EdmondsansBold-Regular.otf) format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  /* FiraSansBlack */
  @font-face {
    font-family: 'FiraSansBlack';
    src: url(#{$font-url-base}/FiraSansBlack-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'FiraSansBlack';
    src: url(#{$font-url-base}/FiraSansBlack-Italic.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
  }

  /* FiraSansBold */
  @font-face {
    font-family: 'FiraSansBold';
    src: url(#{$font-url-base}/FiraSansBold-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'FiraSansBold';
    src: url(#{$font-url-base}/FiraSansBold-Italic.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
  }

  /* FiraSansCondensedBlack */
  @font-face {
    font-family: 'FiraSansCondensedBlack';
    src: url(#{$font-url-base}/FiraSansCondensedBlack-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'FiraSansCondensedBlack';
    src: url(#{$font-url-base}/FiraSansCondensedBlack-Italic.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
  }

  /* FiraSansCondensedBold */
  @font-face {
    font-family: 'FiraSansCondensedBold';
    src: url(#{$font-url-base}/FiraSansCondensedBold-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'FiraSansCondensedBold';
    src: url(#{$font-url-base}/FiraSansCondensedBold-Bold.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: 'FiraSansCondensedBold';
    src: url(#{$font-url-base}/FiraSansCondensedBold-Italic.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
  }
  @font-face {
    font-family: 'FiraSansCondensedBold';
    src: url(#{$font-url-base}/FiraSansCondensedBold-BoldItalic.ttf) format('truetype');
    font-weight: bold;
    font-style: italic;
  }

  /* FiraSansExtraBold */
  @font-face {
    font-family: 'FiraSansExtraBold';
    src: url(#{$font-url-base}/FiraSansExtraBold-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'FiraSansExtraBold';
    src: url(#{$font-url-base}/FiraSansExtraBold-Italic.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
  }

  /* FiraSansExtraCondensedBlack */
  @font-face {
    font-family: 'FiraSansExtraCondensedBlack';
    src: url(#{$font-url-base}/FiraSansExtraCondensedBlack-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'FiraSansExtraCondensedBlack';
    src: url(#{$font-url-base}/FiraSansExtraCondensedBlack-Italic.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
  }

  /* FiraSansExtraCondensedBold */
  @font-face {
    font-family: 'FiraSansExtraCondensedBold';
    src: url(#{$font-url-base}/FiraSansExtraCondensedBold-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'FiraSansExtraCondensedBold';
    src: url(#{$font-url-base}/FiraSansExtraCondensedBold-Bold.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: 'FiraSansExtraCondensedBold';
    src: url(#{$font-url-base}/FiraSansExtraCondensedBold-Italic.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
  }

  /* FraicheSemiBold */
  @font-face {
    font-family: 'FraicheSemiBold';
    src: url(#{$font-url-base}/FraicheSemiBold-Regular.otf) format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  /* FraicheTrapMedium */
  @font-face {
    font-family: 'FraicheTrapMedium';
    src: url(#{$font-url-base}/FraicheTrapMedium-Regular.otf) format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  /* FraicheTrapSemiBold */
  @font-face {
    font-family: 'FraicheTrapSemiBold';
    src: url(#{$font-url-base}/FraicheTrapSemiBold-Regular.otf) format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  /* FranklinGothicDemi */
  @font-face {
    font-family: 'FranklinGothicDemi';
    src: url(#{$font-url-base}/FranklinGothicDemi-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'FranklinGothicDemi';
    src: url(#{$font-url-base}/FranklinGothicDemi-Italic.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
  }

  /* FranklinGothicDemiCondensed */
  @font-face {
    font-family: 'FranklinGothicDemiCondensed';
    src: url(#{$font-url-base}/FranklinGothicDemiCondensed-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* FranklinGothicHeavy */
  @font-face {
    font-family: 'FranklinGothicHeavy';
    src: url(#{$font-url-base}/FranklinGothicHeavy-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'FranklinGothicHeavy';
    src: url(#{$font-url-base}/FranklinGothicHeavy-Italic.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
  }

  /* FredokaOne */
  @font-face {
    font-family: 'FredokaOne';
    src: url(#{$font-url-base}/FredokaOne-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* FuturaStdHeavy */
  @font-face {
    font-family: 'FuturaStdHeavy';
    src: url(#{$font-url-base}/FuturaStdHeavy-Regular.otf) format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  /* GermaniaOne */
  @font-face {
    font-family: 'GermaniaOne';
    src: url(#{$font-url-base}/GermaniaOne-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* GilSansMTBold */
  @font-face {
    font-family: 'GilSansMTBold';
    src: url(#{$font-url-base}/GilSansMTBold-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'GilSansMTBold';
    src: url(#{$font-url-base}/GilSansMTBold-Italic.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
  }

  /* GilSansMTExtCondensedBold */
  @font-face {
    font-family: 'GilSansMTExtCondensedBold';
    src: url(#{$font-url-base}/GilSansMTExtCondensedBold-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* GilSansUltraBold */
  @font-face {
    font-family: 'GilSansUltraBold';
    src: url(#{$font-url-base}/GilSansUltraBold-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* GilSansUltraBoldCondensed */
  @font-face {
    font-family: 'GilSansUltraBoldCondensed';
    src: url(#{$font-url-base}/GilSansUltraBoldCondensed-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* Gineva */
  @font-face {
    font-family: 'Gineva';
    src: url(#{$font-url-base}/Gineva-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* GothamBold */
  @font-face {
    font-family: 'GothamBold';
    src: url(#{$font-url-base}/GothamBold-Regular.otf) format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  /* GravitasOne */
  @font-face {
    font-family: 'GravitasOne';
    src: url(#{$font-url-base}/GravitasOne-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* Haettenschweiler */
  @font-face {
    font-family: 'Haettenschweiler';
    src: url(#{$font-url-base}/Haettenschweiler-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* HanaleiFill */
  @font-face {
    font-family: 'HanaleiFill';
    src: url(#{$font-url-base}/HanaleiFill-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* HandwrittenCrystal */
  @font-face {
    font-family: 'HandwrittenCrystal';
    src: url(#{$font-url-base}/HandwrittenCrystal-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* Haudrey */
  @font-face {
    font-family: 'Haudrey';
    src: url(#{$font-url-base}/Haudrey-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* Hitchcut */
  @font-face {
    font-family: 'Hitchcut';
    src: url(#{$font-url-base}/Hitchcut-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* HostageScript */
  @font-face {
    font-family: 'HostageScript';
    src: url(#{$font-url-base}/HostageScript.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* Impact */
  @font-face {
    font-family: 'Impact';
    src: url(#{$font-url-base}/Impact-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* Intro */
  @font-face {
    font-family: 'Intro';
    src: url(#{$font-url-base}/Intro-Regular.otf) format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  /* JellyBelly */
  @font-face {
    font-family: 'JellyBelly';
    src: url(#{$font-url-base}/JellyBellyFont-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* KGHappySolid */
  @font-face {
    font-family: 'KGHappySolid';
    src: url(#{$font-url-base}/KGHappySolid-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* KalamBold */
  @font-face {
    font-family: 'KalamBold';
    src: url(#{$font-url-base}/KalamBold-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* KelsonSansBGBold */
  @font-face {
    font-family: 'KelsonSansBGBold';
    src: url(#{$font-url-base}/KelsonSansBGBold-Regular.otf) format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  /* KlinicSlabBold */
  @font-face {
    font-family: 'KlinicSlabBold';
    src: url(#{$font-url-base}/KlinicSlabBold-Regular.otf) format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'KlinicSlabBold';
    src: url(#{$font-url-base}/KlinicSlabBold-Italic.otf) format('opentype');
    font-weight: normal;
    font-style: italic;
  }

  /* Knewave */
  @font-face {
    font-family: 'Knewave';
    src: url(#{$font-url-base}/Knewave-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* Kohm */
  @font-face {
    font-family: 'Kohm';
    src: url(#{$font-url-base}/Kohm-Regular.otf) format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  /* LatoBlack */
  @font-face {
    font-family: 'LatoBlack';
    src: url(#{$font-url-base}/LatoBlack-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* LatoBold */
  @font-face {
    font-family: 'LatoBold';
    src: url(#{$font-url-base}/LatoBold-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* Liberator */
  @font-face {
    font-family: 'Liberator';
    src: url(#{$font-url-base}/Liberator-Regular.otf) format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Liberator';
    src: url(#{$font-url-base}/Liberator-Bold.otf) format('opentype');
    font-weight: bold;
    font-style: normal;
  }

  /* LouisBold */
  @font-face {
    font-family: 'LouisBold';
    src: url(#{$font-url-base}/LouisBold-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* LuckiestGuy */
  @font-face {
    font-family: 'LuckiestGuy';
    src: url(#{$font-url-base}/LuckiestGuy-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* Madden */
  @font-face {
    font-family: 'Madden';
    src: url(#{$font-url-base}/Madden-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* MerriweatherBlack */
  @font-face {
    font-family: 'MerriweatherBlack';
    src: url(#{$font-url-base}/MerriweatherBlack-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'MerriweatherBlack';
    src: url(#{$font-url-base}/MerriweatherBlack-Italic.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
  }

  /* MerriweatherBold */
  @font-face {
    font-family: 'MerriweatherBold';
    src: url(#{$font-url-base}/MerriweatherBold-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'MerriweatherBold';
    src: url(#{$font-url-base}/MerriweatherBold-Italic.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
  }

  /* ModernNo20 */
  @font-face {
    font-family: 'ModernNo20';
    src: url(#{$font-url-base}/ModernNo20-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* Mogra */
  @font-face {
    font-family: 'Mogra';
    src: url(#{$font-url-base}/Mogra-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* MontserratBlack */
  @font-face {
    font-family: 'MontserratBlack';
    src: url(#{$font-url-base}/MontserratBlack-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'MontserratBlack';
    src: url(#{$font-url-base}/MontserratBlack-Italic.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
  }

  /* MontserratBold */
  @font-face {
    font-family: 'MontserratBold';
    src: url(#{$font-url-base}/MontserratBold-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'MontserratBold';
    src: url(#{$font-url-base}/MontserratBold-Italic.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
  }

  /* MontserratExtraBold */
  @font-face {
    font-family: 'MontserratExtraBold';
    src: url(#{$font-url-base}/MontserratExtraBold-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'MontserratExtraBold';
    src: url(#{$font-url-base}/MontserratExtraBold-Italic.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
  }

  /* MortEndBold */
  @font-face {
    font-family: 'MortEndBold';
    src: url(#{$font-url-base}/MortEnd-Bold.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
  }

  /* MortEndExtraBold */
  @font-face {
    font-family: 'MortEndExtraBold';
    src: url(#{$font-url-base}/MortEndExtraBold-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* OleoScript */
  @font-face {
    font-family: 'OleoScript';
    src: url(#{$font-url-base}/OleoScript-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'OleoScript';
    src: url(#{$font-url-base}/OleoScript-Bold.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
  }

  /* OpenSansCondensedBold */
  @font-face {
    font-family: 'OpenSansCondensedBold';
    src: url(#{$font-url-base}/OpenSansCondensedBold-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* Orbitron*/
  @font-face {
    font-family: 'Orbitron';
    src: url(#{$font-url-base}/Orbitron-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Orbitron';
    src: url(#{$font-url-base}/Orbitron-Bold.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
  }

  /* OrbitronBlack*/
  @font-face {
    font-family: 'OrbitronBlack';
    src: url(#{$font-url-base}/OrbitronBlack-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* Oswald */
  @font-face {
    font-family: 'Oswald';
    src: url(#{$font-url-base}/Oswald-Combined.ttf) format('truetype');
  }

  /* Ovsyanka */
  @font-face {
    font-family: 'Ovsyanka';
    src: url(#{$font-url-base}/Ovsyanka-Regular.otf) format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  /* OvsyankaPress */
  @font-face {
    font-family: 'OvsyankaPress';
    src: url(#{$font-url-base}/OvsyankaPress-Regular.otf) format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  /* Pacifico */
  @font-face {
    font-family: 'Pacifico';
    src: url(#{$font-url-base}/Pacifico-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* PassionOne */
  @font-face {
    font-family: 'PassionOne';
    src: url(#{$font-url-base}/PassionOne-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'PassionOne';
    src: url(#{$font-url-base}/PassionOne-Bold.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
  }

  /* PassionOneBlack */
  @font-face {
    font-family: 'PassionOneBlack';
    src: url(#{$font-url-base}/PassionOneBlack-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* PermanentMarker */
  @font-face {
    font-family: 'PermanentMarker';
    src: url(#{$font-url-base}/PermanentMarker-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* Piedra */
  @font-face {
    font-family: 'Piedra';
    src: url(#{$font-url-base}/Piedra-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* PinkSunset */
  @font-face {
    font-family: 'PinkSunset';
    src: url(#{$font-url-base}/PinkSunset-Regular.otf) format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'PinkSunset';
    src: url(#{$font-url-base}/PinkSunset-Italic.otf) format('opentype');
    font-weight: normal;
    font-style: italic;
  }

  /* PixelRand */
  @font-face {
    font-family: 'PixelRand';
    src: url(#{$font-url-base}/PixelRand-Regular.otf) format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  /* PoppinsBlack */
  @font-face {
    font-family: 'PoppinsBlack';
    src: url(#{$font-url-base}/PoppinsBlack-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'PoppinsBlack';
    src: url(#{$font-url-base}/PoppinsBlack-Italic.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
  }

  /* PoppinsBold */
  @font-face {
    font-family: 'PoppinsBold';
    src: url(#{$font-url-base}/PoppinsBold-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'PoppinsBold';
    src: url(#{$font-url-base}/PoppinsBold-Italic.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
  }

  /* PoppinsExtraBold */
  @font-face {
    font-family: 'PoppinsExtraBold';
    src: url(#{$font-url-base}/PoppinsExtraBold-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'PoppinsExtraBold';
    src: url(#{$font-url-base}/PoppinsExtraBold-Italic.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
  }

  /* ProximaNovaBold */
  @font-face {
    font-family: 'ProximaNovaBold';
    src: url(#{$font-url-base}/ProximaNovaBold-Regular.otf) format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'ProximaNovaBold';
    src: url(#{$font-url-base}/ProximaNovaBold-Italic.otf) format('opentype');
    font-weight: normal;
    font-style: italic;
  }

  /* RalewayBlack */
  @font-face {
    font-family: 'RalewayBlack';
    src: url(#{$font-url-base}/RalewayBlack-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'RalewayBlack';
    src: url(#{$font-url-base}/RalewayBlack-Italic.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
  }

  /* RalewayBold */
  @font-face {
    font-family: 'RalewayBold';
    src: url(#{$font-url-base}/RalewayBold-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'RalewayBold';
    src: url(#{$font-url-base}/RalewayBold-Bold.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: 'RalewayBold';
    src: url(#{$font-url-base}/RalewayBold-Italic.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
  }
  @font-face {
    font-family: 'RalewayBold';
    src: url(#{$font-url-base}/RalewayBold-BoldItalic.ttf) format('truetype');
    font-weight: bold;
    font-style: italic;
  }

  /* RhinosRocks */
  @font-face {
    font-family: 'RhinosRocksLeft';
    src: url(#{$font-url-base}/RhinosRocksLeft-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* Righteous */
  @font-face {
    font-family: 'Righteous';
    src: url(#{$font-url-base}/Righteous-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* RobotoBlack */
  @font-face {
    font-family: 'RobotoBlack';
    src: url(#{$font-url-base}/RobotoBlack-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'RobotoBlack';
    src: url(#{$font-url-base}/RobotoBlack-Italic.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
  }

  /* RobotoBold */
  @font-face {
    font-family: 'RobotoBold';
    src: url(#{$font-url-base}/RobotoBold-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'RobotoBold';
    src: url(#{$font-url-base}/RobotoBold-Italic.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
  }

  /* RobotoCondensedBold */
  @font-face {
    font-family: 'RobotoCondensedBold';
    src: url(#{$font-url-base}/RobotoCondensedBold-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'RobotoCondensedBold';
    src: url(#{$font-url-base}/RobotoCondensedBold-Italic.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
  }

  /* Rockwell */
  @font-face {
    font-family: 'Rockwell';
    src: url(#{$font-url-base}/Rockwell-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Rockwell';
    src: url(#{$font-url-base}/Rockwell-Bold.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: 'Rockwell';
    src: url(#{$font-url-base}/Rockwell-Italic.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
  }
  @font-face {
    font-family: 'Rockwell';
    src: url(#{$font-url-base}/Rockwell-BoldItalic.ttf) format('truetype');
    font-weight: bold;
    font-style: italic;
  }

  /* RockwellExtraBold */
  @font-face {
    font-family: 'RockwellExtraBold';
    src: url(#{$font-url-base}/RockwellExtraBold-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* RosebaySlab */
  @font-face {
    font-family: 'RosebaySlab';
    src: url(#{$font-url-base}/RosebaySlab-Regular.otf) format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  /* RosebaySlabOblique */
  @font-face {
    font-family: 'RosebaySlabOblique';
    src: url(#{$font-url-base}/RosebaySlab-Italic.otf) format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  /* Runboy */
  @font-face {
    font-family: 'Runboy';
    src: url(#{$font-url-base}/Runboy-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* SegoeUI */
  @font-face {
    font-family: 'SegoeUI';
    src: url(#{$font-url-base}/SegoeUI.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }  
  @font-face {
    font-family: 'SegoeUI';
    src: url(#{$font-url-base}/SegoeUI-Bold.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
  } 
  @font-face {
    font-family: 'SegoeUI';
    src: url(#{$font-url-base}/SegoeUI-Italic.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
  } 

  /* Shojumaru */
  @font-face {
    font-family: 'Shojumaru';
    src: url(#{$font-url-base}/Shojumaru-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* SigmarOne */
  @font-face {
    font-family: 'SigmarOne';
    src: url(#{$font-url-base}/SigmarOne-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* Signesha */
  @font-face {
    font-family: 'Signesha';
    src: url(#{$font-url-base}/Signesha-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* SourceSansProBold */
  @font-face {
    font-family: 'SourceSansProBold';
    src: url(#{$font-url-base}/SourceSansPro-Bold.otf) format('opentype');
    font-weight: Bold;
    font-style: normal;
  }

  /* SourceSansProBlack */
  @font-face {
    font-family: 'SourceSansProBlack';
    src: url(#{$font-url-base}/SourceSansProBlack-Regular.otf) format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  /* SpicyRice */
  @font-face {
    font-family: 'SpicyRice';
    src: url(#{$font-url-base}/SpicyRice-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* Stolzl */
  @font-face {
    font-family: 'Stolzl';
    src: url(#{$font-url-base}/Stolzl-Bold.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: 'Stolzl';
    src: url(#{$font-url-base}/Stolzl-Book.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Stolzl';
    src: url(#{$font-url-base}/Stolzl-Light.ttf) format('truetype');
    font-weight: light;
    font-style: normal;
  }

  /* TitanOne */
  @font-face {
    font-family: 'TitanOne';
    src: url(#{$font-url-base}/TitanOne-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* ThirtyNineStencil */
  @font-face {
    font-family: 'ThirtyNineStencil';
    src: url(#{$font-url-base}/ThirtyNineStencil-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* Zeichen */
  @font-face {
    font-family: 'Zeichen';
    src: url(#{$font-url-base}/Zeichen-Regular.otf) format('opentype');
    font-weight: normal;
    font-style: normal;
  }
}
