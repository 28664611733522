@import "variables";

#privacy-policy-page {
  height: 100%;
  overflow-y: auto;
  padding-top: 2em;

  .page-title {
    font-weight: 700;
    font-size: 2.25em;
    line-height: 2.25em;
    margin-bottom: 0;
  }

  .statement-wrapper {
    border-bottom: 1px solid gray;
    padding-top: 2em;
    padding-bottom: 2em;
  }

  .statement-label {
    color: $teal;
    font-size: 1.5em;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 1.5em;
    padding-bottom: .75em;
  }

  .last-updated {
    color: gray;
    padding-top: 2em;
    padding-bottom: 2em;
  }
}
