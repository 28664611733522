@import 'variables';

$between-padding: 5px;

.content-item {
  border-radius: 10px;
  flex: 0 0 15rem;
  margin: 1rem;
  transition: all 200ms ease;
  cursor: pointer;
  position: relative;
  background-position: 100%;
  background-size: 200%;

  &:hover {
    margin: 0.5rem;
    flex: 0 0 16rem;
    background-position: 0;
  }

  &.active {
    border: 4px solid $teal;
  }

  .loading-icon {
    position: absolute;
    transition: all 200ms ease;
  }

  .multiselect-icon {
    bottom: 50px;
    font-size: 1.5em;
    font-weight: 700;
    left: -8px;
    position: absolute;
    z-index: 10;
    
    &.hidden {
      visibility: hidden;
    }
  }

  &:hover .multiselect-icon.hidden {
    visibility: visible;
  }

  .content-item-info {
    position: relative;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .content-item-name {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .free-label {
    background: $primary;
    color: $white;
    font-size: 0.8rem;
    border-radius: 20px;
    box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.1);
    text-transform: uppercase;
    transition: opacity 250ms ease;
    margin-left: 0.5rem;
  }

  .img {
    background-color: black;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .container-16-9 {
    position: relative;
    padding-top: 56.25%;
  }
  .content-item-thumbnail {
    position: absolute;
    transform: translateY(-50%);
    border-radius: 10px;
    top: 50%;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .content-item-draft,
  .content-item-processing {
    padding: 7px;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 0.8rem;

    display: flex;
    justify-content: center;
    align-items: center;

    .presto-icon {
      background-repeat: no-repeat;
      background-size: contain;
      height: 100px;
      width: 100px;

      @media (min-width: map-get($grid-breakpoints, lg)) {
        height: 60px;
        width: 60px;
      }
    }

    .draft-text {
      color: #ffffff;
      line-height: normal;
      padding-left: $between-padding;
      text-transform: uppercase;

      max-width: calc(100% - 100px);
      font-size: 2em;
      @media (min-width: map-get($grid-breakpoints, sm)) {
        font-size: 1.75em;
      }

      @media (min-width: map-get($grid-breakpoints, md)) {
        font-size: 1.5em;
      }

      @media (min-width: map-get($grid-breakpoints, lg)) {
        max-width: calc(100% - 60px);
        font-size: 1.5em;
      }
    }
  }

  .content-item-draft {
    background-color: #b7b7b7;

    .presto-icon {
      filter: grayscale(1);
    }
  }

  &.processing {
    .content-item-name {
      max-width: 85%;
      z-index: 31;
    }
  }

  .content-item-processing {
    background: linear-gradient(90deg, rgba(10, 129, 160, 1) 0%, rgba(0, 96, 126, 1) 46%, rgba(0, 45, 66, 1) 100%);

    &.has-error {
      .presto-icon {
        filter: grayscale(1);
      }
    }
  }

  .render-progress {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 60px;
    z-index: 30;

    .progress {
      background: transparent;
      border-radius: 0;
      height: 100%;
    }

    .progress-bar {
      background: linear-gradient(90deg, rgba(0, 173, 239, 0) 0%, rgba(0, 173, 239, 1) 100%) !important;
      color: white;
      padding-right: 10px;
      text-align: right;
    }

    .percent-label {
      position: absolute;
      right: 10px;
    }
  }
}

.selected {
  background-color: rgba(0, 139, 194, .50);
}
