/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
#editor-side {
  height: 100%; }
  #editor-side .editor-side-tabs {
    display: flex;
    height: 0px; }
  #editor-side .tab {
    background-color: #008BC2;
    color: #fff;
    cursor: pointer;
    padding: 9px;
    text-align: center;
    width: 50%; }
    #editor-side .tab.active {
      background-color: #003250;
      cursor: auto; }
    #editor-side .tab:not(.active):hover {
      background-color: #fff;
      color: #003250; }
  #editor-side .editor-side-active {
    height: calc(100% - 0px - 91px); }
  #editor-side .editor-side-actions {
    background-color: #008BC2;
    height: 91px;
    padding: 0 1em;
    /* Flex Styles */
    display: flex;
    align-items: center; }
  #editor-side .save-button-group {
    width: 100%; }
    #editor-side .save-button-group .save-button {
      width: 80%; }
    #editor-side .save-button-group .dropdown-toggle-split {
      width: 20%; }
    #editor-side .save-button-group .dropdown-item {
      color: #000; }
