@import "variables";

.playback-controls {
  background-color: $body-bg-color;
  margin-right: 1px;
  margin-left: 1px;
  padding: .25em 1em .25em .75em;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: 250px;

  .controls-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 5px;
    margin-right: 50px;

    &.video-timeline {
      height: 50px;
    }

    &.timeline {
      max-height: 200px;
      overflow-x: visible;
      overflow-y: scroll;
    }
  }

  .entity-info {
    width: 250px;
    display: flex;
    gap: 5px;
  }

  .entity-add-button {
    align-self: center;
  }

  .time-control {
    flex-grow: 1;
    align-self: center;
  }

  .timer {
    align-self: center;
  }
}

.inline-playback-controls {
  background-color: $body-bg-color;
  height: 53px;
  margin-right: 1px;
  margin-left: 1px;
  padding: .25em 1em .25em .75em;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;

  /* Flex Styles */
  display: flex;
  align-items: center;
  justify-content: space-between;

  .player-controls-wrapper {
    width: 85px;
  }

  .time-control-wrapper {
    width: calc(100% - 150px);
  }

  .time-tracker-wrapper {
    width: 55px;
  }

  .controls-wrapper {
    flex: 0 1 auto;
    min-width: 55px;
    padding: 0;
  }

  .single-slider-track {
    background-color: $sky-blue;
  }

  .timer {
    text-align: right;
  }
}
