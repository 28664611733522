@import "variables";

.unsplash-image-modal {
  @media(min-width: map-get($grid-breakpoints, sm)) { 
    max-width: 100%;
  }
  @media(min-width: map-get($grid-breakpoints, md)) {
    max-width: 800px;
  }
  @media(min-width: map-get($grid-breakpoints, lg)) {
    max-width: 1140px;
  }

  .modal-header .close {
    color: #000;
  }

  .image-row {
    margin-left: -0.375rem;
    margin-right: -0.375rem;

    .col {
      padding-left: 0.375rem;
      padding-right: 0.375rem;
    }
  }

  .image-container {
    position: relative;
    overflow: hidden;

    &:hover {
      .image-data {
        opacity: 1;
      }
    }
  }

  .image-data {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    pointer-events: none;
    transition: opacity ease-in 100ms;
    background-color: rgba(0, 0, 0, 0.25);
  }

  .image-attribution {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;

    &-image {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      overflow: hidden;
    }

    &-text {
      color: white;
      line-height: 18px;
      pointer-events: all;
    }
  }
}