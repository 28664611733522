/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.timeline-slider {
  position: relative;
  background-color: grey;
  width: 100%;
  height: 15px;
  margin: 10px 0px; }
  .timeline-slider .timeline-slider-track {
    background-color: #008BC2;
    height: 15px;
    position: absolute; }
  .timeline-slider .timeline-slider-handle {
    background-color: #fff;
    border: 1px solid #00314A;
    border-radius: 2px;
    cursor: pointer;
    position: absolute;
    left: 0;
    width: 12px;
    height: 15px; }
    .timeline-slider .timeline-slider-handle:hover {
      background-color: #D8D8D8; }
  .timeline-slider .timeline-transition-handle {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    height: 15px; }
    .timeline-slider .timeline-transition-handle:hover .timeline-transition-handle-text {
      background-color: rgba(255, 255, 255, 0.5); }
    .timeline-slider .timeline-transition-handle .timeline-transition-handle-text {
      color: #003250;
      position: absolute;
      top: 0;
      left: 8px;
      font-size: 10px;
      height: 15px;
      line-height: 15px;
      border: 1px solid #003250;
      padding: 0 2px 0 6px;
      background-color: rgba(255, 255, 255, 0.3);
      white-space: nowrap; }
      .timeline-slider .timeline-transition-handle .timeline-transition-handle-text.right-text {
        right: -8px;
        left: auto;
        padding: 0 6px 0 2px; }
    .timeline-slider .timeline-transition-handle .timeline-transition-handle-marker {
      position: absolute;
      bottom: 0;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid #00283D; }
