@import 'mixins/stroke';

#display-active {
  background-color: transparent;
  // mix-blend-mode: difference;

  .rotation-label {
    @include stroke(2, white);

    display: none;
    fill: black;
    font-size: 20px;
    font-weight: bold;
  }
}
