/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
/* Styles */
body,
html,
#app,
.app-wrapper {
  height: 100%;
  -ms-user-select: none; }

html {
  overflow: hidden; }

body {
  background-color: #fff;
  color: #000;
  font-family: 'Poppins', sans-serif;
  line-height: 1.8;
  position: relative;
  overflow: auto;
  -webkit-overflow-scrolling: touch; }

img {
  max-width: 100%; }

a {
  color: #50D2C2; }
  a:hover, a:focus {
    text-decoration: underline; }

.full-height {
  height: 100%; }

@media (min-width: 1440px) {
  .col-xxl-one-fifth {
    flex: 0 0 20%;
    max-width: 20%; } }

.cursor-pointer {
  cursor: pointer; }

.cursor-zoom-in {
  cursor: zoom-in; }

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-decoration-underline {
  text-decoration: underline; }
