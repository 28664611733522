/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
@media (min-width: 576px) {
  .unsplash-image-modal {
    max-width: 100%; } }

@media (min-width: 768px) {
  .unsplash-image-modal {
    max-width: 800px; } }

@media (min-width: 992px) {
  .unsplash-image-modal {
    max-width: 1140px; } }

.unsplash-image-modal .modal-header .close {
  color: #000; }

.unsplash-image-modal .image-row {
  margin-left: -0.375rem;
  margin-right: -0.375rem; }
  .unsplash-image-modal .image-row .col {
    padding-left: 0.375rem;
    padding-right: 0.375rem; }

.unsplash-image-modal .image-container {
  position: relative;
  overflow: hidden; }
  .unsplash-image-modal .image-container:hover .image-data {
    opacity: 1; }

.unsplash-image-modal .image-data {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  transition: opacity ease-in 100ms;
  background-color: rgba(0, 0, 0, 0.25); }

.unsplash-image-modal .image-attribution {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0; }
  .unsplash-image-modal .image-attribution-image {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    overflow: hidden; }
  .unsplash-image-modal .image-attribution-text {
    color: white;
    line-height: 18px;
    pointer-events: all; }
