@import 'variables';

.entity-add-button {
  .dropdown-item {
    svg {
      margin-right: 1rem;
      text-align: center;
      width: 16px;

      &.pull-right {
        float: right;
        margin-right: 0;
        margin-top: 0.3rem;
      }
    }
  }

  .ai-tool-text, .unsplash-tool-text {
    display: block;
    font-size: 11px;
    font-style: italic;
    margin-bottom: 4px;
    line-height: 1;
  }
}
