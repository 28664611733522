@import url(../../../../node_modules/rc-color-picker/assets/index.css);
/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.rc-color-picker {
  z-index: 1005; }

.rc-color-picker-panel {
  background-color: #292929;
  width: 100%; }

.rc-color-picker-panel-inner {
  border: 1px solid #6D6D6D;
  box-shadow: 0 0 0 white;
  padding: 1rem 1rem 2rem; }
