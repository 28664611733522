/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
#main-navbar {
  margin-left: 0;
  position: relative;
  max-width: 100vw;
  min-height: 82px; }
  #main-navbar .navbar {
    z-index: 100;
    position: absolute;
    height: 82px;
    width: 100%;
    background-image: linear-gradient(to right, #fff 2%, #00DBD0 55%, #008BC2);
    display: flex;
    letter-spacing: 0.03em; }
    #main-navbar .navbar .btn-link {
      font-size: 14px; }
    #main-navbar .navbar .navbar-left-logo-container {
      position: absolute;
      left: 0;
      top: 0;
      height: 82px; }
      @media (min-width: 576px) {
        #main-navbar .navbar .navbar-left-logo-container {
          left: 1rem; } }
    #main-navbar .navbar .navbar-nav {
      align-items: center; }
      #main-navbar .navbar .navbar-nav .nav-link {
        color: #fff; }
    @media (max-width: 992px) {
      #main-navbar .navbar .navbar-collapse {
        background-color: #00314A;
        border-radius: 10px; } }
    #main-navbar .navbar .nav-dropdown {
      position: relative; }
      #main-navbar .navbar .nav-dropdown .nav-dropdown-toggle {
        cursor: pointer; }
      #main-navbar .navbar .nav-dropdown .nav-dropdown-item-list {
        display: none;
        list-style-type: none;
        position: absolute;
        top: 100%;
        left: 0;
        width: 200px;
        background-color: #00314A;
        padding: 15px; }
        #main-navbar .navbar .nav-dropdown .nav-dropdown-item-list:hover {
          display: block; }
        #main-navbar .navbar .nav-dropdown .nav-dropdown-item-list .nav-dropdown-item .btn-link {
          color: #fff;
          font-size: 12px;
          font-weight: 500;
          padding: 4px 16px; }
          #main-navbar .navbar .nav-dropdown .nav-dropdown-item-list .nav-dropdown-item .btn-link:hover, #main-navbar .navbar .nav-dropdown .nav-dropdown-item-list .nav-dropdown-item .btn-link:focus {
            color: #00DBD0;
            text-decoration: none; }
      #main-navbar .navbar .nav-dropdown .nav-dropdown-toggle:hover + .nav-dropdown-item-list {
        display: block; }
    #main-navbar .navbar .nav-btn {
      margin-left: 1rem; }
    #main-navbar .navbar #dropdownMenuButton {
      margin-left: 0.5rem; }
    #main-navbar .navbar #download-credits-badge {
      margin-right: 5px;
      padding: 0.5rem 1.25rem; }
    #main-navbar .navbar .badge-item {
      display: flex;
      align-items: center;
      margin-right: 6px; }
    #main-navbar .navbar .badge-label {
      color: #fff;
      margin-left: 0;
      max-width: 80px;
      font-size: .875rem;
      line-height: .85rem; }
    #main-navbar .navbar .navbar-toggle-button {
      padding: 0.25rem 0.75rem; }
