/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.edit-image-controls .can-not-edit {
  margin-top: 1rem;
  text-align: center; }

.edit-image-controls .ai-tool-text {
  display: block;
  font-size: 11px;
  font-style: italic;
  margin-bottom: 4px;
  line-height: 1; }

.edit-image-controls .image-selector {
  margin-bottom: 1rem;
  min-height: 20px;
  position: relative; }

.edit-image-controls .image-change-button {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  padding: 0; }

.edit-image-controls .image-thumbnail {
  width: auto;
  max-height: 14em;
  border-radius: 4px; }

.edit-image-controls .image-edit-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  .edit-image-controls .image-edit-icon svg {
    background-color: #000;
    border-radius: 100%;
    color: #fff;
    padding: 10px;
    opacity: 0.75; }

.edit-image-controls .crop-dropdown {
  width: 20px;
  padding-right: 0;
  padding-left: 0; }

.edit-image-controls .react-numeric-input b i {
  background: white !important; }
