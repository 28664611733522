@import "variables";

$button-font-size: 1.25em;

.dropdown-actions {
  height: 100%;

  .dropdown-actions-open {
    color: $white;
  }

  svg {
    font-size: $button-font-size;
  }

  .loading-icon {
    font-size: $button-font-size;
  }

  .btn {
    padding: 0;

    /* Flex Styles */
    display: flex;
    align-items: center;
  }
}
