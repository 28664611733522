/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.pricing-tabs {
  flex-wrap: nowrap;
  overflow-x: hidden;
  padding-top: 20px;
  justify-content: center;
  border: none;
  line-height: 1; }
  .pricing-tabs > .nav-item {
    margin-bottom: 0;
    border-radius: 0; }
  .pricing-tabs .nav-link {
    text-align: center;
    text-transform: uppercase;
    width: 200px;
    background-color: #D1D3D4;
    color: #fff;
    overflow-x: hidden; }
    .pricing-tabs .nav-link .subtitle {
      font-size: 0.7em; }
    .pricing-tabs .nav-link.active {
      background-color: #fff;
      border-radius: 0; }
