/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
#editor-page {
  background: center/11px url("../../../assets/images/display-bg.png") repeat;
  display: flex;
  height: 100%;
  overflow: hidden; }
  #editor-page #editor-side {
    flex: 0 0 290px; }
  #editor-page .main-display {
    flex: 2 0 auto;
    height: 100%;
    width: calc(100vw - 290px);
    position: relative; }
    #editor-page .main-display:active .display-controls, #editor-page .main-display:focus .display-controls, #editor-page .main-display:hover .display-controls {
      right: 15px; }
    #editor-page .main-display:active .scrollbars .scrollbar-horizontal .single-slider-track,
    #editor-page .main-display:active .scrollbars .scrollbar-vertical .single-slider-track, #editor-page .main-display:focus .scrollbars .scrollbar-horizontal .single-slider-track,
    #editor-page .main-display:focus .scrollbars .scrollbar-vertical .single-slider-track, #editor-page .main-display:hover .scrollbars .scrollbar-horizontal .single-slider-track,
    #editor-page .main-display:hover .scrollbars .scrollbar-vertical .single-slider-track {
      opacity: .4; }
    #editor-page .main-display:active .scrollbars .scrollbar-horizontal .single-slider-handle,
    #editor-page .main-display:active .scrollbars .scrollbar-vertical .single-slider-handle, #editor-page .main-display:focus .scrollbars .scrollbar-horizontal .single-slider-handle,
    #editor-page .main-display:focus .scrollbars .scrollbar-vertical .single-slider-handle, #editor-page .main-display:hover .scrollbars .scrollbar-horizontal .single-slider-handle,
    #editor-page .main-display:hover .scrollbars .scrollbar-vertical .single-slider-handle {
      opacity: .8; }
  #editor-page .display-wrapper {
    height: 100%; }
  #editor-page .display-controls {
    border-bottom: none;
    width: 50px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    transition: right .1s ease-out;
    /* Flex Styles */
    display: flex;
    flex-direction: column;
    justify-content: center; }
    #editor-page .display-controls .controls-wrapper {
      border: 1px solid #292929;
      padding: 0;
      width: 100%; }
      #editor-page .display-controls .controls-wrapper:first-of-type {
        border-bottom: 0; }
      #editor-page .display-controls .controls-wrapper:last-of-type {
        border-top: 0; }
    #editor-page .display-controls .display-control {
      min-height: 160px; }
    #editor-page .display-controls .zoom-control {
      min-height: 200px; }
  #editor-page.no-permissions {
    padding: 30px;
    color: white; }
