@import "variables";

.edit-screenshot-time-controls {
  .current-time {
    font-size: 1.05em;
    margin-bottom: 1em;
  }

  .current-time-value {
    font-weight: bold;
  }

  .set-buttons-header {
    text-align: center;
  }

  .set-button {
    color: $white;
  }
}
