/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
#simple-layout {
  height: 100%;
  overflow-y: hidden;
  /* Flex Styling */
  display: flex;
  flex-direction: column; }

.simple-page {
  overflow: auto; }
  .simple-page.with-emulation-bar {
    height: calc(100vh - 95px); }
    @media (min-width: 576px) {
      .simple-page.with-emulation-bar {
        height: calc(100vh - 48px); } }
