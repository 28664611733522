/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.zoom-control {
  background-color: #008BC2;
  border-top: 1px solid #9CD7EE;
  height: 100%;
  padding: .5rem 0;
  user-select: none;
  /* Display Styles */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .zoom-control .zoom-icon {
    font-size: 1.5em;
    flex: 0 1 auto; }
  .zoom-control .zoom-slider {
    margin: 5px; }
  .zoom-control .zoom-label {
    font-size: .75em; }
  .zoom-control .single-slider {
    flex: 0 1 auto; }
  .zoom-control .single-slider-track {
    background-color: #9CD7EE; }
