/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.plan-list-container {
  overflow-y: auto; }

.plan-list-tabs {
  flex-wrap: nowrap;
  overflow-x: scroll; }
  .plan-list-tabs > .nav-item {
    margin-bottom: 0; }

.plan-list {
  display: flex;
  flex-wrap: nowrap;
  min-width: 1100px; }
