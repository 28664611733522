@import 'variables';

// This should match the HANDLE_RADIUS value in MultiSlider.js.
$handle-radius: 6px;
$slider-height: 15px;

.timeline-slider {
  position: relative;
  background-color: grey;
  width: 100%;
  height: $slider-height;
  margin: 10px 0px;

  .timeline-slider-track {
    background-color: $secondary;
    height: $slider-height;
    position: absolute;
  }

  .timeline-slider-handle {
    background-color: $white;
    border: 1px solid $midnight-blue;
    border-radius: 2px;
    cursor: pointer;
    position: absolute;
    left: 0;
    width: $handle-radius * 2;
    height: $slider-height;

    &:hover {
      background-color: $gray-300;
    }
  }

  .timeline-transition-handle {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    height: $slider-height;

    &:hover {
      .timeline-transition-handle-text {
        background-color: rgba(255, 255, 255, 0.5);
      }
    }

    .timeline-transition-handle-text {
      color: $blue-600;
      position: absolute;
      top: 0;
      left: 8px;
      font-size: 10px;
      height: $slider-height;
      line-height: $slider-height;
      border: 1px solid $blue-600;
      padding: 0 2px 0 6px;
      background-color: rgba(255, 255, 255, 0.3);
      white-space: nowrap;

      &.right-text {
        right: -8px;
        left: auto;
        padding: 0 6px 0 2px;
      }
    }

    .timeline-transition-handle-marker {
      position: absolute;
      bottom: 0;
      width: 0; 
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      
      border-bottom: 8px solid $blue-800;
    }
  }
}
