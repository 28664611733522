/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.sign-form {
  background-color: #fff;
  color: #000; }
  .sign-form .form-control {
    margin: 1rem 0;
    color: #000;
    font-weight: 400; }
    .sign-form .form-control::placeholder {
      color: #999999;
      font-weight: 400; }
  .sign-form .upper-label {
    position: absolute;
    top: -4px;
    color: #999999;
    text-transform: uppercase;
    left: 30px;
    font-size: 0.7rem; }
  .sign-form .help-popover {
    border: 1px solid #999999;
    color: #999999;
    width: 20px;
    height: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    cursor: help;
    font-weight: bold; }
  .sign-form #popover-optional {
    margin-left: 0.5rem; }
  .sign-form #popover-manufacturer {
    position: absolute;
    top: 36px;
    left: 0; }
  .sign-form #popover-resolution {
    position: absolute;
    top: 31px;
    left: 0; }
  .sign-form #popover-matrix {
    margin-left: 0.5rem;
    font-size: 1rem; }
  .sign-form .invalid-feedback {
    display: block; }

.sign-form-popover.help-popover-resolution {
  max-width: 472px; }
  .sign-form-popover.help-popover-resolution .help-popover-image {
    height: 159px;
    margin-left: 2rem; }

.sign-form-popover.help-popover-matrix {
  max-width: 481px; }
  .sign-form-popover.help-popover-matrix .help-popover-image {
    height: 178px;
    margin-left: 0.5rem; }
