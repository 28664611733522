/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.wave-graphic {
  position: fixed;
  top: 0;
  right: 0;
  display: none;
  height: 100vh;
  width: 198px;
  opacity: 1;
  background-color: #11516d;
  transition: width 1000ms ease; }
  @media (min-width: 992px) {
    .wave-graphic {
      display: block; } }
  .wave-graphic.onboarding {
    width: 100px;
    display: none; }
    @media (min-width: 1920px) {
      .wave-graphic.onboarding {
        display: block; } }

.wave-graphic.fullscreen {
  width: 100vw; }

.wave {
  position: absolute;
  left: -198px;
  height: 6400px;
  width: 198px;
  background: url("../../../assets/images/wave.svg") repeat-y;
  animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0); }

.wave:nth-of-type(2) {
  top: -175px;
  opacity: 1;
  animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite, swell 7s ease -1.25s infinite; }

@keyframes wave {
  0% {
    margin-top: 0; }
  100% {
    margin-top: -1600px; } }

@keyframes swell {
  0%, 100% {
    transform: translate3d(0, -25px, 0); }
  50% {
    transform: translate3d(0, 5px, 0); } }
