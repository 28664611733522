@import "variables";

.collection-sidebar-list {
  background-color: $body-bg-color;
  margin-top: 8px;

  .sidebar-list-header {
    padding: 0.5rem 0.5rem 0.5rem 0.3rem;
  }

  .sidebar-list-item {
    border-radius: $default-radius;
    transition: background-color 250ms ease;
    cursor: pointer;
    padding: 0.2rem 1rem;

    &:hover, &.active {
      background-color: $lightest-gray;
    }

    .sidebar-list-item-count {
      opacity: 0.5;
    }
  }

  .free-label {
    background: $primary;
    color: $white;
    font-size: 0.8rem;
    border-radius: 20px;
    box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.1);
    text-transform: uppercase;
    transition: opacity 250ms ease;
    margin-left: 0.5rem;
  }
}
