@import 'variables';

.navbar-left-logo {
  width: 175px;
  padding: 0 0.5rem;
  overflow: hidden;

  img {
    max-width: 100%;
  }

  @media (min-width: map-get($grid-breakpoints, sm)) {
    width: 296px;
  }

  // at this size, the logo becomes just the PC icon with no text
  // this is because the nav links take up too much width
  //
  // we use the same logo throughout all sizes,
  // but at this size we hide the text with "overflow: hidden"
  // on the parent .navbar-left-logo element
  @media (min-width: map-get($grid-breakpoints, lg)) {
    width: 52px;

    img {
      max-width: calc(296px - 1rem);
    }
  }

  @media (min-width: map-get($grid-breakpoints, xl)) {
    width: 296px;
  }
}