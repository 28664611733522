/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.single-slider {
  cursor: pointer;
  display: block;
  position: relative;
  width: 100%;
  height: 24px;
  user-select: none; }
  .single-slider .single-slider-track {
    background-color: #999999;
    border-radius: 30px;
    height: 4px;
    position: absolute;
    top: 9px;
    left: 0;
    width: 100%; }
  .single-slider .single-slider-handle {
    background-color: #fff;
    border-radius: 100%;
    border: 1px solid #00314A;
    cursor: pointer;
    position: absolute;
    top: 5px;
    left: 0;
    width: 12px;
    height: 12px; }
    .single-slider .single-slider-handle:hover {
      background-color: #D8D8D8; }
  .single-slider.slider-teal .single-slider-track {
    background-color: #50D2C2; }
  .single-slider.vertical {
    height: 100%;
    width: 24px; }
    .single-slider.vertical .single-slider-track {
      top: 0;
      left: 9px;
      height: 100%;
      width: 4px; }
    .single-slider.vertical .single-slider-handle {
      top: 0;
      left: 5px; }
