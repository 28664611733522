/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.edit-time-controls .edit-time-text-wrapper {
  height: 1rem;
  margin-bottom: 1rem;
  position: relative; }

.edit-time-controls .edit-time-text {
  color: #999999;
  font-size: .75rem;
  position: absolute;
  top: 0; }

.edit-time-controls .edit-time-start {
  left: -10px; }

.edit-time-controls .edit-time-end {
  right: -10px; }
