.generate-image-modal {

  .image-preview-row {
    margin-left: -0.3rem;
    margin-right: -0.3rem;

    .col {
      padding-left: 0.3rem;
      padding-right: 0.3rem;
      margin-bottom: 0.6rem;
    }
  }
}