/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.content-info-modal {
  flex: 1;
  margin-bottom: 5rem;
  max-width: 700px;
  padding: 0 2px; }
  .content-info-modal .modal-content {
    background-color: #000;
    padding: 0;
    border-radius: 10px; }
  .content-info-modal .modal-body {
    background-image: linear-gradient(to right, #00DBD0 45%, #008BC2);
    border-radius: 0 0 8px 8px; }
  .content-info-modal .modal-body-content {
    padding: 1.5em; }
  .content-info-modal .info-btn-responsive {
    width: 100%;
    margin-top: 1rem; }
    .content-info-modal .info-btn-responsive:first-child {
      margin-top: 0; }
    @media (min-width: 576px) {
      .content-info-modal .info-btn-responsive {
        width: auto;
        margin-top: 0;
        margin-left: 1rem; }
        .content-info-modal .info-btn-responsive:first-child {
          margin-left: 0; } }
  .content-info-modal .content-admin-bar {
    background-color: #00709E;
    padding: 1.5em;
    border-radius: 0 0 8px 8px; }
