#transitionWrapper {
  .slide-in-appear {
    transform: translateX(30px);
    opacity: 0;
  }
  
  .slide-in-appear.slide-in-appear-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 0.6s linear;
  }
  
  .slide-in-enter {
    opacity: 0;
    transform: translateX(30px);
  }
  
  .slide-in-enter.slide-in-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 0.2s linear 0.4s;
  }
  
  .slide-in-exit {
    opacity: 1.0;
    transform: translateX(0);
  }
  
  .slide-in-exit.slide-in-exit-active {
    opacity: 0;
    transform: translateX(-30px);
    transition: all 0.2s linear;
  }
}