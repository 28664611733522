@import "variables";

.draft-preview {
  background-color: #b7b7b7;
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  .presto-icon {
    background-repeat: no-repeat;
    background-size: contain;
    filter: grayscale(1);

    width: 160px;
    @media (min-width: map-get($grid-breakpoints, sm)) {
      width: 200px;
    }
  }

  .draft-text {
    color: #ffffff;
    font-size: 5em;
    line-height: normal;
    text-transform: uppercase;
  }
}
