/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
#admin-company-signs-page {
  height: 100%;
  margin-top: 1.5em;
  overflow-y: auto; }
  #admin-company-signs-page .signs-header {
    margin-bottom: .5em; }
  #admin-company-signs-page .card-deck {
    margin-bottom: 2em; }
  #admin-company-signs-page .card {
    color: #000;
    margin-bottom: 1.5em;
    max-width: 18em;
    min-width: 18em; }
  #admin-company-signs-page .add-new-card {
    background-color: transparent; }
    #admin-company-signs-page .add-new-card .card-body {
      display: flex;
      align-content: center;
      justify-content: center; }
    #admin-company-signs-page .add-new-card .add-new-button {
      background-color: #fff;
      border: 1px solid gray;
      color: #000;
      width: 100%; }
