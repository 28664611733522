/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
#presto-page {
  background: center/11px url("../../../assets/images/display-bg.png") repeat;
  height: 100%;
  overflow: hidden; }
  #presto-page .presto-top {
    height: calc(100vh - 82px - 91px);
    overflow: hidden;
    /* Flex Styles */
    display: flex;
    align-items: stretch;
    flex-direction: row;
    justify-content: space-between; }
    #presto-page .presto-top.has-message-bar {
      height: calc(100vh - 82px - 91px - 95px); }
      @media (min-width: 576px) {
        #presto-page .presto-top.has-message-bar {
          height: calc(100vh - 82px - 91px - 48px); } }
  #presto-page #display-sidebar.is-minimized {
    flex: 0 0 38px; }
  #presto-page #display-sidebar.is-expanded {
    flex: 0 0 290px; }
  #presto-page .main-display {
    flex: 2 0 auto;
    height: 100%;
    width: calc(100vw - 290px);
    position: relative; }
    #presto-page .main-display:active .display-controls, #presto-page .main-display:focus .display-controls, #presto-page .main-display:hover .display-controls {
      right: 15px; }
    #presto-page .main-display:active .scrollbars .scrollbar-horizontal .single-slider-track,
    #presto-page .main-display:active .scrollbars .scrollbar-vertical .single-slider-track, #presto-page .main-display:focus .scrollbars .scrollbar-horizontal .single-slider-track,
    #presto-page .main-display:focus .scrollbars .scrollbar-vertical .single-slider-track, #presto-page .main-display:hover .scrollbars .scrollbar-horizontal .single-slider-track,
    #presto-page .main-display:hover .scrollbars .scrollbar-vertical .single-slider-track {
      opacity: .4; }
    #presto-page .main-display:active .scrollbars .scrollbar-horizontal .single-slider-handle,
    #presto-page .main-display:active .scrollbars .scrollbar-vertical .single-slider-handle, #presto-page .main-display:focus .scrollbars .scrollbar-horizontal .single-slider-handle,
    #presto-page .main-display:focus .scrollbars .scrollbar-vertical .single-slider-handle, #presto-page .main-display:hover .scrollbars .scrollbar-horizontal .single-slider-handle,
    #presto-page .main-display:hover .scrollbars .scrollbar-vertical .single-slider-handle {
      opacity: .8; }
  #presto-page .display-wrapper {
    height: 100%; }
  #presto-page .display-controls {
    border-bottom: none;
    width: 50px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    transition: right .1s ease-out;
    /* Flex Styles */
    display: flex;
    flex-direction: column;
    justify-content: center; }
    #presto-page .display-controls .controls-wrapper {
      border: 1px solid #292929;
      padding: 0;
      width: 100%; }
      #presto-page .display-controls .controls-wrapper:first-of-type {
        border-bottom: 0; }
      #presto-page .display-controls .controls-wrapper:last-of-type {
        border-top: 0; }
    #presto-page .display-controls .display-control {
      min-height: 160px; }
    #presto-page .display-controls .zoom-control {
      min-height: 200px; }
