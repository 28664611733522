@import "variables";

#editor-nav-item {
  border-right: 1px solid $blue-600;
  height: 100%;
  max-width: 300px;
  padding: 0 1.5em 0 0;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  .nav-back-button {
    font-size: 1em;
    color: $body-color;
  }

  .logo {
    height: 57px;
    width: 57px;
    max-width: none;
  }

  .presto-header {
    margin-left: 1.5em;

    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .presto-label-main {
    line-height: 1.25em;
    margin-left: 1em;
  }
  .presto-label {
    font-size: 1.25em;
  }
  .presto-sub-label {
    font-size: .75em;
  }
}
