/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.header-sidebar-list {
  background-color: #fff; }

.sidebar-list-item {
  border-radius: 4px;
  transition: background-color 250ms ease;
  cursor: pointer;
  padding: 0.2rem 1rem;
  color: #000; }
  .sidebar-list-item-append {
    color: #00DBD0; }
  .sidebar-list-item:hover, .sidebar-list-item.active {
    background-color: #E9ECEF;
    text-decoration: none;
    color: #000; }
  .sidebar-list-item .sidebar-list-item-count {
    opacity: 0.5; }
  .sidebar-list-item.disabled-link {
    cursor: not-allowed;
    color: #D1D3D4; }
    .sidebar-list-item.disabled-link:hover {
      background-color: #fff; }
  .sidebar-list-item .badge-pill {
    padding: 3px 10px;
    margin-top: 0px;
    background-color: #00DBD0;
    color: #fff; }

.maintenance-message {
  background-color: orange;
  padding: 5px;
  font-weight: bold;
  text-align: center; }
