.sidebar-title-container {
  display: flex;
  justify-content: space-between;
  margin: 0 0.75rem;
  padding: 1rem 0.75rem 0 0.75rem;
}

.sidebar-title-text {
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
}