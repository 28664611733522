@import 'variables';

// This should match the HANDLE_RADIUS value in MultiSlider.js.
$handle-radius: 6px;

.multi-slider {
  position: relative;
  width: 100%;

  .multi-slider-track {
    background-color: $teal;
    border-radius: 30px; // Make this very high to account for any height.
    height: 4px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .multi-slider-handle {
    background-color: $white;
    border-radius: 100%;
    cursor: pointer;
    position: absolute;
    top: -4px;
    left: 0;
    width: $handle-radius * 2;
    height: $handle-radius * 2;

    &:hover {
      background-color: $gray-300;
    }
  }
}
