@import "variables";

#editor-template-nav-item {
  height: 100%;
  padding: 0 1.5em;

  /* Flex Style */
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .template-title {
    font-size: .85em;
    margin-left: 1em;
  }
}
.template-plan {
  margin-left: 1em;

  .site-select {
    min-width: 7em;
  }
}
