@import 'variables';

.zoom-control {
  background-color: $sky-blue;
  border-top: 1px solid $blue-400;
  height: 100%;
  padding: .5rem 0;
  user-select: none;

  /* Display Styles */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .zoom-icon {
    font-size: 1.5em;
    flex: 0 1 auto;
  }

  .zoom-slider {
    margin: 5px;
  }

  .zoom-label {
    font-size: .75em;
  }

  .single-slider {
    flex: 0 1 auto;
  }

  .single-slider-track {
    background-color: $blue-400;
  }
}
