/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.search-content-items {
  position: relative; }
  .search-content-items .fade-enter {
    opacity: 0;
    transform: scale(0.5); }
  .search-content-items .fade-enter-active {
    opacity: 1;
    transform: scale(1); }
  .search-content-items .fade-exit {
    opacity: 1;
    transform: scale(1); }
  .search-content-items .fade-exit-active {
    opacity: 0;
    transform: scale(0.5); }
