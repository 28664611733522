/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.folder-sidebar-list {
  background-color: #fff;
  margin-top: 8px; }
  .folder-sidebar-list .sidebar-list-header {
    padding: 0.5rem 0.5rem 0.5rem 0.3rem; }
  .folder-sidebar-list .sidebar-list-item {
    border-radius: 4px;
    transition: background-color 250ms ease;
    cursor: pointer;
    padding: 0.2rem 1rem; }
    .folder-sidebar-list .sidebar-list-item:hover, .folder-sidebar-list .sidebar-list-item.active {
      background-color: #E9ECEF; }
    .folder-sidebar-list .sidebar-list-item .sidebar-list-item-prepend {
      color: #008BC2; }
    .folder-sidebar-list .sidebar-list-item .sidebar-list-item-count {
      opacity: 0.5; }
  .folder-sidebar-list .fa-folder {
    color: #008BC2;
    font-size: 1.2rem;
    transition: color 250ms ease; }
  .folder-sidebar-list .help-text {
    display: inline-block;
    opacity: .5;
    text-align: center;
    width: 100%; }
  .folder-sidebar-list .sidebar-list-item.active .fa-folder {
    color: #fff; }
