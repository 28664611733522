/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.color-selector .rc-color-picker-trigger {
  border: 0;
  border-radius: 4px;
  box-shadow: 0 0 0 white;
  position: relative;
  overflow: hidden;
  width: 36px;
  height: 36px; }
  .color-selector .rc-color-picker-trigger::before {
    content: "";
    background-color: #2fabdb;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 10px;
    height: 10px; }
  .color-selector .rc-color-picker-trigger::after {
    content: "";
    display: inline-block;
    border: 4px solid transparent;
    border-top: 4px solid black;
    border-bottom: 0 none;
    position: absolute;
    bottom: 3px;
    right: 1px; }
