@import 'variables';

.content-info-modal {
  flex: 1;
  margin-bottom: 5rem;
  max-width: 700px;
  padding: 0 2px;

  .modal-content {
    background-color: $black;
    padding: 0;
    border-radius: 10px;
  }

  .modal-body {
    background-image: linear-gradient(to right, $primary 45%, $secondary);
    border-radius: 0 0 8px 8px;
  }

  .modal-body-content {
    padding: 1.5em;
  }

  .info-btn-responsive {
    width: 100%;
    margin-top: 1rem;

    &:first-child {
      margin-top: 0;
    }

    @media (min-width: map-get($grid-breakpoints, sm)) {
      width: auto;
      margin-top: 0;
      margin-left: 1rem;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .content-admin-bar {
    background-color: #00709E;
    padding: 1.5em;
    border-radius: 0 0 8px 8px;
  }
}
