@import '../../../../node_modules/rc-color-picker/assets/index.css';
@import 'variables';

.rc-color-picker {
  z-index: 1005;
}

.rc-color-picker-panel {
  background-color: $gray-900;
  width: 100%;
}

.rc-color-picker-panel-inner {
  border: 1px solid $gray-500;
  box-shadow: 0 0 0 white;
  padding: 1rem 1rem 2rem;
}
