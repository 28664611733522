@import "variables";

.transition-list-item {
  position: relative;
  user-select: none;
  padding: .25rem .10rem .25rem .5rem;
  min-height: 50px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: $body-bg-color;

  &:hover {
    .transition-delete {
      display: block;
    }

    &:not(.active) {
      .btn-link {
        color: $white;

        &:hover {
          color: $gray-300;
        }
      }
    }
  }

  &.active {
    .badge {
      border-color: $gray-800;
    }
  }

  .transition-order {
    margin-right: 1em;
  }

  .transition-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    width: 100%;
  }

  .transition-delete {
    display:none;
  }

  .dropdown-item {
    svg {
      margin-right: 1rem;
      text-align: center;
      width: 16px;
    }
  }

  .transition-actions-wrapper {
    display: flex;
    align-items: center;
  }

  .transition-actions {
    .btn-link {
      position: relative;
      top: 1px;
    }
  }
}

// In IE and Edge, change the padding to account for scrollbar width.
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  $ie-right-size: 10px;

  .transition-list-item {
    .transition-actions-wrapper {
      margin-right: $ie-right-size;
    }
  }
}
