@import 'variables';

$entity-width: 180px;

.transition-entity {
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  margin: 20px;
  padding: 15px;
  height: 255px;
  width: $entity-width;
  cursor: pointer;

  &:hover, &:focus {
    background-color: $secondary;
  }

  &.active {
    background-color: $secondary;
  }

  .transition-entity-img {
    height: 150px;
    width: 150px;
  }

  .transition-entity-label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 8px 0px 4px;
    font-size: 14px;
    font-weight: 600;
  }

  .transition-entity-select {
    width: 100%;
    border: 1px $secondary solid;
    border-radius: $default-radius;
  }
}