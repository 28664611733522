/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.collection-example {
  border-radius: 10px;
  flex: 0 0 15rem;
  overflow: hidden;
  margin: 1rem;
  transition: all 200ms ease;
  cursor: pointer;
  position: relative;
  background-color: #fff;
  background-position: 100%;
  background-size: 200%; }
  .collection-example:hover {
    margin: 0.5rem;
    flex: 0 0 16rem;
    background-position: 0; }
  .collection-example.active {
    border: 4px solid #50D2C2; }
  .collection-example .collection-example-info {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .collection-example .collection-example-name {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .collection-example .img {
    background-position: center;
    background-size: cover; }
  .collection-example .container-16-9 {
    position: relative;
    padding-top: 56.25%; }
  .collection-example .collection-example-thumbnail {
    position: absolute;
    transform: translateY(-50%);
    border-radius: 10px;
    top: 50%;
    left: 0;
    width: 100%;
    height: 100%; }
