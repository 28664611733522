/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
#signup-page {
  background-color: #fff;
  color: #000;
  padding-top: 2rem; }
  @media (min-width: 992px) {
    #signup-page {
      padding-top: 8rem; } }
  #signup-page .logo {
    max-width: 225px; }
  #signup-page .signup-button {
    border-color: #008BC2; }
  #signup-page hr {
    border-top: 2px dashed #003250; }
