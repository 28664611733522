@import 'variables';

// This should match the HANDLE_RADIUS value in SingleSlider.js.
$handle-radius: 6px;

$slider-top-offset: 9px;

.single-slider {
  cursor: pointer;
  display: block;
  position: relative;
  width: 100%;
  height: 24px;
  user-select: none;

  .single-slider-track {
    background-color: $gray-400;
    border-radius: 30px; // Make this very high to account for any height.
    height: 4px;
    position: absolute;
    top: $slider-top-offset;
    left: 0;
    width: 100%;
  }

  .single-slider-handle {
    background-color: $white;
    border-radius: 100%;
    border: 1px solid $midnight-blue;
    cursor: pointer;
    position: absolute;
    top: -4px + $slider-top-offset;
    left: 0;
    width: $handle-radius * 2;
    height: $handle-radius * 2;

    &:hover {
      background-color: $gray-300;
    }
  }

  &.slider-teal {
    .single-slider-track {
      background-color: $teal;
    }
  }

  &.vertical {
    height: 100%;
    width: 24px;

    .single-slider-track {
      top: 0;
      left: $slider-top-offset;
      height: 100%;
      width: 4px;
    }

    .single-slider-handle {
      top: 0;
      left: -4px + $slider-top-offset;
    }
  }
}
