@import 'variables';

.text-field.form-group {
  margin-bottom: .5rem;

  .form-control {
    padding-left: 0;
    padding-bottom: 1.5rem;
    border: none;
    border-radius: 0;
    border-bottom: 2px solid $gray-300;

    &:focus {
      box-shadow: none;
      border-color: $primary;
    }
  }

  &.floating-label{
    .form-control {
      margin-top: 20px;
    }

    label {
      position: relative;
      display: flex;
      flex-direction: column;
      vertical-align: top;
    }

    span {
      color: #9296A2;
      position: absolute;
      top: 0;
      left: 0;
      cursor: text;
      transform: translate(0, 24px) scale(1);
      transform-origin: top left;
      transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    }

    &.has-content span,
    .form-control:focus + span {
      font-weight: 600;
      text-transform: uppercase;
      transform: translate(0, 1.5px) scale(0.75);
    }
  }

  .invalid-feedback {
    display: block;
  }
}
