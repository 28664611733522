.smart-table {
  .table-sortable {
    thead {
      tr {
        cursor: pointer;
      }

      .show-on-hover {
        i {
          visibility: hidden;
        }
      }

      th:hover {
        .show-on-hover {
          i {
            visibility: visible;
          }
        }
      }
    }
  }
}
