/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.search-form {
  font-size: .875em; }
  .search-form .search-form-submit {
    display: none; }
  .search-form .search-form-query {
    background-color: transparent;
    margin-left: 0.5em;
    border: none;
    width: calc(100% - 17px - 0.5em); }
    .search-form .search-form-query:focus {
      outline: none; }
  .search-form .search-form-icon {
    font-size: 1.25em;
    width: 17px; }
  .search-form .disabled {
    opacity: 0.6;
    cursor: not-allowed; }

#content-header .sub-header-search-wrapper .search-form-query {
  width: calc(100% - 25px - 0.5em); }

#content-header .sub-header-search-wrapper .search-form-icon {
  width: 25px; }
