/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
#admin-sidebar .admin-side-header {
  background-color: #fff;
  color: #00283D;
  padding: 1em; }

#admin-sidebar .nav-item {
  color: #000; }
