@import "_bootstrap";

$title-height: 65px;
$actions-height: 80px;
$total-height: $title-height + $actions-height;

#presto-bottom-bar {
  background-color: $sky-blue;
  box-shadow: 7px 0 1px 0 rgba($black, 0.3);
  height: 91px;

  /* Flex Styles */
  display: flex;
  align-items: stretch;
  flex-direction: row;
  justify-content: space-between;

  .entity-list {
    height: 100%;
    overflow-y: hidden;
    padding-left: 1em;

    /* Flex Styles */
    display: flex;
    justify-content: flex-start;
  }

  .entity-nav {
    margin-right: 1rem;
  }

  .bottom-bar-entities {
    width: 100%;
    overflow-x: auto;
  }

  .bottom-bar-actions {
    height: 100%;
    padding-right: 1em;

    /* Flex Styles */
    display: flex;
    align-items: center;

    .action-button {
      flex: 0 1 auto;
    }
  }

  .dropdown-button {
    background-color: darken($teal, 10);
    border-color: darken($teal, 10);
    border-top-right-radius: $btn-border-radius-lg;
    border-bottom-right-radius: $btn-border-radius-lg;
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .dropdown-button-text {
    margin-left: 1em;
  }

  .back-button {
    margin-right: 1em;
  }

  .action-button {
    &.btn-secondary {
      background-color: $white;
      color: $black;
    }

    &.btn-outline-secondary {
      border-color: $white;
      color: $white;
    }
  }
}
