@import 'variables';

.plan-list-container {
  overflow-y: auto;
}

.plan-list-tabs {
  flex-wrap: nowrap;
  overflow-x: scroll;

  > .nav-item {
    margin-bottom: 0 ;
  }
}

.plan-list {
  display: flex;
  flex-wrap: nowrap;
  min-width: 1100px;
}