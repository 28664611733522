@import 'variables';

.sign-form {
  background-color: $white;
  color: $black;

  .form-control {
    margin: 1rem 0;
    color: $black;
    font-weight: 400;

    &::placeholder {
      color: $gray-400;
      font-weight: 400;
    }
  }

  .upper-label {
    position: absolute;
    top: -4px;
    color: $gray-400;
    text-transform: uppercase;
    left: 30px;
    font-size: 0.7rem;
  }

  .help-popover {
    border: 1px solid $gray-400;
    color: $gray-400;
    width: 20px;
    height: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    cursor: help;
    font-weight: bold;
  }

  #popover-optional {
    margin-left: 0.5rem;
  }

  #popover-manufacturer {
    position: absolute;
    top: 36px;
    left: 0;
  }

  #popover-resolution {
    position: absolute;
    top: 31px;
    left: 0;
  }

  #popover-matrix {
    margin-left: 0.5rem;
    font-size: 1rem;
  }

  .invalid-feedback {
    display: block;
  }
}

.sign-form-popover {
  &.help-popover-resolution {
    max-width: 472px;

    .help-popover-image {
      height: 159px;
      margin-left: 2rem;
    }
  }

  &.help-popover-matrix {
    max-width: 481px;

    .help-popover-image {
      height: 178px;
      margin-left: 0.5rem;
    }
  }
}
