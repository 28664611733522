/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
#privacy-policy-page {
  height: 100%;
  overflow-y: auto;
  padding-top: 2em; }
  #privacy-policy-page .page-title {
    font-weight: 700;
    font-size: 2.25em;
    line-height: 2.25em;
    margin-bottom: 0; }
  #privacy-policy-page .statement-wrapper {
    border-bottom: 1px solid gray;
    padding-top: 2em;
    padding-bottom: 2em; }
  #privacy-policy-page .statement-label {
    color: #50D2C2;
    font-size: 1.5em;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 1.5em;
    padding-bottom: .75em; }
  #privacy-policy-page .last-updated {
    color: gray;
    padding-top: 2em;
    padding-bottom: 2em; }
