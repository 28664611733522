@import 'variables';

$modal-width: 75vw;
$modal-height: 82vh;
$upper-height: 75px;

$header-height: 38px;

.select-media-modal {
  max-width: $modal-width;

  .modal-content {
    background-color: $body-bg-color;
    width: $modal-width;
    padding: 0;
  }

  .modal-header {
    background-color: $secondary;
    color: $white;
    padding: 1em;
    border-bottom: 1px solid $gray-900;
  }

  .modal-body-content-wrapper {
    height: $modal-height;
    width: 100%;
  }

  .select-file-main {
    display: flex;
    justify-content: space-between;
    padding: .75rem 0;
    height: 100%;
    width: 100%;

    & > .left-side {
      flex: 0 1 275px;
      margin-left: 1em;
      overflow: auto;
    }
    & > .right-side {
      margin-left: .5em;
      margin-right: 1em;
      overflow: hidden;
      width: 100%;
      overflow: auto;
    }
  }

  .files-list {
    overflow-y: auto;
    height: calc(100% - #{$header-height} - 30px);
  }

  .files-list-flex {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .file-upload {
    width: 224px;
    margin: .5rem;
    display: flex;
    align-items: center;
  }
}
