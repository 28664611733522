/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.onboarding-content {
  width: 100%;
  padding: 1rem; }
  .onboarding-content .welcome-video {
    width: 100%; }
  .onboarding-content .plan-table {
    margin: 1rem; }
  .onboarding-content .pc-logo {
    height: 25px;
    width: 25px; }
  .onboarding-content .next-promo-title {
    padding: 0 30px; }
  .onboarding-content .next-promo-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    .onboarding-content .next-promo-container .next-plan-item {
      width: 100%;
      margin-bottom: -75px; }
      @media (min-width: 768px) {
        .onboarding-content .next-promo-container .next-plan-item {
          width: 400px; } }
    .onboarding-content .next-promo-container .table-wrapper {
      overflow-x: auto;
      flex-grow: 1; }
      @media (min-width: 768px) {
        .onboarding-content .next-promo-container .table-wrapper {
          flex-grow: 0; } }
    .onboarding-content .next-promo-container .pc-feature-table {
      min-width: 450px; }
      .onboarding-content .next-promo-container .pc-feature-table .header-row {
        display: flex;
        flex-wrap: nowrap;
        text-transform: uppercase;
        font-weight: 500; }
        .onboarding-content .next-promo-container .pc-feature-table .header-row .pc-col {
          border-radius: 10px 10px 0 0;
          padding-top: 15px; }
      .onboarding-content .next-promo-container .pc-feature-table .feature-row {
        display: flex;
        flex-wrap: nowrap; }
      .onboarding-content .next-promo-container .pc-feature-table .feature-col {
        flex-grow: 1;
        padding: 10px; }
        @media (min-width: 768px) {
          .onboarding-content .next-promo-container .pc-feature-table .feature-col {
            min-width: 400px;
            max-width: 400px; } }
      .onboarding-content .next-promo-container .pc-feature-table .pc-col {
        min-width: 90px;
        max-width: 90px;
        background-color: #008BC2;
        text-align: center;
        color: #fff; }
      .onboarding-content .next-promo-container .pc-feature-table .feature-row:last-child .pc-col {
        border-radius: 0 0 10px 10px;
        padding-bottom: 10px; }
      .onboarding-content .next-promo-container .pc-feature-table .feature-row:nth-child(even) {
        background-color: #E9ECEF; }
        .onboarding-content .next-promo-container .pc-feature-table .feature-row:nth-child(even) .pc-col {
          background-color: #009ddc; }
      .onboarding-content .next-promo-container .pc-feature-table .others-col {
        min-width: 80px;
        max-width: 80px;
        text-align: center;
        padding: 0 10px; }
