/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.editor-remove-dimension {
  margin-left: .25em; }
  .editor-remove-dimension .btn {
    height: 36px; }
  .editor-remove-dimension .dropdown-menu {
    color: black; }
  .editor-remove-dimension .dropdown-item svg {
    margin-right: 1rem;
    text-align: center;
    width: 16px; }
    .editor-remove-dimension .dropdown-item svg.pull-right {
      margin-right: -.75rem;
      margin-top: .25rem;
      width: auto; }
