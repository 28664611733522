@import 'variables';

/* Put all overrides in ./_variables.scss */

/* Include scss */
@import '~bootstrap/scss/bootstrap.scss';

/* Overrides */

/*
 * Dropdown Styling
 */

.dropdown-toggle {
  &:after {
    font-size: 1.2em;
    color: $gray-400;
  }

  &.color-inherit-caret {
    &:after {
      color: inherit;
    }
  }
}

.dropdown-menu {
  background-color: $white;
  padding: 0;
  box-shadow: 0px 2px 4px transparentize(black, .5);
}

.dropdown-item {
  overflow: hidden;
  color: $gray-800;
  border-bottom: 1px solid $gray-300;
  position: relative;
  padding: 0.5rem 1rem;
  font-size: .875em;
  cursor: pointer;
  &:hover, &:focus {
    overflow: hidden;
    background-color: $gray-300;
    color: $gray-900;
  }
  &:first-of-type {
    border-top-left-radius: $default-radius;
    border-top-right-radius: $default-radius;
  }
  &:last-of-type {
    border-bottom-left-radius: $default-radius;
    border-bottom-right-radius: $default-radius;
  }

  &.item-radio {
    padding: 0.5rem 1rem 0.5rem 1.5rem;
    &.active {
      color: $gray-800;
      background-color: $white;
      &:hover, &:focus {
        background-color: $gray-300;
      }
      &:before {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 8px;
        content: "\A";
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: $primary;
        display: block;
      }
    }
  }
}

/*
 * Color Styling
 */

.list-group-item,
.nav-link {
  color: $body-color;
  font-weight: bold;
  border-radius: 0;

  &:hover,
  &:focus,
  &:active,
  &.active {
    border-radius: 0;
    color: $white;
    background-color: rgba(137, 136, 136, 0.5);
  }
}

/*
 * Button Styling
 */

.btn-primary {
  color: $gray-800;

  &:disabled,
  &:hover,
  &:focus {
    color: $gray-800;
  }
}

.btn-secondary {
  &:hover, &:focus {
    border: 1px solid $gray-900;
    background-color: $gray-900;
  }
}

.btn-rounded {
  border-radius: 40px;
}

.btn.disabled, .btn:disabled  {
  opacity: .4;
}

.btn-dark {
  background-color: $blue-600;
  border: 1px solid $white;
  color: $white;

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-color: $white;
    border: 1px solid $white;
    color: $blue-600;
  }

  &:hover {
    background-color: $sky-blue;
    border: 1px solid $white;
    color: $white;
  }
}

.clickable {
  cursor: pointer;
}

/*
 * Modal Styling
 */

.modal-body {
  text-align: left;
  padding: 0;
}

.modal-header {
  text-align: left;
  border-bottom: none;
  padding: 0;

  .close {
    color: $white;
  }

  .modal-title {
    font-weight: bold;
    font-size: 1.5rem;
  }
}

.modal-footer {
  border-top: none;
  padding: 0;
  margin-top: 1.5em;

  .btn {
    padding: .5em 1em;
  }
}

.modal-content {
  padding: 1.5em;
}

// In IE and Edge, change the flex settings for modals.
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .modal-body {
    flex: 0 0 auto;
  }
}

// Transform Styling

.rotate-clockwise-270 {
  transform: rotate(270deg);
}

.btn-dark-blue {
  @include button-variant($midnight-blue, $midnight-blue);
}

// Ratio style from bootstrap 5
.ratio {
  position: relative;
  width: 100%;

  &:before {
    content: "";
    display: block;
  }

  &.ratio-16x9 {
    &:before {
      padding-top: 56.25%;
    }
  }

  >* {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

