/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.file-uploading-progress .uploading-file {
  border-bottom: 1px solid #6D6D6D;
  margin-bottom: 1em;
  padding-bottom: 1em; }

.file-uploading-progress .btn-link:active, .file-uploading-progress .btn-link:focus, .file-uploading-progress .btn-link:hover {
  text-decoration: none; }

.file-uploading-progress .file-progress {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.file-uploading-progress .file-progress-cancel .btn {
  color: #ffc107; }

.file-uploading-progress .file-progress-error {
  color: #ffc107; }

.file-uploading-progress .file-progress-canceled {
  font-size: .7em; }

.file-uploading-progress .file-progress-bar {
  flex: 0 1 auto;
  width: 88%; }

.file-uploading-progress .file-progress-text {
  flex: 0 1 auto; }

.file-uploading-progress .upload-pending .file-progress-bar {
  width: 75%; }

.file-uploading-progress .upload-empty {
  text-align: center; }
