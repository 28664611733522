/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.entity-add-button .dropdown-item svg {
  margin-right: 1rem;
  text-align: center;
  width: 16px; }
  .entity-add-button .dropdown-item svg.pull-right {
    float: right;
    margin-right: 0;
    margin-top: 0.3rem; }

.entity-add-button .ai-tool-text, .entity-add-button .unsplash-tool-text {
  display: block;
  font-size: 11px;
  font-style: italic;
  margin-bottom: 4px;
  line-height: 1; }
