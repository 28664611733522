/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.edit-feed-icon-controls .can-not-edit {
  margin-top: 1rem;
  text-align: center; }

.edit-feed-icon-controls .react-numeric-input b i {
  background: white !important; }
