#display-source {
  // The drop positioning will not work without 100% height.
  height: 100%;

  background-color: rgba(0,0,0,.3);

  // We want to transform from the left so the x and y axis transformations match.
  transform-origin: left top;

  user-select: none;
}

#render-page,
#presto-page {
  #display-source {
    overflow: hidden; // Hides flyIn/flyOut transitions from showing.
  }
}
