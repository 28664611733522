@import 'variables';

#dashboard-page {
  .sidebar {
    background-color: $gray-100;
    border-right: $sidebar-border-width solid $sidebar-border-color;
    flex: 0 0 $sidebar-content-width;
    font-size: 0.9rem;
    overflow-y: scroll;
    width: $main-sidebar-width;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1000;

    .loading-icon {
      width: auto;
      margin: 0.5rem;
    }

    .sidebar-list {
      padding: 1.5rem;
    }
  }

  .sidebar-fixed-container {
    position: fixed;
    z-index: 500;
    top: $main-nav-bar-height;
    right: 0;
    bottom: 0;
    left: 0;

    .sidebar {
      height: 100%;
      background-color: $gray-300;
    }

    .dashboard-sidebar-open {
      background-color: rgba(78, 78, 78, 0.5);
    }

    &.has-message-bar {
      top: calc(#{$main-nav-bar-height} + 95px);

      @media (min-width: map-get($grid-breakpoints, sm)) {
        top: calc(#{$main-nav-bar-height} + 48px);
      }
    }
  }

  .dashboard-header {
    /* Flex Styles */
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dashboard-content {
    max-height: calc(100vh - #{$main-nav-bar-height});
    overflow: scroll;
    padding: 10px;
  }

  .sidebar-margin {
    margin-left: $main-sidebar-width;
  }

  .sidebar-focus {
    background-color: rgba(78, 78, 78, 0.5);
  }

  .choose-folder-header {
    text-align: center;
  }

  .cancel-search-anchor {
    margin-left: .25em;
  }
}
