.file-uploading-modal {
  .modal-content {
    padding: 24px;
  }

  .modal-body-content {
    padding-top: 1em;
  }

  .close-modal {
    text-align: center;
  }
}
