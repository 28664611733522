.sign-select {
  .aspect-ratio-sign-list {
    list-style-type: none;
    margin: 0;
    padding-left: 1rem;
    overflow: hidden;
  }

  .aspect-ratio-sign {
    padding-left: 1rem;
    line-height: 1.1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &-name {
      font-size: 14px;
      font-weight: bold;
    }
    &-dimensions {
      font-size: 12px;
    }
  }
}