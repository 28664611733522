/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.playback-controls {
  background-color: #fff;
  margin-right: 1px;
  margin-left: 1px;
  padding: .25em 1em .25em .75em;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: 250px; }
  .playback-controls .controls-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 5px;
    margin-right: 50px; }
    .playback-controls .controls-wrapper.video-timeline {
      height: 50px; }
    .playback-controls .controls-wrapper.timeline {
      max-height: 200px;
      overflow-x: visible;
      overflow-y: scroll; }
  .playback-controls .entity-info {
    width: 250px;
    display: flex;
    gap: 5px; }
  .playback-controls .entity-add-button {
    align-self: center; }
  .playback-controls .time-control {
    flex-grow: 1;
    align-self: center; }
  .playback-controls .timer {
    align-self: center; }

.inline-playback-controls {
  background-color: #fff;
  height: 53px;
  margin-right: 1px;
  margin-left: 1px;
  padding: .25em 1em .25em .75em;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  /* Flex Styles */
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .inline-playback-controls .player-controls-wrapper {
    width: 85px; }
  .inline-playback-controls .time-control-wrapper {
    width: calc(100% - 150px); }
  .inline-playback-controls .time-tracker-wrapper {
    width: 55px; }
  .inline-playback-controls .controls-wrapper {
    flex: 0 1 auto;
    min-width: 55px;
    padding: 0; }
  .inline-playback-controls .single-slider-track {
    background-color: #008BC2; }
  .inline-playback-controls .timer {
    text-align: right; }
