/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
#display-sidebar {
  background-color: #003250;
  height: 100%;
  overflow-y: auto;
  position: relative; }
  #display-sidebar .expand-arrow {
    position: absolute;
    right: 15px; }
  #display-sidebar .minimize-arrow {
    position: absolute;
    right: 15px; }
  #display-sidebar .minimized-content {
    background-color: #fff;
    padding: 10px 19px 0; }
  #display-sidebar .display-sidebar-content {
    background-color: #fff;
    min-width: 290px;
    max-width: 290px;
    width: 290px; }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #display-sidebar .display-sidebar-content {
    padding: 10px 20px 0 15px; }
  #display-sidebar .group-header {
    margin-right: -20px; }
  #display-sidebar .minimize-arrow {
    right: 20px; } }
