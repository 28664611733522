/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.plan {
  position: relative;
  height: 100%;
  padding: 50px 30px 120px; }
  .plan .plan-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 5px 15px;
    color: #fff;
    text-transform: uppercase;
    font-size: 0.80em;
    text-align: center;
    font-weight: bold; }
    .plan .plan-header.background-primary {
      background-color: #00DBD0; }
    .plan .plan-header.background-secondary {
      background-color: #008BC2; }
  .plan .plan-indicators {
    display: flex; }
    .plan .plan-indicators .plan-step-indicator {
      width: 25%;
      height: 8px;
      background-color: #D1D3D4;
      transform: skew(340deg);
      margin: 0 3px; }
      .plan .plan-indicators .plan-step-indicator.has-color {
        background-color: #008BC2; }
  .plan .plan-title {
    text-transform: uppercase;
    color: #00314A;
    font-weight: 700;
    margin: 25px 0 5px 0; }
    .plan .plan-title.large {
      font-size: 32px; }
  .plan .plan-price {
    color: #595959;
    font-weight: 300;
    padding-bottom: 20px; }
    .plan .plan-price .plan-currency {
      font-size: 15px; }
    .plan .plan-price .plan-price-number {
      font-size: 40px;
      margin: 0 5px; }
      .plan .plan-price .plan-price-number.small-inline {
        font-size: 24px;
        line-height: 24px;
        font-weight: 400;
        margin: 0; }
  .plan .plan-description {
    padding-bottom: 30px; }
    .plan .plan-description-title {
      color: #008BC2;
      font-style: italic;
      font-size: 0.90em;
      font-weight: bold; }
    .plan .plan-description-content {
      color: #595959;
      font-size: 0.90em;
      min-height: 3em; }
  .plan .plan-features .plan-feature-content {
    padding-left: 10px;
    color: #595959;
    font-size: 0.90em; }
  .plan .plan-footer {
    position: absolute;
    bottom: 20px;
    left: 30px;
    right: 30px; }
    .plan .plan-footer .plan-select-button {
      width: 100%;
      display: block;
      text-transform: uppercase;
      color: #008BC2;
      font-weight: 300;
      font-size: 0.85em;
      border-radius: 0;
      border: 1px solid #008BC2;
      margin-top: 10px; }
      .plan .plan-footer .plan-select-button.trial {
        background-image: linear-gradient(150deg, #00DBD0 15%, #008BC2);
        color: #fff;
        border: none; }
  .plan.popular {
    background-color: #E9ECEF; }
    .plan.popular #innerCircle {
      fill: #E9ECEF; }
  .plan.recommended {
    background-color: #008BC2;
    color: #fff; }
    .plan.recommended #innerCircle {
      fill: #008BC2; }
    .plan.recommended #checkmark {
      fill: #00DBD0; }
    .plan.recommended .plan-title {
      color: #fff; }
    .plan.recommended .plan-indicators .plan-step-indicator.has-color {
      background-color: #fff; }
    .plan.recommended .plan-price {
      color: #fff; }
    .plan.recommended .plan-description .plan-description-title {
      color: #00DBD0; }
    .plan.recommended .plan-description .plan-description-content {
      color: #fff; }
    .plan.recommended .plan-features .plan-feature-content {
      color: #fff; }
    .plan.recommended .plan-features .checkmark {
      border-bottom: 3px solid #00DBD0;
      border-right: 3px solid #00DBD0; }
    .plan.recommended .plan-footer .plan-select-button {
      color: #fff;
      border: 1px solid #fff; }
      .plan.recommended .plan-footer .plan-select-button.trial {
        color: #008BC2;
        background-color: #fff;
        background-image: none; }
  .plan .plan-emphasized-message {
    background-image: linear-gradient(120deg, #008BC2 15%, #00DBD0);
    color: #fff;
    padding: 10px;
    margin-top: 25px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 18px; }
