@import 'variables';

#admin-sidebar {
  .admin-side-header {
    background-color: $white;
    color: $blue-800;
    padding: 1em;
  }

  .nav-item {
    color: $body-color;
  }
}
