@import 'variables';

.onboarding-content {
  width: 100%;
  padding: 1rem;

  .welcome-video {
    width: 100%
  }

  .plan-table {
    margin: 1rem;
  }

  .pc-logo {
    height: 25px;
    width: 25px;
  }

  .next-promo-title {
    padding: 0 30px;
  }

  .next-promo-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .next-plan-item {
      width: 100%;
      margin-bottom: -75px;

      @media (min-width: map-get($grid-breakpoints, md)) {
        width: 400px;
      }
    }

    .table-wrapper {
      overflow-x: auto;
      flex-grow: 1;

      @media (min-width: map-get($grid-breakpoints, md)) {
        flex-grow: 0;
      }
    }
  
    .pc-feature-table {
      min-width: 450px;
  
      .header-row {
        display: flex;
        flex-wrap: nowrap;
        text-transform: uppercase;
        font-weight: 500;
  
        .pc-col {
          border-radius: 10px 10px 0 0;
          padding-top: 15px;
        }
      }

      .feature-row {
        display: flex;
        flex-wrap: nowrap;
      }
  
      .feature-col {
        flex-grow: 1;
        padding: 10px;

        @media (min-width: map-get($grid-breakpoints, md)) {
          min-width: 400px;
          max-width: 400px;
        }
      }
  
      .pc-col {
        min-width: 90px;
        max-width: 90px;
        background-color: $secondary;
        text-align: center;
        color: $white;
      }
  
      .feature-row:last-child {
        .pc-col {
          border-radius: 0 0 10px 10px;
          padding-bottom: 10px;
        }
      }
  
      .feature-row:nth-child(even) {
        background-color: $lightest-gray;
  
        .pc-col {
          background-color: lighten($secondary, 5%);
        }
      }
  
      .others-col {
        min-width: 80px;
        max-width: 80px;
        text-align: center;
        padding: 0 10px;
      }
    }
  }
}