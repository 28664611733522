/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
#editor-nav-item {
  border-right: 1px solid #003250;
  height: 100%;
  max-width: 300px;
  padding: 0 1.5em 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-start; }
  #editor-nav-item .nav-back-button {
    font-size: 1em;
    color: #000; }
  #editor-nav-item .logo {
    height: 57px;
    width: 57px;
    max-width: none; }
  #editor-nav-item .presto-header {
    margin-left: 1.5em;
    display: flex;
    align-items: center;
    justify-content: flex-start; }
  #editor-nav-item .presto-label-main {
    line-height: 1.25em;
    margin-left: 1em; }
  #editor-nav-item .presto-label {
    font-size: 1.25em; }
  #editor-nav-item .presto-sub-label {
    font-size: .75em; }
