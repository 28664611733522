/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
#onboarding-page .nav {
  position: relative;
  border-bottom: 2px solid #D8D8D8; }

#onboarding-page .logo {
  max-height: 3rem;
  min-height: 3rem; }

#onboarding-page .btn-back {
  font-size: 2rem;
  line-height: 1.3rem;
  padding: 0.5rem 0.9rem;
  border-width: 1px; }

#onboarding-page .signup-alert {
  margin-bottom: 0;
  margin-top: .75rem;
  text-align: center; }

#onboarding-page .active-page-dot {
  bottom: -7.5px;
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background: #008BC2;
  transition: all 250ms ease; }

#onboarding-page .active-page-line {
  position: absolute;
  bottom: -2px;
  left: 0;
  height: 2px;
  background: #008BC2;
  transition: all 250ms ease; }

#onboarding-page .section1 .active-page-dot {
  left: calc(50vw - 150px - 0.5rem - 15px); }

#onboarding-page .section1 .active-page-line {
  width: calc(50vw - 150px - 0.5rem - 15px); }

#onboarding-page .section2 .active-page-dot {
  left: calc(50vw - 7.5px); }

#onboarding-page .section2 .active-page-line {
  width: calc(50vw - 7.5px); }

#onboarding-page .section3 .active-page-dot {
  left: calc(50vw + 150px + 0.5rem); }

#onboarding-page .section3 .active-page-line {
  width: calc(50vw + 150px + 0.5rem); }

#onboarding-page .section-button {
  background: none;
  white-space: nowrap;
  border-radius: 20px;
  padding: 0.2rem 0;
  font-size: 0.8rem;
  margin: 0 0.5rem;
  text-align: center;
  width: 150px;
  color: #008BC2;
  border: 1px solid #008BC2;
  opacity: 0.3;
  transition: all 250ms ease;
  cursor: pointer; }

#onboarding-page .section-button.active {
  background: #008BC2;
  color: #000;
  opacity: 1; }

#onboarding-page .section-button:hover {
  opacity: 1; }

#onboarding-page .onboarding-content {
  width: 100%;
  padding: 0 0 1rem; }

#onboarding-page .onboarding-box {
  padding: 2rem;
  margin: 1rem 0;
  background-color: #fff;
  color: #000;
  border-radius: 10px; }
  #onboarding-page .onboarding-box .form-control {
    margin: 1rem 0;
    color: #000;
    font-weight: 400; }
    #onboarding-page .onboarding-box .form-control::placeholder {
      color: #999999;
      font-weight: 400; }

#onboarding-page .invalid-feedback {
  display: block; }

#onboarding-page .onboarding-back-button {
  background: none;
  border: none;
  color: #000;
  font-size: 1.2rem;
  padding: 1rem;
  cursor: pointer; }
