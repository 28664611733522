.transition-edit {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  .content {
    padding: .25em 0em .25em 0em;
  }

  .save-button {
    height: 36px;
  }
}
