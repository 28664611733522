@import '_bootstrap';
@import 'variables';

.file-uploading-progress {
  .uploading-file {
    border-bottom: 1px solid $gray-500;
    margin-bottom: 1em;
    padding-bottom: 1em;
  }

  .btn-link {
    &:active,
    &:focus,
    &:hover {
      text-decoration: none;
    }
  }

  .file-progress {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .file-progress-cancel .btn {
    color: $warning;
  }

  .file-progress-error {
    color: $warning;
  }

  .file-progress-canceled {
    font-size: .7em;
  }

  .file-progress-bar {
    flex: 0 1 auto;
    width: 88%;
  }

  .file-progress-text {
    flex: 0 1 auto;
  }

  .upload-pending {
    .file-progress-bar {
      width: 75%;
    }
  }

  .upload-empty {
    text-align: center;
  }
}
