/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.alacarte-table .item-list-tabs {
  flex-wrap: nowrap;
  overflow-x: scroll; }
  .alacarte-table .item-list-tabs > .nav-item {
    margin-bottom: 0; }

.alacarte-table .item-list-footer {
  padding: 20px;
  background-color: #D8D8D8;
  justify-content: center; }
  .alacarte-table .item-list-footer .item-select-button {
    width: 100%;
    display: block;
    text-transform: uppercase;
    color: #008BC2;
    font-weight: 300;
    font-size: 0.85em;
    border-radius: 0;
    border: 1px solid #008BC2;
    margin-top: 10px; }

.alacarte-item {
  position: relative;
  height: 100%;
  padding: 75px 30px 50px 30px; }
  .alacarte-item.js-gray-100 {
    background-color: #f8f9fa; }
  .alacarte-item.js-gray-200 {
    background-color: #e9ecef; }
  .alacarte-item .item-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 5px 15px;
    color: #fff;
    text-transform: uppercase;
    font-size: 0.80em;
    text-align: center;
    font-weight: bold; }
    .alacarte-item .item-header.js-primary {
      background-color: #00DBD0; }
    .alacarte-item .item-header.js-secondary {
      background-color: #008BC2; }
  .alacarte-item .item-title {
    text-transform: uppercase;
    color: #00314A;
    font-weight: 700;
    font-size: 1.5em; }
  .alacarte-item .item-price {
    color: #595959;
    font-weight: 500;
    margin: 30px 0;
    line-height: 50px; }
    .alacarte-item .item-price .item-price-text {
      text-transform: uppercase; }
    .alacarte-item .item-price .item-currency {
      font-size: 20px;
      font-weight: 300; }
    .alacarte-item .item-price .item-price-number {
      font-size: 50px;
      margin: 0 5px; }
  .alacarte-item .item-description {
    padding-bottom: 30px; }
    .alacarte-item .item-description-title {
      color: #008BC2;
      font-style: italic;
      font-size: 0.90em; }
    .alacarte-item .item-description-content {
      color: #595959;
      font-size: 0.90em;
      min-height: 3em; }
  .alacarte-item .item-features .item-feature-content {
    padding-left: 10px;
    color: #595959;
    font-size: 0.90em; }
  .alacarte-item .credit-buttons {
    display: flex;
    justify-content: center; }
    .alacarte-item .credit-buttons .credit-button {
      height: 60px; }
    .alacarte-item .credit-buttons .credit-field {
      margin: 0 10px;
      height: 60px;
      text-align: center;
      padding: 0;
      width: 90px;
      font-size: 2em; }
