@import 'variables';

.file-upload {
  .upload-zone {
    transition: border .125s ease;
    height: 124px;
    border: 2px dashed $gray-400;
    padding: 2em;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.is-active {
      border: 2px dashed $primary;

      .upload-text {
        color: white;
      }
    }
  }

  .upload-text {
    transition: color .125s ease;
    color: $gray-400;
    margin: 0;
  }

  .upload-click-text {
    color: $primary;
    &:hover {
      cursor: pointer;
    }
  }
}

.select-image-modal {
  .file-upload {
    .upload-zone {
      height: 160px;
    }
  }
}
