@import 'variables';

.search-bar {
  &.theme-white {
    border: solid $gray-500 1px;
    border-radius: 25px;

    .form-control {
      font-size: 1em;
      background: $white;
      border: 0;
      color: $black;
    }
  }

  &.theme-clear {
    border: solid $gray-500 1px;
    border-radius: 25px;

    .form-control {
      font-size: 1em;
      background: transparent;
      border: 0;
      color: $black;
    }

    .search-prepend {
      background: transparent;
      border: 0;
      margin-left: 20px;
      color: $gray-500;
      font-size: 1em;
      padding: 0;
    }
  }

  .input-group {
    flex-wrap: nowrap;
  }

  .menu-preview-image {
    display: inline-block;

    img {
      max-width: 100px;
      max-height: 100px;
      margin-right: 1em;
    }
  }
}
