/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.site-control-editor {
  background-color: #fff;
  color: #00314A; }
  .site-control-editor input.form-control {
    border-color: #fff;
    border-radius: 4px;
    color: #000;
    padding: 7px; }
  .site-control-editor .group-header {
    margin-left: -15px;
    margin-right: -15px;
    padding: .5rem;
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .site-control-editor .group-header-label {
    font-size: .75rem;
    font-weight: bold;
    text-transform: uppercase;
    padding-left: 15px; }
  .site-control-editor .group-controls {
    background-color: #E9ECEF;
    padding: 1rem; }
  .site-control-editor .form-buttons {
    display: flex;
    justify-content: space-between; }
  .site-control-editor .form-button {
    width: 36px;
    height: 36px; }

/**
 * This is a fork of the react-select css, but converted to scss and with the colors split out into themes.
 * There is a dark and a light theme that uses the global variables.
 */
/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
@keyframes Select-animation-fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes Select-animation-spin {
  to {
    transform: rotate(1turn); } }

.Select {
  position: relative; }
  .Select input::-webkit-contacts-auto-fill-button,
  .Select input::-webkit-credentials-auto-fill-button {
    display: none !important; }
  .Select input::-ms-clear {
    display: none !important; }
  .Select input::-ms-reveal {
    display: none !important; }
  .Select,
  .Select div,
  .Select input,
  .Select span {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .Select.is-disabled .Select-arrow-zone {
    cursor: default;
    pointer-events: none;
    opacity: 0.35; }
  .Select.is-disabled > .Select-control:hover {
    box-shadow: none; }
  .Select.is-open > .Select-control {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .Select.is-open > .Select-control .Select-arrow {
    top: -2px;
    border-width: 0 5px 5px; }
  .Select.is-searchable.is-open > .Select-control {
    cursor: text; }
  .Select.is-searchable.is-focused:not(.is-open) > .Select-control {
    cursor: text; }
  .Select.is-focused:not(.is-open) > .Select-control {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1); }
  .Select.has-value.is-clearable.Select--single > .Select-control .Select-value {
    padding-right: 42px; }
  .Select.has-value.Select--single > .Select-control .Select-value a.Select-value-label,
  .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label {
    cursor: pointer;
    text-decoration: none; }
  .Select.has-value.Select--single > .Select-control .Select-value a.Select-value-label:hover,
  .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:hover,
  .Select.has-value.Select--single > .Select-control .Select-value a.Select-value-label:focus,
  .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:focus {
    outline: none;
    text-decoration: underline; }
  .Select.has-value.is-pseudo-focused .Select-input {
    opacity: 0; }
  .Select.Select--rtl {
    direction: rtl;
    text-align: right; }
  .Select .Select-control {
    border-radius: 4px;
    cursor: default;
    display: table;
    border-spacing: 0;
    border-collapse: separate;
    height: 36px;
    outline: none;
    overflow: hidden;
    position: relative;
    width: 100%; }
  .Select .Select-control .Select-input:focus {
    outline: none; }
  .Select .Select-placeholder,
  .Select.Select--single > .Select-control .Select-value {
    bottom: 0;
    left: 0;
    line-height: 34px;
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    right: 0;
    top: 0;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .Select .Select-input {
    height: 34px;
    padding-left: 10px;
    padding-right: 10px;
    vertical-align: middle; }
  .Select .Select-input > input {
    width: 100%;
    background: none transparent;
    border: 0 none;
    box-shadow: none;
    cursor: default;
    display: inline-block;
    font-family: inherit;
    font-size: inherit;
    margin: 0;
    outline: none;
    line-height: 17px;
    /* For IE 8 compatibility */
    padding: 8px 0 12px;
    /* For IE 8 compatibility */
    -webkit-appearance: none; }
  .Select .is-focused .Select-input > input {
    cursor: text; }
  .Select .has-value.is-pseudo-focused .Select-input {
    opacity: 0; }
  .Select .Select-control:not(.is-searchable) > .Select-input {
    outline: none; }
  .Select .Select-loading-zone {
    cursor: pointer;
    display: table-cell;
    position: relative;
    text-align: center;
    vertical-align: middle;
    width: 16px; }
  .Select .Select-loading {
    -webkit-animation: Select-animation-spin 400ms infinite linear;
    -o-animation: Select-animation-spin 400ms infinite linear;
    animation: Select-animation-spin 400ms infinite linear;
    width: 16px;
    height: 16px;
    box-sizing: border-box;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    vertical-align: middle; }
  .Select .Select-clear-zone {
    -webkit-animation: Select-animation-fadeIn 200ms;
    -o-animation: Select-animation-fadeIn 200ms;
    animation: Select-animation-fadeIn 200ms;
    cursor: pointer;
    display: table-cell;
    position: relative;
    text-align: center;
    vertical-align: middle;
    width: 17px; }
  .Select .Select-clear {
    display: inline-block;
    font-size: 18px;
    line-height: 1; }
  .Select.Select--multi .Select-clear-zone {
    width: 17px; }
  .Select .Select-arrow-zone {
    cursor: pointer;
    display: table-cell;
    position: relative;
    text-align: center;
    vertical-align: middle;
    width: 25px;
    padding-right: 5px; }
  .Select.Select--rtl .Select-arrow-zone {
    padding-right: 0;
    padding-left: 5px; }
  .Select .Select-arrow {
    border-style: solid;
    border-width: 5px 5px 2.5px;
    display: inline-block;
    height: 0;
    width: 0;
    position: relative; }
  .Select .Select-control > *:last-child {
    padding-right: 5px; }
  .Select.Select--multi .Select-multi-value-wrapper {
    display: inline-block; }
  .Select .Select-aria-only {
    position: absolute;
    display: inline-block;
    height: 1px;
    width: 1px;
    margin: -1px;
    clip: rect(0, 0, 0, 0);
    overflow: hidden;
    float: left; }
  .Select .Select-menu-outer {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    box-sizing: border-box;
    margin-top: -1px;
    max-height: 200px;
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    z-index: 1;
    -webkit-overflow-scrolling: touch; }
  .Select .Select-menu {
    max-height: 198px;
    overflow-y: auto; }
  .Select .Select-option {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    padding: 8px 10px; }
  .Select .Select-option:last-child {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px; }
  .Select .Select-option.is-disabled {
    cursor: default; }
  .Select .Select-noresults {
    box-sizing: border-box;
    cursor: default;
    display: block;
    padding: 8px 10px; }
  .Select.Select--multi .Select-input {
    vertical-align: middle;
    margin-left: 10px;
    padding: 0; }
  .Select.Select--multi.Select--rtl .Select-input {
    margin-left: 0;
    margin-right: 10px; }
  .Select.Select--multi.has-value .Select-input {
    margin-left: 5px; }
  .Select.Select--multi .Select-value {
    border-radius: 2px;
    display: inline-block;
    font-size: 0.9em;
    line-height: 1.4;
    margin-left: 5px;
    margin-top: 5px;
    vertical-align: top; }
  .Select.Select--multi .Select-value-icon,
  .Select.Select--multi .Select-value-label {
    display: inline-block;
    vertical-align: middle; }
  .Select.Select--multi .Select-value-label {
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
    cursor: default;
    padding: 2px 5px; }
  .Select.Select--multi a.Select-value-label {
    cursor: pointer;
    text-decoration: none; }
  .Select.Select--multi a.Select-value-label:hover {
    text-decoration: underline; }
  .Select.Select--multi .Select-value-icon {
    cursor: pointer;
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
    padding: 1px 5px 3px; }
  .Select.Select--multi.Select--rtl .Select-value {
    margin-left: 0;
    margin-right: 5px; }
  .Select.Select--multi.Select--rtl .Select-value-icon {
    border-right: none; }
  .Select.Select--multi.is-disabled .Select-value-icon {
    cursor: not-allowed; }

.Select.theme-dark.is-disabled > .Select-control {
  background-color: #003250; }

.Select.theme-dark.is-open > .Select-control {
  background-color: #003250;
  border-color: #fff; }

.Select.theme-dark.is-open > .Select-control .Select-arrow {
  border-color: transparent transparent #9CD7EE; }

.Select.theme-dark.is-focused > .Select-control {
  background-color: #003250; }

.Select.theme-dark.is-focused:not(.is-open) > .Select-control {
  background-color: #003250;
  border-color: #00DBD0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1); }

.Select.theme-dark.has-value.Select--single > .Select-control .Select-value .Select-value-label,
.Select.theme-dark.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
  color: #fff; }

.Select.theme-dark.has-value.Select--single > .Select-control .Select-value a.Select-value-label:hover,
.Select.theme-dark.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:hover,
.Select.theme-dark.has-value.Select--single > .Select-control .Select-value a.Select-value-label:focus,
.Select.theme-dark.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:focus {
  color: #00DBD0; }

.Select.theme-dark.has-value.Select--single > .Select-control .Select-value a.Select-value-label:focus,
.Select.theme-dark.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:focus {
  background-color: #003250; }

.Select.theme-dark.is-open .Select-arrow,
.Select.theme-dark .Select-arrow-zone:hover > .Select-arrow {
  border-top-color: #0072b6; }

.Select.theme-dark .Select-control {
  background-color: #003250;
  border: 1px solid #fff;
  color: #fff; }

.Select.theme-dark .Select-control:hover {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06); }

.Select.theme-dark .Select-control .Select-input:focus {
  background-color: #003250; }

.Select.theme-dark .Select-placeholder,
.Select.theme-dark.Select--single > .Select-control .Select-value {
  color: #9CD7EE; }

.Select.theme-dark .Select-loading {
  border: 2px solid #fff;
  border-right-color: #fff; }

.Select.theme-dark .Select-clear-zone {
  color: #9CD7EE; }

.Select.theme-dark .Select-clear-zone:hover {
  color: #D0021B; }

.Select.theme-dark .Select-arrow {
  border-color: #9CD7EE transparent transparent; }

.Select.theme-dark .Select-input > input {
  color: #fff; }

.Select.theme-dark .Select-menu-outer {
  background-color: #003250;
  border: 1px solid #fff;
  border-top-color: #0072b6;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06); }

.Select.theme-dark .Select-option {
  background-color: #003250;
  color: #fff; }

.Select.theme-dark .Select-option.is-selected {
  background-color: #005283;
  color: #fff; }

.Select.theme-dark .Select-option.is-focused {
  background-color: #0072b6;
  color: #fff; }

.Select.theme-dark .Select-option.is-disabled {
  color: #fff; }

.Select.theme-dark .Select-noresults {
  color: #9CD7EE; }

.Select.theme-dark.Select--multi .Select-value {
  background-color: #ECF5FD;
  border: 1px solid #00DBD0;
  color: #00DBD0; }

.Select.theme-dark.Select--multi a.Select-value-label {
  color: #9CD7EE; }

.Select.theme-dark.Select--multi .Select-value-icon {
  border-right: 1px solid #00DBD0;
  padding: 1px 5px 3px; }

.Select.theme-dark.Select--multi .Select-value-icon:hover,
.Select.theme-dark.Select--multi .Select-value-icon:focus {
  background-color: #d8eafd;
  color: #0071e6; }

.Select.theme-dark.Select--multi .Select-value-icon:active {
  background-color: #00DBD0; }

.Select.theme-dark.Select--multi.Select--rtl .Select-value-icon {
  border-left: 1px solid #00DBD0; }

.Select.theme-dark.Select--multi.is-disabled .Select-value {
  background-color: #003250;
  border: 1px solid #003250;
  color: #fff; }

.Select.theme-dark.Select--multi.is-disabled .Select-value-icon {
  border-right: 1px solid #003250; }

.Select.theme-dark.Select--multi.is-disabled .Select-value-icon:hover,
.Select.theme-dark.Select--multi.is-disabled .Select-value-icon:focus,
.Select.theme-dark.Select--multi.is-disabled .Select-value-icon:active {
  background-color: #003250; }

.Select.theme-header.is-disabled > .Select-control {
  background-color: #003250; }

.Select.theme-header.is-open > .Select-control {
  background-color: #003250;
  border-color: #fff; }

.Select.theme-header.is-open > .Select-control .Select-arrow {
  border-color: transparent transparent #9CD7EE; }

.Select.theme-header.is-focused > .Select-control {
  background-color: #003250; }

.Select.theme-header.is-focused:not(.is-open) > .Select-control {
  background-color: #003250;
  border-color: #00DBD0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1); }

.Select.theme-header.has-value.Select--single > .Select-control .Select-value .Select-value-label,
.Select.theme-header.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
  color: #fff; }

.Select.theme-header.has-value.Select--single > .Select-control .Select-value a.Select-value-label:hover,
.Select.theme-header.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:hover,
.Select.theme-header.has-value.Select--single > .Select-control .Select-value a.Select-value-label:focus,
.Select.theme-header.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:focus {
  color: #00DBD0; }

.Select.theme-header.has-value.Select--single > .Select-control .Select-value a.Select-value-label:focus,
.Select.theme-header.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:focus {
  background-color: #003250; }

.Select.theme-header.is-open .Select-arrow,
.Select.theme-header .Select-arrow-zone:hover > .Select-arrow {
  border-top-color: #0072b6; }

.Select.theme-header .Select-control {
  background-color: #003250;
  border: 1px solid #fff;
  color: #fff; }

.Select.theme-header .Select-control:hover {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06); }

.Select.theme-header .Select-control .Select-input:focus {
  background-color: #003250; }

.Select.theme-header .Select-placeholder,
.Select.theme-header.Select--single > .Select-control .Select-value {
  color: #9CD7EE; }

.Select.theme-header .Select-loading {
  border: 2px solid #fff;
  border-right-color: #fff; }

.Select.theme-header .Select-clear-zone {
  color: #9CD7EE; }

.Select.theme-header .Select-clear-zone:hover {
  color: #D0021B; }

.Select.theme-header .Select-arrow {
  border-color: #9CD7EE transparent transparent; }

.Select.theme-header .Select-input > input {
  color: #fff; }

.Select.theme-header .Select-menu-outer {
  background-color: #003250;
  border: 1px solid #fff;
  border-top-color: #0072b6;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06); }

.Select.theme-header .Select-option {
  background-color: #003250;
  color: #fff; }

.Select.theme-header .Select-option.is-selected {
  background-color: #005283;
  color: #fff; }

.Select.theme-header .Select-option.is-focused {
  background-color: #0072b6;
  color: #fff; }

.Select.theme-header .Select-option.is-disabled {
  color: #fff; }

.Select.theme-header .Select-noresults {
  color: #9CD7EE; }

.Select.theme-header.Select--multi .Select-value {
  background-color: #ECF5FD;
  border: 1px solid #00DBD0;
  color: #00DBD0; }

.Select.theme-header.Select--multi a.Select-value-label {
  color: #9CD7EE; }

.Select.theme-header.Select--multi .Select-value-icon {
  border-right: 1px solid #00DBD0;
  padding: 1px 5px 3px; }

.Select.theme-header.Select--multi .Select-value-icon:hover,
.Select.theme-header.Select--multi .Select-value-icon:focus {
  background-color: #d8eafd;
  color: #0071e6; }

.Select.theme-header.Select--multi .Select-value-icon:active {
  background-color: #00DBD0; }

.Select.theme-header.Select--multi.Select--rtl .Select-value-icon {
  border-left: 1px solid #00DBD0; }

.Select.theme-header.Select--multi.is-disabled .Select-value {
  background-color: #003250;
  border: 1px solid #003250;
  color: #fff; }

.Select.theme-header.Select--multi.is-disabled .Select-value-icon {
  border-right: 1px solid #003250; }

.Select.theme-header.Select--multi.is-disabled .Select-value-icon:hover,
.Select.theme-header.Select--multi.is-disabled .Select-value-icon:focus,
.Select.theme-header.Select--multi.is-disabled .Select-value-icon:active {
  background-color: #003250; }

.Select.theme-input-white.is-disabled > .Select-control {
  background-color: #f8f9fa; }

.Select.theme-input-white.is-open > .Select-control {
  background-color: #fff;
  border-color: #fff; }

.Select.theme-input-white.is-open > .Select-control .Select-arrow {
  border-color: transparent transparent #999999; }

.Select.theme-input-white.is-focused > .Select-control {
  background-color: #fff; }

.Select.theme-input-white.is-focused:not(.is-open) > .Select-control {
  background-color: #fff;
  border-color: #00DBD0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1); }

.Select.theme-input-white.has-value.Select--single > .Select-control .Select-value .Select-value-label,
.Select.theme-input-white.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
  color: #333333; }

.Select.theme-input-white.has-value.Select--single > .Select-control .Select-value a.Select-value-label:hover,
.Select.theme-input-white.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:hover,
.Select.theme-input-white.has-value.Select--single > .Select-control .Select-value a.Select-value-label:focus,
.Select.theme-input-white.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:focus {
  color: #00DBD0; }

.Select.theme-input-white.has-value.Select--single > .Select-control .Select-value a.Select-value-label:focus,
.Select.theme-input-white.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:focus {
  background-color: #fff; }

.Select.theme-input-white.is-open .Select-arrow,
.Select.theme-input-white .Select-arrow-zone:hover > .Select-arrow {
  border-top-color: #cccccc; }

.Select.theme-input-white .Select-control {
  background-color: #fff;
  border: 1px solid #fff;
  color: #333333; }

.Select.theme-input-white .Select-control:hover {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06); }

.Select.theme-input-white .Select-control .Select-input:focus {
  background-color: #fff; }

.Select.theme-input-white .Select-placeholder,
.Select.theme-input-white.Select--single > .Select-control .Select-value {
  color: #999999; }

.Select.theme-input-white .Select-loading {
  border: 2px solid #fff;
  border-right-color: #333333; }

.Select.theme-input-white .Select-clear-zone {
  color: #999999; }

.Select.theme-input-white .Select-clear-zone:hover {
  color: #D0021B; }

.Select.theme-input-white .Select-arrow {
  border-color: #999999 transparent transparent; }

.Select.theme-input-white .Select-input > input {
  color: #333333; }

.Select.theme-input-white .Select-menu-outer {
  background-color: #fff;
  border: 1px solid #fff;
  border-top-color: #cccccc;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06); }

.Select.theme-input-white .Select-option {
  background-color: #fff;
  color: #6D6D6D; }

.Select.theme-input-white .Select-option.is-selected {
  background-color: #e6e6e6;
  color: #333333; }

.Select.theme-input-white .Select-option.is-focused {
  background-color: #cccccc;
  color: #333333; }

.Select.theme-input-white .Select-option.is-disabled {
  color: #fff; }

.Select.theme-input-white .Select-noresults {
  color: #999999; }

.Select.theme-input-white.Select--multi .Select-value {
  background-color: #ECF5FD;
  border: 1px solid #00DBD0;
  color: #00DBD0; }

.Select.theme-input-white.Select--multi a.Select-value-label {
  color: #999999; }

.Select.theme-input-white.Select--multi .Select-value-icon {
  border-right: 1px solid #00DBD0;
  padding: 1px 5px 3px; }

.Select.theme-input-white.Select--multi .Select-value-icon:hover,
.Select.theme-input-white.Select--multi .Select-value-icon:focus {
  background-color: #d8eafd;
  color: #0071e6; }

.Select.theme-input-white.Select--multi .Select-value-icon:active {
  background-color: #00DBD0; }

.Select.theme-input-white.Select--multi.Select--rtl .Select-value-icon {
  border-left: 1px solid #00DBD0; }

.Select.theme-input-white.Select--multi.is-disabled .Select-value {
  background-color: #f8f9fa;
  border: 1px solid #f8f9fa;
  color: #333333; }

.Select.theme-input-white.Select--multi.is-disabled .Select-value-icon {
  border-right: 1px solid #f8f9fa; }

.Select.theme-input-white.Select--multi.is-disabled .Select-value-icon:hover,
.Select.theme-input-white.Select--multi.is-disabled .Select-value-icon:focus,
.Select.theme-input-white.Select--multi.is-disabled .Select-value-icon:active {
  background-color: #f8f9fa; }

.Select.theme-light.is-disabled > .Select-control {
  background-color: #f8f9fa; }

.Select.theme-light.is-open > .Select-control {
  background-color: #fff;
  border-color: #D8D8D8; }

.Select.theme-light.is-open > .Select-control .Select-arrow {
  border-color: transparent transparent #999999; }

.Select.theme-light.is-focused > .Select-control {
  background-color: #fff; }

.Select.theme-light.is-focused:not(.is-open) > .Select-control {
  background-color: #fff;
  border-color: #00DBD0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1); }

.Select.theme-light.has-value.Select--single > .Select-control .Select-value .Select-value-label,
.Select.theme-light.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
  color: #333333; }

.Select.theme-light.has-value.Select--single > .Select-control .Select-value a.Select-value-label:hover,
.Select.theme-light.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:hover,
.Select.theme-light.has-value.Select--single > .Select-control .Select-value a.Select-value-label:focus,
.Select.theme-light.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:focus {
  color: #00DBD0; }

.Select.theme-light.has-value.Select--single > .Select-control .Select-value a.Select-value-label:focus,
.Select.theme-light.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:focus {
  background-color: #fff; }

.Select.theme-light.is-open .Select-arrow,
.Select.theme-light .Select-arrow-zone:hover > .Select-arrow {
  border-top-color: #cccccc; }

.Select.theme-light .Select-control {
  background-color: #fff;
  border: 1px solid #D8D8D8;
  color: #333333; }

.Select.theme-light .Select-control:hover {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06); }

.Select.theme-light .Select-control .Select-input:focus {
  background-color: #fff; }

.Select.theme-light .Select-placeholder,
.Select.theme-light.Select--single > .Select-control .Select-value {
  color: #999999; }

.Select.theme-light .Select-loading {
  border: 2px solid #D8D8D8;
  border-right-color: #333333; }

.Select.theme-light .Select-clear-zone {
  color: #999999; }

.Select.theme-light .Select-clear-zone:hover {
  color: #D0021B; }

.Select.theme-light .Select-arrow {
  border-color: #999999 transparent transparent; }

.Select.theme-light .Select-input > input {
  color: #333333; }

.Select.theme-light .Select-menu-outer {
  background-color: #fff;
  border: 1px solid #D8D8D8;
  border-top-color: #cccccc;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06); }

.Select.theme-light .Select-option {
  background-color: #fff;
  color: #6D6D6D; }

.Select.theme-light .Select-option.is-selected {
  background-color: #e6e6e6;
  color: #333333; }

.Select.theme-light .Select-option.is-focused {
  background-color: #cccccc;
  color: #333333; }

.Select.theme-light .Select-option.is-disabled {
  color: #D8D8D8; }

.Select.theme-light .Select-noresults {
  color: #999999; }

.Select.theme-light.Select--multi .Select-value {
  background-color: #ECF5FD;
  border: 1px solid #00DBD0;
  color: #00DBD0; }

.Select.theme-light.Select--multi a.Select-value-label {
  color: #999999; }

.Select.theme-light.Select--multi .Select-value-icon {
  border-right: 1px solid #00DBD0;
  padding: 1px 5px 3px; }

.Select.theme-light.Select--multi .Select-value-icon:hover,
.Select.theme-light.Select--multi .Select-value-icon:focus {
  background-color: #d8eafd;
  color: #0071e6; }

.Select.theme-light.Select--multi .Select-value-icon:active {
  background-color: #00DBD0; }

.Select.theme-light.Select--multi.Select--rtl .Select-value-icon {
  border-left: 1px solid #00DBD0; }

.Select.theme-light.Select--multi.is-disabled .Select-value {
  background-color: #f8f9fa;
  border: 1px solid #f8f9fa;
  color: #333333; }

.Select.theme-light.Select--multi.is-disabled .Select-value-icon {
  border-right: 1px solid #f8f9fa; }

.Select.theme-light.Select--multi.is-disabled .Select-value-icon:hover,
.Select.theme-light.Select--multi.is-disabled .Select-value-icon:focus,
.Select.theme-light.Select--multi.is-disabled .Select-value-icon:active {
  background-color: #f8f9fa; }

/**
 * This is a fork of the react-toggle css, but converted to scss and
 * with the colors split out into variables.
 */
/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.react-toggle {
  touch-action: pan-x;
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;
  -webkit-touch-callout: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent; }

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  transition: opacity 0.25s; }

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  border-color: #fff;
  border-width: 1px;
  border-style: solid;
  background-color: #003250;
  transition: all 0.2s ease; }

.react-toggle--checked .react-toggle-track {
  background-color: #00DBD0; }

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #00DBD0; }

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  transition: opacity 0.25s ease; }

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  transition: opacity 0.25s ease; }

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  transition: opacity 0.25s ease; }

.react-toggle--checked .react-toggle-track-x {
  opacity: 0; }

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #505050;
  border-radius: 50%;
  background-color: #fff;
  box-sizing: border-box;
  transition: all 0.25s ease; }

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #00DBD0; }

.react-toggle--focus .react-toggle-thumb {
  box-shadow: 0px 0px 2px 3px #0099E0; }

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  box-shadow: 0px 0px 5px 5px #0099E0; }

/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.site-refresh-button {
  background-color: transparent;
  color: #fff;
  border: none;
  transition: all .25s ease;
  transform: rotate(0deg);
  color: #999999;
  font-size: 1.5em; }
  .site-refresh-button:hover {
    cursor: pointer; }
  .site-refresh-button:hover {
    color: #6D6D6D;
    transform: rotate(20deg); }
  .site-refresh-button:focus {
    outline: none; }

/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.site-no-results {
  width: 100%;
  padding: 1em .5em;
  font-size: 1.375em;
  color: #999999;
  margin: 0;
  text-align: center;
  color: #999999; }
  .site-no-results.small-text {
    font-size: 1em;
    padding: .5em 1em; }

/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.site-error {
  width: 100%;
  padding: 1em .5em;
  font-size: 1.375em;
  color: #999999;
  margin: 0;
  text-align: center;
  color: #CB5955; }
  .site-error.small-text {
    font-size: 1em;
    padding: .5em 1em; }

/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.site-text-input {
  border-radius: 4px;
  border: 1px solid #505050;
  margin-bottom: 1em;
  width: 100%; }
  .site-text-input input[type=text] {
    background-color: transparent;
    padding: .25em .5em;
    width: 100%;
    color: #505050;
    border: none; }
    .site-text-input input[type=text]:focus {
      outline: none;
      box-shadow: none; }
    .site-text-input input[type=text]:-ms-input-placeholder {
      color: #999999; }

.site-label {
  margin-bottom: .5em;
  font-size: 1em; }

/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.site-transparent-button {
  background-color: transparent;
  color: #fff;
  border: none; }
  .site-transparent-button:hover {
    cursor: pointer; }

/*=== Colors ===*/
/*
 * BOOTSTRAP OVERRIDES
 */
.site-navbar-control {
  font-size: .875em;
  height: 80px;
  margin: 0;
  padding: 12px 1.5em;
  /* Flex Styles */
  display: flex;
  align-items: center; }
  .site-navbar-control:after {
    margin-left: 1em; }

@media (min-width: 0px) and (max-width: 580px) {
  .site-navbar-control {
    font-size: .75em;
    padding: 0; } }

/* IE11 does not know what the main element is. */
main {
  display: block; }

body {
  /* Make sure the scrollbars behave correctly in IE11 */
  -ms-overflow-style: -ms-autohiding-scrollbar; }

.main-row {
  padding: 1rem 1rem; }

.sub-row {
  padding: 1rem 1rem; }

a {
  cursor: pointer; }

*:focus {
  outline: none !important; }

.btn-icon:active, .btn-icon:focus, .btn-icon:hover {
  text-decoration: none; }

.form-control-bottom-border {
  background: none;
  border: none;
  border-radius: 0;
  border-bottom: 2px solid #003250;
  color: #000; }
  .form-control-bottom-border::placeholder {
    color: #008BC2; }
  .form-control-bottom-border:focus {
    background: none;
    box-shadow: none;
    color: #000;
    border-color: #00DBD0; }

.react-select-bottom-border {
  background: none;
  border: none;
  border-radius: 0;
  border-bottom: 2px solid #003250;
  color: #000; }
  .react-select-bottom-border::placeholder {
    color: #008BC2; }
  .react-select-bottom-border:focus {
    background: none;
    box-shadow: none;
    color: #000;
    border-color: #00DBD0; }
  .react-select-bottom-border > div[class$="-control"] {
    border: none; }
  .react-select-bottom-border span[class$="-indicatorSeparator"] {
    width: 0; }

.react-select-bottom-border__control {
  box-shadow: none !important;
  border: none !important; }

.react-select-bottom-border__menu .react-select-bottom-border__option--is-selected {
  background-color: #999999 !important; }

.react-select-bottom-border__menu .react-select-bottom-border__option--is-focused {
  background-color: #D8D8D8 !important; }

.react-select__control {
  box-shadow: none !important;
  border-color: #cccccc !important; }

.react-select__menu .react-select__option--is-selected {
  background-color: #999999 !important; }

.react-select__menu .react-select__option--is-focused {
  background-color: #D8D8D8 !important; }

.icon {
  height: 24px;
  width: 24px; }

.icon-bold {
  font-weight: bold; }

/*
 * BADGES
 */
.badge-outline-primary {
  background-color: #333333;
  border: 1px solid #00DBD0;
  color: #00DBD0; }

.badge-outline-dark {
  background-color: #333333;
  border: 1px solid #999999;
  color: #999999; }

/*
 * Tooltips
 */
.tooltip {
  pointer-events: none; }
  .tooltip.show {
    transition: opacity .2s ease-out; }
  .tooltip .tooltip-inner hr {
    border-top-color: #fff; }

/*
 * Popovers
 */
.popover-header, .popover-body {
  color: #00283D;
  text-align: justify;
  text-align-last: left; }

/*
 * Modals
 */
.modal-backdrop {
  background-color: #fff; }
