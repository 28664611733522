$size: 43px;

#download-credits-badge {
  background: url('../../../../../assets/images/content-request.svg') no-repeat center;
  height: $size;
  width: $size;

  display: flex;
  align-items: center;
  justify-content: center;
}
