@import 'variables';

$max-feed-icon-height: 14em;

.edit-feed-icon-controls {
  .can-not-edit {
    margin-top: 1rem;
    text-align: center;
  }

  .react-numeric-input {
    b i {
      // Because the component doesn't believe in classes...
      background: white !important;
    }
  }
}
